<template lang="">
  <div v-if="activity.startQuestionNum === activity.endQuestionNum" class="activity-questions bold">Question {{ activity.startQuestionNum }}</div>
  <div v-else class="activity-questions bold">Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</div>
  <div class="activity-title" v-html="activity.activityTitle" />
  <div class="activity-note" v-html="activity.activitySubTitle" />
  <div class="activity-content">
    <fill-blank :text="activity.activityContent" :activity-id="activity.activityId" />
  </div>
</template>
<script>
import FillBlank from './ListeningFillBlanks'
export default {
  name: 'Answer0205Component',
  components: { FillBlank },
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: {}
    }
  }
}
</script>
<style lang="scss" scoped>
.activity-questions{
  margin-bottom: 15px;
}

.activity-title{
  margin-bottom: 5px;
}
.activity-content{
  font-size: 14px;
  line-height: 1.5rem;
  margin-top:20px;
}
.activity-content:deep( div, p){
  margin-bottom: 5px;
}
.activity-content:deep(h1,h2,h3,h4,h5,h6) {
  font-size: 18px;
}
</style>
