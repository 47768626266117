<template lang="">
  <p v-if="activity.startQuestionNum === activity.endQuestionNum" class="activity-questions bold">Question {{ activity.startQuestionNum }}</p>
  <p v-else class="activity-questions bold">Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</p>
  <p class="activity-title" v-html="activity.activityTitle" />
  <p class="activity-note" v-html="activity.activitySubTitle" />
  <div class="activity-content">
    <ul>
      <li
        v-for="item in answerList"
        :key="item.index"
      >
        <strong>{{ item.index }}</strong> {{ item.content }}
      </li>
    </ul>
    <ul class="questions__">
      <li v-for="question in questionList" :key="question.questionNum">
        <span><strong>{{ question.questionNum }}.</strong> {{ question.questionTitle }}</span>
        <span :class="'answer__ ' + ((getResult(question.questionNum).isRight || getResult(question.questionNum).right) ?'right':'wrong') +(currentNum === parseInt(question.questionNum) ? ' highlight':'')" @click="changeNum(question.questionNum)">
          {{ question.questionAnswer }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Answer0304Component',
  inject: ['readingAnswers', 'currentNum', 'changeNum'],
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: [],
      questionList: [],
      answerList: []
    }
  },
  created() {
    this.questionList = this.activity.questionList
    this.answerList = JSON.parse(this.activity.activityFormwork)
  },
  methods: {
    getResult(num) {
      var r
      this.readingAnswers.value.forEach(e => {
        if (e.questionNum === num) {
          r = e
          return
        }
      })
      return r
    }
  }

}
</script>
<style lang="scss" scoped>
.activity-questions{
  margin-bottom: 15px;
}
.activity-title{
  margin-bottom: 5px;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li{
  margin: 5px auto;
}

ul.questions__ {
  margin-top: 20px;
}

ul.questions__ li{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

ul.questions__ li span:last-child{
  margin-top: 5px;
  font-weight: bold;
  border-bottom: 1px dashed #303133;
  text-align: center;
  max-width: 100px;
  cursor: pointer;
}

ul.questions__ li span:last-child.right{
  color: #40d039;
}

ul.questions__ li span:last-child.right.highlight{
  background-color:  #f0f9eb;
}

ul.questions__ li span:last-child.wrong{
  color: #E31837;
}

ul.questions__ li span:last-child.wrong.highlight{
  background-color:  #fef0f0;
}
</style>
