<template lang="">
  <p class="activity-questions bold">{{ activity.activityType }}</p>
  <p
    v-if="activity.startQuestionNum === activity.endQuestionNum"
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Question {{ activity.startQuestionNum }}</p>
  <p
    v-else
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</p>
  <p
    class="activity-title"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activityTitle"
  />
  <p
    class="activity-note"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activitySubTitle"
  />
  <ul class="questions">
    <li
      v-for="question in activity.questionList"
      :id="'question_' + question.questionNum"
      :key="question.questionNum"
      style="margin-bottom:15px;"
    >
      <reading-question-card
        :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
        :question-num=" activity.startQuestionNum + '-' + activity.endQuestionNum"
        :question="question"
        :is-highlight="question.questionNum === currentNum"
      >
        <template #default="slotProps">
          <hi-checkbox-group
            v-model="queModels[slotProps.question.questionNum]"
            :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
            @change="onChange"
          >
            <hi-checkbox v-for="answer in JSON.parse(question.questionContent)" :key="answer.index" :label="answer.index" :name="'checkbox-'+slotProps.question.questionNum">
              <strong>{{ answer.index }}</strong> {{ answer.content }}
            </hi-checkbox>
          </hi-checkbox-group>
        </template>
      </reading-question-card>
    </li>
  </ul>
</template>
<script>
import { mapGetters } from 'vuex'
import ReadingQuestionCard from '../ReadingQuestionCard'
import HiCheckbox from '../checkbox/Checkbox'
import HiCheckboxGroup from '../checkbox/CheckboxGroup'
export default {
  name: 'Question0302Component',
  components: { ReadingQuestionCard, HiCheckbox, HiCheckboxGroup },
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: [],
      answerList: [],
      size: '0'
    }
  },
  computed: {
    ...mapGetters({ fontSize: 'fontSize', readingAnswer: 'readingAnswer', currentNum: 'currentQuestionNum' }),
    getFontSize() {
      return this.fontSize
    },
    getCurrentNum() {
      return this.currentNum
    }
  },
  watch: {
    getCurrentNum: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.handleFocus()
      }
    },
    getFontSize: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.size = this.fontSize
      }
    }
  },
  created() {
    var _this = this
    this.readingAnswer.forEach(element => {
      _this.queModels[element.questionNumber] = element.yourAnswer
    })
  },
  mounted() {
    this.size = this.fontSize
  },
  methods: {
    onChange(e) {
      this.$store.dispatch('setCurrentQuestionNum', this.activity.startQuestionNum)
      for (var i = this.activity.startQuestionNum; i <= this.activity.endQuestionNum; i++) {
        this.$store.dispatch('saveReadingAnswer', {
          'questionNumber': i,
          'yourAnswer': e
        })
      }
    },
    handleFocus() {
      var node = document.getElementById('question_' + this.currentNum)
      if (node) {
        // node.scrollIntoView({
        //   behavior: 'smooth'
        // })
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.activity-questions{
  margin-bottom: 15px;
}
.activity-title{
  margin-bottom: 5px;
}

.el-radio-group{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.questions{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
ul.questions li{
  flex: 0 0 100%;
  margin: 0;
}

ul.questions::v-deep .el-checkbox-group{
  display: block;
}

ul.questions::v-deep .el-checkbox{
  display: block;
  margin-bottom: 5px;
  height: auto;

}

ul.questions::v-deep .el-checkbox__input{
  margin-top: 4px;
}

ul.questions::v-deep .el-checkbox__label {
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.7em;
  vertical-align: top;
  display: inline-block;
}
</style>
