import request from '@/utils/request'

// 获取分类树
export function findTree(categoryId) {
  return request({
    url: '/api/category/' + categoryId + '/tree',
    method: 'get'
  })
}

// 获取分类列表
export function findCategoryByCode(code) {
  return request({
    url: '/api/category/code/' + code,
    method: 'get'
  })
}
