<template lang="">
  <div class="common-layout">
    <el-row>
      <el-col :offset="6" :span="12" class="feedback">
        <el-card>
          <img src="@/assets/images/feedback.png">
        </el-card>
        <span>有问题，扫码联系客服哦 (*¯︶¯*)</span>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'FeedbackPage',
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.common-layout{
  background-color: #ffffff;
}
.feedback{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.feedback span{
  margin-top: 10px;
}
</style>
