<template lang="">
  <component :is="currentComponents" @next-step="handleToNextStep" />
</template>
<script>
import TestStartView from './pages/TestStartView'
import ListeningIndexView from './listening/ListeningIndexView'
import ReadingIndexView from './reading/ReadingIndexView'
import WritingIndexView from './writing/WritingIndexView'

import { ElMessageBox } from 'element-plus'

export default {
  name: 'TestStartPage',
  components: { TestStartView, ListeningIndexView, ReadingIndexView, WritingIndexView },
  data() {
    return {
      currentView: 0,
      currentComponents: 'TestStartView'
    }
  },
  mounted() {
    // 监听浏览器返回
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', this.goBack, false)

    // 取消禁用右键
    document.oncontextmenu = new Function('event.returnValue=true')
    // 取消禁用选择
    document.onselectstart = new Function('event.returnValue=true')
  },
  created() {
    // 阻止F5刷新
    this.stopF5Refresh()
    // 监听刷新事件
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  unmounted() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('popstate', this.goBack)
  },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('popstate', this.goBack)
  },
  methods: {
    // 阻止F5刷新
    stopF5Refresh() {
      document.onkeydown = function(e) {
        var evt = window.event || e
        var code = evt.keyCode || evt.which
        if (code === 116) {
          if (evt.preventDefault) {
            evt.preventDefault()
          } else {
            evt.keyCode = 0
            evt.returnValue = false
          }
        }
      }
    },
    // 浏览器刷新事件
    beforeunloadHandler(e) {
      e = e || window.event
      if (e) {
        e.returnValue = '关闭提示'
      }
      return '关闭提示'
    },
    handleToNextStep(val) {
      this.currentComponents = val
    },
    goBack() {
      ElMessageBox.confirm('即将结束本次考试？', '提示',
        {
          type: 'warning',
          confirmButtonText: '结束考试',
          cancelButtonText: '继续考试'
        }).then(() => {
        this.$router.back()
      }).catch((action) => {
        if (action === 'cancel') {
          history.pushState(null, null, document.URL)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
img {
    vertical-align: middle;
    border-style: none;
}

.student-details, .test-sound {
    width: 100%;
    max-width: 1120px;
    margin: 80px auto 0 auto;
    text-align: left;
}
.bc-box-title {
    display: inline-block;
    font-weight: bold;
}
.bc-box {
    display: block;
    width: 100%;
    max-width: 1120px;
    border: solid 1px white;
    border-radius: 10px;
    padding: 15px;
    margin: 0 auto;
}
.btn.bc-button {
    margin: 0 auto;
    display: block;
    background-color: transparent;
    color: #283e53;
    font-weight: bold;
    border: none;
    box-shadow: 0 2px 2px grey;
}
p.bx-box-info, p.bc-box-attention {
    padding-left: 25px;
    background-position: left 0.15em;
    background-repeat: no-repeat;
}
p.bx-box-info {
    background-image: url('@/assets/images/iconInformation16.png');
}
</style>
