<template lang="">
  <component :is="currentComponents" @listening-next-step="handleToNextStep" @update-audio="handleUpdateAudio" />
</template>
<script>
import ListeningStartView from './ListeningStartView'
import ListeningTestView from './ListeningTestView'
import ListeningEndView from './ListeningEndView'

export default {
  name: 'ListeningIndexPage',
  components: { ListeningStartView, ListeningTestView, ListeningEndView },
  data() {
    return {
      audio: undefined,
      currentView: 0,
      timerStart: false,
      currentComponents: 'ListeningStartView'
    }
  },
  methods: {
    handleToNextStep(val) {
      this.currentComponents = val
      if (val === 'ListeningTestView') {
        this.timerStart = true
      }
    },
    handleUpdateAudio(val) {
      this.audio = val
    }
  }

}
</script>
<style lang="">

</style>
