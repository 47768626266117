import request from '@/utils/request'
import store from '@/store'
import qs from 'qs'

const scope = 'server'

export const loginByUsername = (username, password, code, randomStr) => {
  const grant_type = 'password'
  const dataObj = qs.stringify({ username: username, password: password })

  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      Authorization: 'Basic aWVsdHNfdGVzdDppZWx0c190ZXN0'
    },
    method: 'post',
    params: { randomStr, code, grant_type, scope },
    data: dataObj
  })
}

export const loginByMobile = (mobile, code) => {
  const grant_type = 'app'
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      Authorization: 'Basic aWVsdHNfdGVzdDppZWx0c190ZXN0'
    },
    method: 'post',
    params: { phone: mobile, code: code, grant_type }
  })
}

export const sendSmsCode = (mobile) => {
  return request({
    url: '/api/mobile/code/' + mobile,
    headers: {
      isToken: false,
      Authorization: 'Basic aWVsdHNfdGVzdDppZWx0c190ZXN0'
    },
    method: 'get'
  })
}

export const refreshToken = (refresh_token) => {
  const grant_type = 'refresh_token'
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      Authorization: 'Basic aWVsdHNfdGVzdDppZWx0c190ZXN0'
    },
    method: 'post',
    params: { refresh_token, grant_type, scope }
  })
}

export const getUserInfo = () => {
  return request({
    url: '/admin/user/info',
    method: 'get'
  })
}

export const logout = () => {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

/**
 * 注册用户
 */
export const registerUser = (userInfo) => {
  return request({
    url: '/admin/register/user',
    method: 'post',
    data: userInfo
  })
}

/**
 * 校验令牌，若有效期小于半小时自动续期
 * @param refreshLock
 */
export const checkToken = (refreshLock, $store, refreshTime) => {
  const token = store.getters.access_token

  request({
    url: '/auth/oauth/check_token',
    headers: {
      isToken: false,
      Authorization: 'Basic aWVsdHNfdGVzdDppZWx0c190ZXN0'
    },
    method: 'get',
    params: { token }
  })
    .then((response) => {
      const expire = response && response.data && response.data.exp
      if (expire) {
        const expiredPeriod = expire * 1000 - new Date().getTime()
        // 小于半小时自动续约
        if (expiredPeriod <= 30 * 60 * 1000) {
          if (!refreshLock) {
            refreshLock = true
            $store.dispatch('RefreshToken').catch(() => {
              clearInterval(refreshTime)
            })
            refreshLock = false
          }
        }
      }
    })
    .catch((error) => {
      console.error(error)
    })
}
