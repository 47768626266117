import request from '@/utils/request'

// 获取文章信息
export function findContent(contentId) {
  return request({
    url: '/api/content/' + contentId,
    method: 'get'
  })
}

// 获取文章列表
export function findList({ first = 0, count = 30, cateId }) {
  var data = { first, count, cateId }
  return request({
    url: '/api/content/list',
    method: 'post',
    data: data
  })
}

