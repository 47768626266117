<template lang="">
  <div v-loading="loadingCourse">
    <el-card :body-style="{ padding: '16px' }" style="height: 64px">
      <div class="top-bar">
        <el-page-header :icon="ArrowLeft" style="display: flex" @back="onBack">
          <template #content>
            {{ state.course.name }}
          </template>
        </el-page-header>
      </div>
    </el-card>
    <el-scrollbar
      style="
        height: calc(100vh - 64px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
      "
    >
      <div style="width: 986px">
        <el-card
          :body-style="{ padding: '16px' }"
          style="margin-top: 16px; height: 262px"
        >
          <div class="course-info">
            <div class="course-info-left">
              <el-image :src="state.course.coverImg">
                <template #placeholder>
                  <div class="image-slot">
                    Loading<span class="dot">...</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div class="course-info-right">
              <h1 class="course-name">{{ state.course.name }}</h1>
              <p class="course-summary">{{ state.course.summary }}</p>
              <div style="flex: 1">
                <p v-if="state.course.isFree === '0'" class="course-price">
                  <span>{{ formatMoney(state.course.price) }}</span>
                  <span class="course-price-origin">{{
                    formatMoney(state.course.originPrice)
                  }}</span>
                </p>
                <p v-else class="course-price">
                  <span>免费</span>
                </p>
              </div>
              <p v-if="!isValid" class="course-action">
                <el-button
                  size="large"
                  type="danger"
                  plain
                  @click="showGoods"
                ><font-awesome-icon
                  icon="fa-solid fa-cart-shopping"
                  style="margin-right: 5px"
                />购买/激活课程</el-button>
              </p>
              <p v-else class="course-action">
                <el-button
                  size="large"
                  type="primary"
                  plain
                  @click="goLesson(undefined)"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-clapperboard"
                    style="margin-right: 5px"
                  />
                  开始学习</el-button>
              </p>
            </div>
          </div>
        </el-card>
        <div v-if="isValid && lastLesson !== null">
          <el-card :body-style="{ padding: '16px' }" style="margin-top: 16px">
            <div class="last-lesson">
              <span style="font-weight: border"><strong>上次学到：</strong></span>
              <span style="flex: 1">{{ lastLesson.title }}</span>
              <span>
                <el-button
                  size="large"
                  type="success"
                  plain
                  @click="goLesson(lastLesson.lessonId)"
                >
                  继续学习
                  <font-awesome-icon
                    icon="fa-solid fa-right-to-bracket"
                    style="margin-left: 5px"
                  />
                </el-button>
              </span>
            </div>
          </el-card>
        </div>
        <el-card :body-style="{ padding: '0' }" style="margin-top: 16px">
          <el-affix :offset="64">
            <ul class="course_tab">
              <li :class="{ active: tabIndex === '1' }" @click="tabIndex = '1'">
                <span>课程详情</span>
              </li>
              <li :class="{ active: tabIndex === '0' }" @click="tabIndex = '0'">
                <span>课程目录</span>
              </li>
            </ul>
          </el-affix>
          <div class="course-body">
            <div v-show="tabIndex === '1'" class="course-detail">
              <el-empty
                v-if="state.course === undefined"
                description="没有内容哦"
              />
              <div v-else v-html="state.course.details" />
            </div>
            <LessonList v-show="tabIndex === '0'" :course-id="courseId" />
          </div>
        </el-card>
      </div>
    </el-scrollbar>
    <GoodsComponent ref="goodsRef" @onSuccess="onSuccess" />
  </div>
</template>
<script setup>
import { ref, reactive, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { findCourse, validCourse, findLast } from '@/apis/course'

import { ArrowLeft } from 'element-plus'

import LessonList from './components/LessonList'

import GoodsComponent from '@/components/Goods/CourseComponent'

const route = useRoute()
const courseId = ref('')
courseId.value = route.params.courseId || ''

const tabIndex = ref('1')
const state = reactive({
  course: {},
  activeName: 'detail',
  isValid: false
})

const goodsRef = ref(undefined)
const showGoods = () => {
  goodsRef.value.show()
}

const loadingCourse = ref(true)
const fetchCourse = async() => {
  loadingCourse.value = true
  var res = await findCourse(courseId.value)
  state.course = res.data.data
  loadingCourse.value = false
}
fetchCourse()

const isValid = ref(false)
const learningId = ref('')
const findValid = async() => {
  var res = await validCourse(courseId.value)
  if (res.data.data === null) {
    isValid.value = false
  } else {
    learningId.value = res.data.data
    isValid.value = true
    await fetchLast()
  }
}
findValid()

provide('courseValid', isValid)

const router = useRouter()
const goLesson = (lessonId) => {
  if (lessonId === undefined) {
    router.push({
      path: '/course/' + courseId.value + '/lesson'
    })
  } else {
    router.push({
      path: '/course/' + courseId.value + '/lesson',
      query: { lessonId: lessonId }
    })
  }
}

const lastLesson = ref(null)
const fetchLast = async() => {
  var dataForm = {
    learningPlanId: learningId.value,
    courseId: courseId.value
  }
  var res = await findLast(dataForm)
  lastLesson.value = res.data.data
}

const onBack = () => {
  router.back()
}

const onSuccess = async() => {
  await findValid()
}

const formatMoney = (value) => {
  var _val
  value = Number(value).toFixed(2)
  if (value === 0) {
    value = 0
    return '￥0'
  } else if (value.split('.')[1].substring(1) === 0) {
    value = Number(value).toFixed(1)
  }
  _val = value.split('.')
  return '￥' + _val[0] + '.' + _val[1]
}
</script>
<style lang="scss" scoped>
h1,
p {
  padding: 0;
  margin: 0;
}

.course-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  max-height: 400px;

  .course-info-left {
    width: 400px;
    height: 230px;
    .el-image {
      width: 400px;
      height: 230px;
      border-radius: 4px;
    }

    .image-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: var(--el-fill-color-light);
      color: var(--el-text-color-secondary);
      font-size: 14px;
    }

    .dot {
      animation: dot 2s infinite steps(3, start);
      overflow: hidden;
    }
  }

  .course-info-left img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .course-info-right {
    flex: 1;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    .course-name {
      font-size: 24px;
      margin-bottom: 4px;
    }

    .course-summary {
      font-size: 16px;
      color: #909399;
    }

    .course-price {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;
      margin-top: 16px;

      span:first-child {
        color: #f56c6c;
        font-weight: bold;
        font-size: 32px;
        margin-right: 8px;
      }

      span.course-price-origin {
        color: #909399;
        font-size: 16px;
        text-decoration: line-through;
      }
    }
  }
}

.last-lesson {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

ul.course_tab {
  padding: 0;
  margin: 0;
  display: flex;
  background-color: #ffffff;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  border-bottom: 1px solid #e4e7ed;

  li {
    margin-right: 16px;
    padding: 16px;
    cursor: pointer;
  }

  li.active {
    font-weight: bold;
    font-size: 16px;
    color: #409eff;
  }
}

.course-body {
  padding: 16px;
  .course-detail {
    line-height: 1.75rem;
  }
  .course-detail:deep(img) {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
