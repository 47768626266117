import { getStore, setStore } from '@/utils/store'
import { findWriting } from '@/apis/member'
const state = {
  writingInfo:
    getStore({
      name: 'writing_info'
    }) || {}
}

const mutations = {
  SET_WRITING_INFO: (state, writingInfo) => {
    state.writingInfo = writingInfo
    setStore({
      name: 'writing_info',
      content: state.writingInfo
    })
  }
}

const actions = {
  getWritingInfo({ commit }, writingInfo) {
    findWriting().then((res) => {
      const data = res.data.data || {}
      commit('SET_WRITING_INFO', data)
    })
  },
  clearWritingInfo({ commit }) {
    commit('SET_WRITING_INFO', {})
  }
}

const writing = {
  state,
  actions,
  mutations
}

export default writing
