<template lang="">
  <el-row :gutter="10">
    <el-col :lg="{span:4}" :md="{span:5}">
      <el-card :body-style="{ padding: '0'}" style="height:calc(100vh - 95px);">
        <dl>
          <dt>官方题库</dt>
          <dd :class="{'active': curCateId==='232'}" @click="changeCate('232','0')">听力<small>（练习模式）</small></dd>
          <dd :class="{'active': curCateId==='233'}" @click="changeCate('233','0')">阅读<small>（练习模式）</small></dd>
          <dd :class="{'active': curCateId==='234'}" @click="changeCate('234','0')">写作<small>（练习模式）</small></dd>
          <dd :class="{'active': curCateId==='231'}" @click="changeCate('231','1')">套题<small>（模考模式）</small></dd>
          <dt>9分题库</dt>
          <dd :class="{'active': curCateId==='21'}" @click="changeCate('21','0')">听力<small>（练习模式）</small></dd>
          <dd :class="{'active': curCateId==='22'}" @click="changeCate('22','0')">阅读<small>（练习模式）</small></dd>
          <dd :class="{'active': curCateId==='150'}" @click="changeCate('150','0')">写作<small>（练习模式）</small></dd>
          <dd :class="{'active': curCateId==='19'}" @click="changeCate('19','1')">套题<small>（模考模式）</small></dd>
        </dl>
      </el-card>
    </el-col>
    <el-col :lg="{span:20}" :md="{span:19}">
      <el-card :body-style="{ padding: '0'}" style="height:calc(100vh - 95px);">
        <el-table
          v-loading="isLoading"
          class="el-table"
          :data="paperList"
          size="large"
          style="width: 100%"
          height="calc(100vh - 95px)"
          stripe
          :row-class-name="tableRowClassName"
          @row-click="handleClick"
        >
          <el-table-column
            label="序号"
            type="index"
            width="80px"
            align="center"
            :index="indexMethod"
          />
          <el-table-column
            label="完成"
            header-align="center"
            align="center"
            width="80px"
          >
            <template #default="scope">
              <span v-if="scope.row.count > 0">
                <font-awesome-icon icon="fa-solid fa-square-check" size="lg" color="#529b2e" />
              </span>
              <span v-else>
                <font-awesome-icon icon="fa-regular fa-square" size="lg" />
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="名称"
            header-align="left"
            align="left"
          >
            <template #default="scope">
              {{ scope.row.paperTitle }}
            </template>
          </el-table-column>
          <el-table-column
            label="题源"
            header-align="center"
            align="center"
            width="340px"
          >
            <template #default="scope">
              <div v-html="scope.row.paperDescription ||'官方题库'" />
            </template>
          </el-table-column>
          <!-- <el-table-column
              label="编号"
              prop="paperId"
              header-align="center"
              align="center"
              width="180px" -->
          />
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="160px"
          >
            <template #default="scope">
              <template v-if="(state.isMember && state.remainDays > 0) || scope.row.isFree === '1'">
                <router-link :to="'/test/' + scope.row.paperId + '/view'">
                  <el-button type="primary" plain>
                    <font-awesome-icon icon="fa-solid fa-desktop" style="margin-right:5px;" /> 开始答题
                  </el-button>
                </router-link>
              </template>
              <template v-else-if="state.isMember">会员过期</template>
              <template v-else>会员专享</template>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <!-- <ul v-loading="isLoading" class="paper-list">
          <li v-for="paper in paperList" :key="paper.paperId">
            <router-link :to="'/test/' + paper.paperId + '/view'">
              <el-tag v-if="paper.isFree === '1'" size="small" type="success" class="is-free">
                免费
              </el-tag>
              <el-card :class="{'is-finished':paper.count > 0}">
                <div class="paper-card">
                  <div class="paper-info">
                    <h1>
                      {{ paper.paperTitle.toUpperCase() }}
                    </h1>
                    <small style="color:#909399" v-html="paper.paperDescription" />
                  </div>
                  <div class="paper-opt">
                    <el-icon><arrow-right-bold /></el-icon>
                  </div>
                </div>
              </el-card>
            </router-link>
          </li>
        </ul> -->
    </el-col>
  </el-row>
</template>
<script setup>
import { ref, watch, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { findList } from '@/apis/paper'

const store = useStore()

const state = reactive({
  member: undefined,
  isMember: false,
  remainDays: 0
})
const paperList = ref([])
const curCateId = ref('232')
const paperType = ref(['0'])
const cateIds = ref(['232'])
const current = ref(0)
const queryForm = reactive({
  first: 0,
  count: 100,
  paperType: [],
  cateIds: []
})

const isLoading = ref(true)
const fetchPaperList = () => {
  queryForm.paperType = paperType.value
  queryForm.cateIds = cateIds.value
  queryForm.first = current * queryForm.count
  findList(queryForm).then(res => {
    paperList.value = res.data.data
    isLoading.value = false
  }).catch(e => {
    console.log(e)
    isLoading.value = false
  })
}
fetchPaperList()

const changeCate = (cate, type) => {
  curCateId.value = cate
  cateIds.value = [cate]
  paperType.value = [type]
  fetchPaperList()
}

onMounted(() => {
  handleMember()
})

watch(
  () => store.getters.memberInfo,
  (newVal, oldVal) => {
    handleMember()
  }
)

const handleMember = () => {
  state.member = store.getters.memberInfo
  if (state.member === undefined || JSON.stringify(state.member) === '{}') {
    state.isMember = false
    state.remainDays = 0
    return
  }

  state.isMember = true
  var _range = getDateDiff(state.member.endTime, 'day')
  if (_range > 0) {
    state.remainDays = _range
  } else {
    state.remainDays = 0
  }
}

const router = useRouter()
const handleClick = (row) => {
  router.push('/test/' + row.paperId + '/view')
}

const tableRowClassName = ({
  row,
  rowIndex
}) => {
  if (row.count > 0) {
    return 'can-select success-row'
  }
  return 'can-select'
}

const indexMethod = (index) => {
  if (index < 9) {
    return '0' + (index + 1)
  }
  return index + 1
}

const getDateDiff = (endTime, diffType) => {
  // 将xxxx-xx-xx的时间格式，转换为 xxxx/xx/xx的格式
  // startTime = startTime.replace(/\-/g, '/')
  if (endTime === undefined) {
    return 0
  }
  endTime = endTime.replace(/\-/g, '/')
  // 将计算间隔类性字符转换为小写
  diffType = diffType.toLowerCase()
  var sTime = new Date() // 开始时间
  var eTime = new Date(endTime) // 结束时间
  // 作为除数的数字
  var timeType = 1
  switch (diffType) {
    case 'second':
      timeType = 1000
      break
    case 'minute':
      timeType = 1000 * 60
      break
    case 'hour':
      timeType = 1000 * 3600
      break
    case 'day':
      timeType = 1000 * 3600 * 24
      break
    default:
      break
  }
  var _diff = (eTime.getTime() - sTime.getTime()) / parseInt(timeType)
  if (_diff >= 1) {
    return parseInt(_diff)
  } else if (_diff > 0) {
    return parseFloat(_diff).toFixed(1)
  } else {
    return 0
  }
}
</script>
<style lang="scss" scoped>
.common-layout {
  background-color: #FFFFFF;
  min-height: 100vh;
  padding: 16px;
}

.page-head {
  background-color: #FFFFFF;
}

dl {
  margin: 0;
  padding: 16px 0;

  dt {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 8px;
    padding: 0 16px;
  }

  dd {
    margin-bottom: 8px;
    margin-inline-start: 0;
    padding: 8px 0 8px 32px;
    font-size: 16px;
    cursor: pointer;
    small{
      color: #606266;
    }
  }

  dd.active {
    background-color: #ecf5ff;
    color: #409eff;
    border-radius: 4px;
    font-weight: bolder;
  }
}

.pagination-container {
  background-color: white;
  padding: 10px;
  margin-top: 0;
  float: right;
}

.el-table:deep(.el-table__row.success-row) {
  background-color: #f0f9eb;
}
.el-table:deep(.el-table__row.can-select) {
  cursor: pointer;
}
</style>
