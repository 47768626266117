<template lang="">
  <div id="highlightable-content">
    <slot />
  </div>
</template>
<script>
import { initContextMenu, destroyContextMenu } from '@/plugins/highlight'

export default {
  name: 'HighlightContainer',
  mounted() {
    initContextMenu()
  },
  unmounted() {
    destroyContextMenu()
  }
}
</script>
<style>
@import '@/plugins/highlight/gel.css';
</style>
