// import Timeline from 'element-plus/lib/components/timeline/src/timeline'
import { getStore, setStore } from '@/utils/store'

const state = {
  paperInfo: {},
  isTest: '0',
  currentQuestionNum: 1,
  testStartTime: undefined,
  fontSize: '0',
  listeningParts: [],
  listeningAnswer: [],
  readingParts: [],
  readingAnswer: [],
  writingParts: [],
  writingAnswer: [],
  testTypes: [],
  playerType:
    getStore({
      name: 'player_type'
    }) || '2'
}

const mutations = {
  SET_ISTEST: (state, isTest) => {
    state.isTest = isTest === undefined ? '0' : isTest
  },
  SET_CURRENT_QUESTION_NUM: (state, num) => {
    state.currentQuestionNum = num === undefined ? 1 : num
  },
  SET_PAPER_INFO: (state, paperInfo) => {
    state.paperInfo = paperInfo
  },
  SET_TEST_START_TIEM: (state, time) => {
    state.testStartTime = time
  },
  SET_LISTENING_PARTS: (state, parts) => {
    state.listeningParts = parts
    if (parts !== undefined && parts.length > 0) {
      var _types = state.testTypes
      _types.push('listening')
      state.testTypes = _types
    }
  },
  SET_READING_PARTS: (state, parts) => {
    state.readingParts = parts
    if (parts !== undefined && parts.length > 0) {
      var _types = state.testTypes
      _types.push('reading')
      state.testTypes = _types
    }
  },
  SET_WRITING_PARTS: (state, parts) => {
    state.writingParts = parts
    if (parts !== undefined && parts.length > 0) {
      var _types = state.testTypes
      _types.push('writing')
      state.testTypes = _types
    }
  },
  SAVE_LISTENING_ANSWER: (state, data) => {
    var _answers = state.listeningAnswer
    var _has = false
    _answers.forEach((element) => {
      if (element.questionNumber === data.questionNumber) {
        element.yourAnswer = data.yourAnswer
        _has = true
        return
      }
    })
    if (!_has) {
      _answers.push(data)
    }
    state.listeningAnswer = _answers
  },
  SAVE_READING_ANSWER: (state, data) => {
    var _answers = state.readingAnswer
    var _has = false
    _answers.forEach((element) => {
      if (element.questionNumber === data.questionNumber) {
        element.yourAnswer = data.yourAnswer
        _has = true
        return
      }
    })
    if (!_has) {
      _answers.push(data)
    }
    state.readingAnswer = _answers
  },
  SAVE_WRITING_ANSWER: (state, data) => {
    var _answers = state.writingAnswer
    var _has = false
    _answers.forEach((element) => {
      if (element.partId === data.partId) {
        element.yourAnswer = data.yourAnswer
        _has = true
        return
      }
    })
    if (!_has) {
      _answers.push(data)
    }
    state.writingAnswer = _answers
  },
  CLEAR_ANSWER: (state) => {
    state.paperInfo = {}
    state.isTest = '0'
    state.currentQuestionNum = 1
    state.testStartTime = ''
    state.listeningParts = []
    state.listeningAnswer = []
    state.readingParts = []
    state.readingAnswer = []
    state.writingParts = []
    state.writingAnswer = []
    state.testTypes = []
  },
  SET_FONT_SIZE: (state, data) => {
    state.fontSize = data
  },
  CHANGE_PLAYER_TYPE: (state, data) => {
    state.playerType = data
    setStore({
      name: 'player_type',
      content: state.playerType
    })
  }
}

const actions = {
  setFontSize({ commit }, size) {
    commit('SET_FONT_SIZE', size)
  },
  changePlayerType({ commit }, size) {
    commit('CHANGE_PLAYER_TYPE', size)
  },
  setIsTest({ commit }, isTest) {
    commit('SET_ISTEST', isTest)
  },
  setCurrentQuestionNum({ commit }, num) {
    commit('SET_CURRENT_QUESTION_NUM', num)
  },
  setTestStartTime({ commit }, time) {
    commit('SET_TEST_START_TIEM', time)
  },
  setPaperInfo({ commit }, paper) {
    var _listeningParts = []
    var _readingParts = []
    var _writingParts = []
    if (paper.paperType === '1') {
      paper.children.forEach((child) => {
        if (child.testCategoryId === '21' || child.testCategoryId === '232') {
          _listeningParts = child.partList
        } else if (
          child.testCategoryId === '22' ||
          child.testCategoryId === '233'
        ) {
          _readingParts = child.partList
        } else if (
          child.testCategoryId === '150' ||
          child.testCategoryId === '234'
        ) {
          _writingParts = child.partList
        }
      })
    } else {
      if (paper.testCategoryId === '21' || paper.testCategoryId === '232') {
        _listeningParts = paper.partList
      } else if (
        paper.testCategoryId === '22' ||
        paper.testCategoryId === '233'
      ) {
        _readingParts = paper.partList
      } else if (
        paper.testCategoryId === '150' ||
        paper.testCategoryId === '234'
      ) {
        _writingParts = paper.partList
      }
    }

    commit('SET_PAPER_INFO', paper)
    commit('SET_LISTENING_PARTS', _listeningParts)
    commit('SET_READING_PARTS', _readingParts)
    commit('SET_WRITING_PARTS', _writingParts)
  },
  saveListeningAnswer({ commit }, data) {
    commit('SAVE_LISTENING_ANSWER', data)
  },
  saveReadingAnswer({ commit }, data) {
    commit('SAVE_READING_ANSWER', data)
  },
  saveWritingAnswer({ commit }, data) {
    commit('SAVE_WRITING_ANSWER', data)
  },
  clearAnswer({ commit }) {
    commit('CLEAR_ANSWER')
  }
}

const test = {
  state,
  actions,
  mutations
}

export default test
