<template lang="">
  <p class="activity-questions bold">{{ activity.activityType }}</p>
  <p
    v-if="activity.startQuestionNum === activity.endQuestionNum"
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Question {{ activity.startQuestionNum }}</p>
  <p
    v-else
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</p>
  <p
    class="activity-title"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activityTitle"
  />
  <p
    class="activity-note"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activitySubTitle"
  />
  <div
    class="activity-content"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >
    <fill-blank :text="activity.activityContent" :activity-id="activity.activityId" />
  </div>
</template>
<script>
import FillBlank from '@/views/test/components/ReadingFillBlanks'
import { mapGetters } from 'vuex'
export default {
  name: 'Question0305Component',
  components: { FillBlank },
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: [],
      size: '0'
    }
  },
  computed: {
    ...mapGetters({ fontSize: 'fontSize' }),
    getFontSize() {
      return this.fontSize
    }
  },
  watch: {
    getFontSize: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.size = this.fontSize
      }
    }
  },
  mounted() {
    this.size = this.fontSize
  }
}
</script>
<style lang="scss" scoped>
.activity-questions{
  margin-bottom: 15px;
}

.activity-title{
  margin-bottom: 5px;
}
.activity-content{
  margin-top:20px;
}
</style>
