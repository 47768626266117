<template lang="">
  <div class="common-layout">
    <v-card class="rounded-0">
      <v-card-title>
        <v-btn variant="text" @click="onBack">返回</v-btn>
        {{ topic.name }}
        <v-icon
          v-if="topic.newStatus === '1'"
          icon="mdi-new-box"
          color="pink"
          size="small"
        />
      </v-card-title>
    </v-card>
    <v-row no-gutters>
      <v-col cols="4" class="pa-4">
        <v-card class="pa-0 elevation-4" style="height: calc(100vh - 86px)">
          <v-card-title> QUESTIONS </v-card-title>
          <v-card-text class="pa-0">
            <template v-if="topic.partType === 'PART1'">
              <ul
                class="question_list overflow-y-auto"
                style="height: calc(100vh - 135px)"
              >
                <div class="px-4 py-2 font-weight-bold">Part 1</div>
                <li
                  v-for="(question, index) in questions"
                  :key="question.speakingQuestionId"
                  class="d-flex justify-start align-start px-4 py-3"
                  :class="{ actived: index === currentIndex }"
                  @click="viewDetail(index)"
                >
                  {{ index + 1 }}.
                  <div class="ml-2 question-title" v-html="question.title" />
                </li>
              </ul>
            </template>
            <template v-else-if="topic.partType === 'PART2&3'">
              <div
                class="question_list overflow-y-auto"
                style="height: calc(100vh - 135px)"
              >
                <div class="px-4 py-2 font-weight-bold">Part 2</div>
                <template v-for="(question, index) in questions">
                  <div
                    v-if="question.partType === 'PART2'"
                    :key="question.speakingQuestionId"
                    class="px-4 py-2 question"
                    :class="{ actived: index === currentIndex }"
                    @click="viewDetail(index)"
                  >
                    <div class="question-title" v-html="question.title" />
                  </div>
                </template>
                <div class="px-4 py-2 mt-2 font-weight-bold">Part 3</div>
                <template v-for="(question, index) in questions">
                  <div
                    v-if="question.partType === 'PART3'"
                    :key="question.speakingQuestionId"
                    class="px-4 py-2 question d-flex justify-start align-center"
                    :class="{ actived: index === currentIndex }"
                    @click="viewDetail(index)"
                  >
                    <div>{{ index }}.</div>
                    <div class="ml-2 question-title" v-html="question.title" />
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <div
                class="question_list overflow-y-auto"
                style="height: calc(100vh - 135px)"
              >
                <div
                  v-for="(question, index) in questions"
                  :key="question.speakingQuestionId"
                  class="pa-4 question"
                  @click="viewDetail(index)"
                >
                  <div class="question-title" v-html="question.title" />
                </div>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8" class="py-4 pr-4">
        <QuestionComponent
          v-if="currentQuestion.speakingQuestionId !== undefined"
          :question-id="currentQuestion.speakingQuestionId"
          :question-index="currentIndex"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { findQuestions, findTopic } from '@/apis/speaking'

import QuestionComponent from './components/QuestionComponent'

const route = useRoute()
const topicId = route.params.topicId || ''

const topic = ref({})
const fetchTopic = async() => {
  const res = await findTopic(topicId)
  topic.value = res.data.data
}
fetchTopic()

const questions = ref([])
const fetchQuestions = async() => {
  var res = await findQuestions(topicId)
  questions.value = res.data.data
  currentQuestion.value = questions.value[0]
  currentIndex.value = 0
}
fetchQuestions()

const currentQuestion = ref({})
const currentIndex = ref(1)
const viewDetail = (index) => {
  currentQuestion.value = questions.value[index]
  currentIndex.value = index
}

const router = useRouter()
const onBack = () => {
  router.back()
}
</script>
<style lang="scss" scoped>
.actived {
  background-color: #eeeeee;
}
.common-layout {
  background-color: #ffffff;
  min-height: 100vh;
}
ul.question_list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 1.75rem;
    font-size: 16px;
    cursor: pointer;
    :deep(p) {
      margin: 0;
      padding: 0;
    }
  }
  li:hover {
    background-color: #eeeeee;
  }
}
.question {
  line-height: 1.75rem;
  font-size: 16px;
  cursor: pointer;
  :deep(p) {
    margin: 0;
    padding: 0;
  }
}
.question:hover {
  background-color: #eeeeee;
}
.audio-text {
  line-height: 1.75rem;
  font-size: 16px;
}
</style>
