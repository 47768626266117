export default {
  year: '2021', // 首页显示的时间
  version: 'v3.4.2', // 控制首页显示的版本号
  logo: 'NC', // 侧边栏搜索的时候顶部展示的文案
  key: 'hibook', // 配置主键,目前用于存储
  title: '新航道图书统一业务平台',
  whiteList: ['/login', '/404', '/401', '/lock'], // 配置无权限可以访问的页面
  whiteTagList: ['/login', '/404', '/401', '/lock'], // 配置不添加tags页面 （'/advanced-router/mutative-detail/*'——*为通配符）
  lockPage: '/lock',
  tokenTime: 6000,
  statusWhiteList: [428],
  // 配置首页不可关闭
  isFirstPage: true,
  fistPage: {
    label: '首页',
    value: '/dashboard/index',
    meta: {
      title: '首页'
    },
    params: {},
    query: {},
    group: [],
    close: false
  },
  // 配置菜单的属性
  menu: {
    props: {
      id: 'id',
      name: 'name',
      label: 'label',
      path: 'path',
      icon: 'icon',
      children: 'children',
      meta: 'meta'
    }
  }
}
