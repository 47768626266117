import TestLayout from '@/layout/Layout'

import TestIndexView from '@/views/test/TestIndexView'
import TestResultView from '@/views/test/pages/TestResultView'
import ListeningIndexView from '@/views/test/listening/ListeningIndexView'
import ReadingIndexView from '@/views/test/reading/ReadingIndexView'

export default [
  {
    path: '/test',
    component: TestLayout,
    redirect: '/test/:paperId/view',
    children: [
      {
        path: ':paperId/view',
        name: 'TestIndexView',
        component: TestIndexView
      },
      {
        path: ':paperId/listening',
        name: 'ListeningIndexView',
        component: ListeningIndexView
      },
      {
        path: ':paperId/reading',
        name: 'ReadingIndexView',
        component: ReadingIndexView
      },
      {
        path: 'end/:logId',
        name: 'TestResultView',
        component: TestResultView
      }
    ]
  }
]
