import axios from 'axios'
import { serialize } from '@/utils/util'
import errorCode from '@/const/errorCode'
import { ElMessageBox, ElMessage } from 'element-plus'
import qs from 'qs'
import store from '@/store'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://pig.hibookapp.com',
  validateStatus: (status) => {
    return status >= 200 && status <= 500 // 默认的
  },
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// Vue.prototype.$http = service;
// Vue.prototype.$httpajax = service;
// Vue.prototype.$axios = service;
window.axios = service

// request interceptor
service.interceptors.request.use(
  (config) => {
    const isToken = (config.headers || {}).isToken === false
    const token = store.getters.access_token
    if (token && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + token // token
    }
    // headers中配置serialize为true开启序列化
    if (config.methods === 'post' && config.headers.serialize) {
      config.data = serialize(config.data)
      delete config.data.serialize
    }

    // 处理get 请求的数组 springmvc 可以处理
    if (config.method === 'get') {
      config.paramsSerializer = function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (res) => {
    const status = Number(res.status) || 200
    const message = res.data.msg || errorCode[status] || errorCode['default']

    // 后台定义 424 针对令牌过去的特殊响应码
    if (status === 424) {
      ElMessageBox.alert('令牌状态已过期，请点击重新登录', '系统提示', {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: '好的',
        callback: (action) => {
          store.dispatch('LogOut').then(() => {
            // 刷新登录页面，避免多次弹框
            window.location.reload()
          })
          store.dispatch('clearMemberInfo')
        }
      })
      return
    }

    if (status !== 200 || res.data.code === 1) {
      ElMessage({
        message: message,
        type: 'error'
      })
      return Promise.reject(new Error(message))
    }

    return res
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)

export default service
