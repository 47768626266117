<template lang="">
  <div class="common-layout">
    <el-row :gutter="10">
      <el-col :md="12">
        <el-card :body-style="{ padding: '16px'}" style="margin-bottom:10px;">
          <div class="writing">
            <div style="font-weight:bold;">
              <template v-if="state.writingInfos.status === '1'">
                批改账户被冻结，请联系管理员处理
              </template>
              <template v-else>
                批改账户余额 <span class="quantity">{{ state.writingInfos.task1Quantity ?? 0 }}</span> 篇
              </template>
            </div>
            <div>
              <el-button type="danger" plain @click="showGoods"><font-awesome-icon icon="fa-solid fa-cart-shopping" style="margin-right:5px;" />购买/激活批改卡</el-button>
            </div>
          </div>
        </el-card>
        <LastWritingLog style="height:calc(100vh - 97px);" />
      </el-col>
      <el-col :md="12">
        <AllWritingLog style="height:calc(100vh - 20px);" />
      </el-col>
    </el-row>
    <GoodsComponent ref="goodsRef" />
  </div>
</template>
<script setup>
import { ref, reactive, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { ElMessageBox } from 'element-plus'
import { getObj } from '@/apis/sys_param'

import GoodsComponent from '@/components/Goods/GoodsComponent'
import LastWritingLog from './components/LastWritingLog'
import AllWritingLog from './components/AllWritingLog'

const store = useStore()
const router = useRouter()
const state = reactive({
  writingInfos: {}
})

watch(
  () => store.getters.writingInfo,
  (newVal, oldVal) => {
    state.writingInfos = store.getters.writingInfo
  }
)

const goodsRef = ref(undefined)
const showGoods = () => {
  goodsRef.value.show()
}

onMounted(() => {
  console.log(store.getters.writingInfo)
  state.writingInfos = store.getters.writingInfo
})

const getWrtingPublic = () => {
  getObj('WRITING_PUBLISH_KEY').then(res => {
    if (res.data.data === '0') {
      ElMessageBox.alert('<p>系统维护中，请稍后再试...</p>', '温馨提示', {
        autofocus: false,
        confirmButtonText: 'OK',
        showClose: false,
        showConfirmButton: true,
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          router.push({ path: '/', replace: true })
        }
      })
    }
  })
}
getWrtingPublic()
</script>
<style lang="scss" scoped>
.common-layout{
  background-color: #FFFFFF;
  min-height: 100vh;
  padding: 10px;
}

.writing{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #414042;
}

.quantity{
  font-weight: bolder;
  color: #F56C6C;
  font-size: 22px;
  margin: 0 5px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.member{
  width: 100%;
  height: 220px;
  background-color:#414042;
  color: #dcb71d;
  margin-bottom: 15px;
}
.member .member-card{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding:30px;
}

.member .member-card .card-info{
  display: inline-block;
}

.member .member-card img{
  display: block;
  width: 150px;
  height: auto;
}

.member .member-card .username{
  font-size:30px;
  font-weight: 700;
  margin-bottom: 10px;
}

.member .member-card .member-num{
  font-size:20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.member .member-card .end-time{
  font-size:16px;
  margin-bottom: 10px;
}

.member .member-card .end-time span{
  color: #E31837;
}

.member .member-card .member-note{
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.qrcode_container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.qrcode_container .qrcode_img{
  margin-right: 30px;
}
.qrcode_container .qrcode_img img{
  display: block;
  width: 180px;
  height: auto;
}

.qrcode_container .introduce ol{
  margin: 8px 0 0 0;
  padding: 0;
  padding-inline-start: 20px;
  line-height: 1.5rem;
}
</style>
