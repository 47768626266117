<template lang="">
  <div class="pa-4">
    <v-row justify="start">
      <v-col
        v-for="course in state.courseList"
        :key="course.courseId"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card class="elevation-4" @click="goCourseDetail(course.courseId)">
          <v-img :src="course.coverImg" width="100%" cover />
          <v-card-item>
            <v-card-title>
              {{ course.name }}
            </v-card-title>
            <v-card-sub-title class="text-grey">
              {{ course.summary || '' }}
            </v-card-sub-title>
          </v-card-item>
          <v-card-actions>
            <span class="text-red text-h5">￥{{ course.price }} <small>.00</small></span>
            <v-spacer />
            <v-btn
              append-icon="mdi-chevron-right"
              @click.stop="goCourseDetail(course.courseId)"
            >了解详情</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'

import { findList } from '@/apis/course'

const state = reactive({
  courseList: []
})

const first = ref(0)
const size = ref(20)
const fetchCourses = async() => {
  var res = await findList(first.value, size.value)
  state.courseList = res.data.data
  first.value = first.value + res.data.data.length
}
fetchCourses()

const router = useRouter()
const goCourseDetail = (courseId) => {
  router.push({
    path: '/course/' + courseId + '/detail'
  })
}
</script>
<style lang="scss" scoped>
ul.course-list {
  padding-inline-start: 0;
  padding: 0;
  margin: 0;
  list-style: none;
}
div.course-item {
  cursor: pointer;
  .el-image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 198px;
    background: var(--el-fill-color-light);
    color: var(--el-text-color-secondary);
    font-size: 14px;
  }

  .dot {
    animation: dot 2s infinite steps(3, start);
    overflow: hidden;
  }

  h1 {
    font-size: 20px;
    line-height: 30px;
    padding: 0 0 0 16px;
    margin: 16px 0 8px 0;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    padding: 0 0 0 16px;
    margin: 0 0 16px 0;
    color: #909399;
  }
}
</style>
