<template lang="">
  <template v-if="currentChat !== undefined">
    <template v-if="currentChat.type === 'question'">
      <QuestionTitleComponent :item="currentChat" />
    </template>
    <template
      v-if="currentChat.type === 'answer1' && currentChat.content !== ''"
    >
      <AnswerOneComponent :item="currentChat" @record="handleRecord" />
    </template>
    <template
      v-if="currentChat.type === 'answer2' && currentChat.content !== ''"
    >
      <AnswerTwoComponent :item="currentChat" @record="handleRecord" />
    </template>
    <template v-if="currentChat.type === 'custom'">
      <AnswerCustomComponent :item="currentChat" :question="props.question" />
    </template>
  </template>
</template>
<script setup>
import { ref, watch, onMounted, defineProps, defineEmits } from 'vue'

import QuestionTitleComponent from './QuestionTitleComponent'
import AnswerOneComponent from './AnswerOneComponent'
import AnswerTwoComponent from './AnswerTwoComponent'
import AnswerCustomComponent from './AnswerCustomComponent'

const emits = defineEmits(['record'])
const props = defineProps({
  question: {
    type: Object,
    default: () => {}
  },
  item: {
    type: Object,
    default: () => {}
  }
})
const currentChat = ref({})
onMounted(() => {
  currentChat.value = props.item
})
watch(
  () => props.item,
  () => {
    currentChat.value = props.item
  }
)
const handleRecord = (v, t) => {
  emits('record', v, t)
}
</script>
<style lang="scss" scoped>
.audio-text {
  font-size: 16px;
  line-height: 1.75rem;
}
</style>
