<template lang="">
  <el-container class="on-test">
    <el-header>
      <top-bar :key="'writing-start'" :limit-minutes="60" :test-type="'Writing'" @handle-finish="handleFinish" />
    </el-header>
    <el-main>
      <section class="app-main">
        <el-row style="margin-top:20px; padding-left:20px;">
          <el-col :span="24">
            <div class="text-left">
              <h5>IELTS Academic Writing</h5>
              <p>Time: 1 hour</p>
              <h5>INSTRUCTIONS TO CANDIDATES</h5>
              <ul>
                <li>Answer <strong>both</strong> parts.</li>
                <li>You can change your answers at any time during the test.</li>
              </ul>
              <h5>INFORMATION FOR CANDIDATES</h5>
              <ul>
                <li>There are two parts in this test.</li>
                <li>Part 2 contributes twice as much as Part 1 to the writing score.</li>
                <li>The test clock will show you when there are 10 minutes and 5 minutes remaining.</li>
              </ul>
            </div>
            <div class="text-center">
              <p>Do not click 'Start test' until you are told to do so.</p>
              <el-button class="btn bc-button" @click="$emit('writingNextStep','WritingTestView')">Start test</el-button>
            </div>
          </el-col>
        </el-row>
      </section>
    </el-main>
    <Note :text="note" />
  </el-container>
</template>
<script>
import TopBar from '@/components/TopBar'
import Note from '@/components/Note'

export default {
  name: 'WritingStartPage',
  components: { TopBar, Note },
  emits: ['writingNextStep'],
  data() {
    return {
      note: 'Please click the “Start test” button to start the familiarisation test, in your real IELTS test there will be an invigilator in the room to advise when to do this.'
    }
  },
  methods: {
    handleFinish(val) {
      this.$emit('writingNextStep', val)
    }
  }
}
</script>
<style lang="scss" scoped>
h5{
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0;
  line-height: 1.2;
}
.btn.bc-button {
    margin: 0 auto;
    background-color: transparent;
    color: #283e53;
    font-weight: bold;
    border: none;
    box-shadow: 0 2px 2px grey;
}
</style>
