<template lang="">
  <div class="log-container">
    <el-row>
      <el-col :lg="{span:22,offset:1}" :md="{span:24}">
        <section style="padding:15px 15px 0 15px;">
          <el-divider>
            <h2 style="margin:0;color:#1c2233;">READING</h2>
          </el-divider>
        </section>
        <section class="log-section">
          <el-row :gutter="10">
            <el-col :span="14" style="display:flex;flex-direction:row;justify-content:flex-start">
              <ul class="menu">
                <li v-for="(part, index) in parts" :key="part.partId" :class="{'actived':currentReadingPart.partId === part.partId }" @click="tabChange(index)"><span>PASSAGE {{ index + 1 }}</span></li>
              </ul>
              <el-card :body-style="{ padding: '0px' }" style="height:calc(100vh - 30px);">
                <div style="padding:10px 15px;text-align:right;"><el-switch v-model="showCN" active-color="#E31837" /> 翻译</div>
                <div style="display:flex;flex-direction:row;">
                  <el-scrollbar style="height:calc(100vh - 82px);">
                    <div class="part-content-en" v-html="currentReadingPart.partContentEn" />
                  </el-scrollbar>
                  <el-scrollbar v-show="showCN" style="height:calc(100vh - 82px);">
                    <div class="part-content-en" v-html="currentReadingPart.partContentCn" />
                  </el-scrollbar>
                </div>
              </el-card>
            </el-col>
            <el-col :span="10">
              <div class="right-panel">
                <div class="right-panel_content">
                  <template v-if="tab2Index === 0">
                    <el-card :body-style="{ padding: '0px' }" style="height:calc(100vh - 30px);">
                      <div class="sheet_title">IELTS Reading Answer Sheet</div>
                      <el-table :data="answerList" style="width: 100%; display:block;" border stripe height="calc(100vh - 118px)">
                        <el-table-column prop="questionNum" label="#" width="64" align="center">
                          <template #default="scope">
                            <strong>{{ scope.row.questionNum }}</strong>
                          </template>
                        </el-table-column>
                        <el-table-column prop="rightAnswer" label="正确答案" align="center" />
                        <el-table-column prop="yourAnswer" label="我的答案" align="center">
                          <template #default="scope">
                            <span class="my_answer" :class="{'right':scope.row.right === true || scope.row.isRight === true, 'wrong':scope.row.right === false || scope.row.isRight === false }">
                              {{ JSON.parse(scope.row.yourAnswer).toString() }}
                            </span>
                          </template>
                        </el-table-column>
                        <el-table-column label="#" align="center" width="100">
                          <template #default="scope">
                            <div class="check_span">
                              <div>
                                <span><font-awesome-icon icon="fa-solid fa-check" /></span>
                                <span class="rectangle" />
                              </div>
                              <span>{{ scope.row.questionNum }}</span>
                              <div>
                                <span><font-awesome-icon icon="fa-solid fa-xmark" /></span>
                                <span class="rectangle" />
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                      <div class="sheet_foot">做对 <span class="right-num">{{ readingScore }}</span> / 40 题</div>
                    </el-card>
                  </template>
                  <template v-else>
                    <el-card :body-style="{ padding: '0px' }" class="parts" style="height:calc(100vh - 395px);">
                      <section v-for="(part, index) in parts" v-show="currentReadingPart.partId === part.partId" :key="part.partId">
                        <div class="part-title__">
                          <span>PASSAGE {{ index +1 }}</span><span>Questions {{ part.startQuestionNum }} - {{ part.endQuestionNum }}</span>
                        </div>
                        <el-scrollbar style=" height:calc(100vh - 450px);">
                          <div v-for="activity in part.activityList" :key="activity.activityId" class="activity">
                            <div class="activity-content">
                              <question-0301 v-if="activity.activityTypeId === '62'" :activity="activity" />
                              <question-0302 v-if="activity.activityTypeId === '101'" :activity="activity" />
                              <question-0303 v-if="activity.activityTypeId === '63'" :activity="activity" />
                              <question-0304 v-if="activity.activityTypeId === '65'" :activity="activity" />
                              <question-0305 v-if="activity.activityTypeId === '61'" :activity="activity" />
                              <question-0305 v-if="activity.activityTypeId === '68'" :activity="activity" />
                            </div>
                          </div>
                        </el-scrollbar>
                      </section>
                    </el-card>
                    <el-card :body-style="{ padding: '0px' }" style="height:350px; margin-top:15px;">
                      <div class="questions-title">
                        <span>第 {{ currenReadingNum }} / 40 题</span>
                        <div class="actions">
                          <span v-if="currenReadingNum === 1" class="icon disable"><el-icon><ArrowLeftBold /></el-icon></span>
                          <span v-else class="icon" @click="onChange(currenReadingNum - 1)"><el-icon><ArrowLeftBold /></el-icon></span>
                          <span v-if="currenReadingNum === 40" class="icon disable"><el-icon><ArrowRightBold /></el-icon></span>
                          <span v-else class="icon" @click="onChange(currenReadingNum + 1)"><el-icon><ArrowRightBold /></el-icon></span>
                        </div>
                      </div>
                      <ul>
                        <li v-for="question in questionList" v-show="currenReadingNum === question.questionNum" :key="'listening-question-' + question.questionId">
                          <el-scrollbar style="height:286px;">
                            <div class="questions">
                              <div class="question-answer">
                                <strong>正确答案：</strong>  <span>{{ question.questionAnswer }}</span>
                              </div>
                              <div :class="'question-answer ' + (getResult(question.questionNum) !== undefined ? ((getResult(question.questionNum).isRight || getResult(question.questionNum).right) ? 'right' : 'wrong'):'')">
                                <strong>我的答案：</strong> <span>{{ getResult(question.questionNum) !== undefined ? handleAnswer(question.questionNum):'同上' }}</span>
                              </div>
                              <el-divider border-style="dashed">解析</el-divider>
                              <template v-if="showAnalysisFree">
                                <div class="question-analyses" v-html="question.questionAnalyses" />
                              </template>
                              <template v-else>
                                <div class="question-analyses_">
                                  <p>题库会员可查看详细解析</p>
                                  <el-button type="danger" plain @click="showGoods"><font-awesome-icon icon="fa-solid fa-cart-shopping" style="margin-right:5px;" />购买/激活会员</el-button>
                                </div>
                              </template>
                            </div>
                          </el-scrollbar>
                        </li>
                      </ul>
                    </el-card>
                  </template>
                </div>
                <ul class="menu2">
                  <li :class="{'actived':tab2Index === 0 }" @click="tab2Change(0)">
                    <span>答题卡</span>
                  </li>
                  <li :class="{'actived':tab2Index === 1 }" @click="tab2Change(1)">
                    <span>答案解析</span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </section>
      </el-col>
    </el-row>
    <GoodsComponent ref="goodsRef" />
  </div>
</template>
<script>
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue'

import { findPaper } from '@/apis/paper'

import Question0301 from './components/Question0301.vue'
import Question0302 from './components/Question0302.vue'
import Question0303 from './components/Question0303.vue'
import Question0304 from './components/Question0304.vue'
import Question0305 from './components/Question0305.vue'
import GoodsComponent from '@/components/Goods/GoodsComponent'

import { computed } from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: { ArrowLeftBold, ArrowRightBold, Question0301, Question0302, Question0303, Question0304, Question0305, GoodsComponent },
  inject: ['testLog', 'readingParts', 'readingAnswers', 'readingNum'],
  provide() {
    return {
      currentNum: computed(() => this.currenReadingNum),
      changeNum: this.changeNum
    }
  },
  data() {
    return {
      currenReadingNum: 1,
      currentReadingPart: {},
      questionList: [],
      answerList: [],
      tab2Index: 0,
      readingScore: 0,
      showCN: false,
      showAnalysisFree: false,
      tlog: undefined
    }
  },
  computed: {
    ...mapGetters({ memberInfo: 'memberInfo' }),
    getMember() {
      return this.memberInfo
    },
    getLog() {
      return this.testLog
    },
    parts() {
      return this.readingParts.value
    },
    answers() {
      return this.readingAnswers
    },
    score() {
      return this.readingNum.value
    }
  },
  watch: {
    getMember: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        if (this.handleMember()) {
          this.showAnalysisFree = true
        }
      }
    },
    getLog() {
      this.tlog = this.testLog
    },
    parts() {
      this.currentReadingPart = this.parts[0]
      this.getQuestions()
    },
    answers() {
      this.answerList = this.answers
    },
    score() {
      this.readingScore = this.readingNum.value
    }
  },
  created() {
    this.currentReadingPart = this.parts[0]
    this.getQuestions()

    this.answerList = this.answers
    this.readingScore = this.readingNum.value

    this.tlog = this.testLog
    this.fetchPaper()
  },
  methods: {
    async fetchPaper() {
      console.log(this.tlog)
      var _res = await findPaper(this.tlog.objectId)
      var _paper = _res.data.data
      if (_paper.analysisFree === '1' || this.handleMember()) {
        this.showAnalysisFree = true
      }
    },
    getQuestions() {
      var _questions = []
      this.readingParts.value.forEach(part => {
        part.activityList.forEach(activity => {
          if (activity.activityType === '0302') {
            for (var i = activity.startQuestionNum; i <= activity.endQuestionNum; i++) {
              var _question = Object.assign({}, activity.questionList[0])
              _question.questionNum = i
              _questions.push(_question)
            }
          } else {
            activity.questionList.forEach(question => {
              _questions.push(question)
            })
          }
        })
      })
      this.questionList = _questions
    },
    handleAnswer(num) {
      var _a = this.getResult(num).yourAnswer
      return JSON.parse(_a).toString()
    },
    getResult(num) {
      var r
      this.readingAnswers.value.forEach(e => {
        if (e.questionNum === num) {
          r = e
          return
        }
      })
      return r
    },
    onChange(i) {
      if (i >= 1 && i <= 40) {
        this.currenReadingNum = i
        this.onChangeNum(i)
      }
    },
    changeNum(i) {
      if (i >= 1 && i <= 40) {
        this.currenReadingNum = i
      }
    },
    onChangeNum(i) {
      var _this = this
      this.parts.forEach(e => {
        if (i >= e.startQuestionNum && i <= e.endQuestionNum) {
          _this.currentReadingPart = e
          return
        }
      })
    },
    tabChange(e) {
      this.currentReadingPart = this.parts[e]
      this.currenReadingNum = this.currentReadingPart.startQuestionNum
    },
    tab2Change(index) {
      this.tab2Index = index
    },
    handleMember() {
      var _isMember = this.handleIsMember()
      var isValid = this.handlerIsValid()
      if (_isMember && isValid) {
        return true
      } else {
        return false
      }
    },
    handleIsMember() {
      var _member = this.memberInfo
      if (_member !== undefined && JSON.stringify(_member) !== '{}') {
        return true
      }
      return false
    },
    handlerIsValid() {
      var _member = this.memberInfo
      if (_member === undefined || JSON.stringify(_member) === '{}') {
        return false
      }
      if (_member.status === '1') {
        return false
      }

      var _end = new Date(_member.endTime.replace(/\-/g, '\/'))
      var _now = new Date()
      if (_member.endTime !== undefined &&
        this.compareDate(_end, _now)) {
        return true
      } else {
        return false
      }
    },
    compareDate(date1, date2) {
      var oDate1 = new Date(date1)
      var oDate2 = new Date(date2)
      if (oDate1.getTime() > oDate2.getTime()) {
        return true // 第一个大
      } else {
        return false // 第二个大
      }
    },
    showGoods() {
      this.$refs.goodsRef.show()
    }
  }
}
</script>
<style lang="scss" scoped>
.log-container {
  background-color: #F2F3F5;
}

.log-section {
  padding: 15px;
}

.parts {
  height: calc(100vh - 30px);
  width: 100%;
}

.parts .part-title__ {
  font-weight: bold;
  padding: 15px 15px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.activities {
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px);
}

.activity {
  display: block;
  padding: 15px;
}

.activity:last-child {
  border-bottom: none;
}

.activity-questions {
  margin-bottom: 15px;
}

.activity-title {
  margin-bottom: 5px;
}

.activity-content table {
  border: 1px solid #F3F3F3;
}

.questions-title {
  padding: 10px 15px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #E4E7ED;
}

.questions-title .actions {
  width: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.questions-title .icon {
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  display: flex;
  padding: 5px;
  background-color: #F0F2F5;
  border: 1px solid #1c2233;
  color: #1c2233;
  cursor: pointer;
}

.questions-title .icon:hover {
  background-color: #1c2233;
  color: #FEC171;
}

.questions-title .icon.disable {
  border: 1px solid #C0C4CC;
  background-color: #F0F2F5;
  color: #C0C4CC;
  cursor: auto;
}

.questions {
  padding: 15px;
  font-size: 14px;
  line-height: 1.5rem;
}

.question-analyses {
  font-size: 14px;
  line-height: 1.8rem;
}

.question-answer span {
  font-weight: bold;
}

.question-answer.right span {
  color: #40d039
}

.question-answer.wrong span {
  color: #E31837
}

.part-content-en {
  background-color: #ffffff;
  font-size: 14px;
  padding: 15px;
  line-height: 1.8rem;
  overflow: auto;
}

.part-content-en:deep(h1) {
  font-size: 20px;
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.menu {
  display: block;
  flex-basis: auto;
  white-space: nowrap;
}

ul.menu li {
  /* line-height: 10px; */
  margin-bottom: 3px;
  cursor: pointer;
}

ul.menu li.actived span {
  background-color: #FFFFFF;
}

ul.menu li span {
  width: 110px;
  /* margin: 60px; */
  display: inline-block;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: #303133;
  font-size: 14px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #E6E8EB;
}

.my_answer.right {
  color: #40d039
}

.my_answer.wrong {
  color: #E31837
}

ul.menu2 {
  display: block;
  flex-basis: auto;
  white-space: nowrap
}

ul.menu2 li {
  /* line-height: 10px; */
  margin-bottom: 3px;
  cursor: pointer;
}

ul.menu2 li.actived span {
  background-color: #FFFFFF;
}

ul.menu2 li span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 90px;
  width: 40px;
  font-weight: bold;
  color: #303133;
  font-size: 14px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #E6E8EB;
  writing-mode: vertical-lr;
}

.right-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .right-panel_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    ;
  }
}

.check_span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  span {
    display: inline-block;
    font-size: 14px;
    text-align: center;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .rectangle {
    display: inline-block;
    border: 1px solid #303133;
    padding: 2px 8px;
  }
}

.sheet_title {
  padding: 8px 0;
  text-align: center;
  background-color: #1c2233;
  color: #ffffff;
  font-weight: bold;
}

.sheet_foot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  color: #303133;
  font-weight: bold;

  .right-num {
    color: #E31837;
    margin: 0 4px;
  }
}

.question-analyses_ {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
