<template lang="">
  <v-sheet style="width: 100%">
    <v-container class="pa-4" fluid>
      <v-card
        v-for="question in questions"
        :key="question.questionId"
        class="mb-4"
      >
        <v-card-text>
          <div class="d-flex justify-space-between align-center mb-2">
            <div class="text-h6">{{ question.title }}</div>
            <AudioPlayer
              ref="audioPart"
              :auto-play="false"
              :audios="[question.titleAudio]"
              :controls="'noMuted noBackward noForward noBackward5 noForward5 noProcess noTime noSpeed noVolume'"
              b-color="#ffffff"
              t-color="#1c2233"
            />
          </div>
          <div v-if="question.answerAudio1 !== null">
            <div class="text-body-1 font-weight-bold mb-2">答案1：</div>
            <div style="height: 45px">
              <AudioPlayer
                ref="answerAudio1"
                :auto-play="false"
                :audios="[question.answerAudio1]"
                :controls="'noMuted noBackward noForward noBackward5 noForward5 noTime noVolume'"
                b-color="#EDE7F6"
                t-color="#673AB7"
                thumb-color="#673AB7"
              />
            </div>
          </div>
          <div v-if="question.answerAudio2 !== null">
            <div>答案2：</div>
            <AudioPlayer
              ref="answerAudio2"
              :auto-play="false"
              :audios="[question.answerAudio2]"
              :controls="'noMuted noBackward noForward noBackward5 noForward5 noTime noVolume'"
              b-color="#EDE7F6"
              t-color="#673AB7"
              thumb-color="#673AB7"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-sheet>
</template>
<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { findFreeQuestions } from '@/apis/speaking'

import AudioPlayer from '@/components/AudioPlayer/AudioPlayer'

const route = useRoute()
const topicId = route.params.topicId
const questions = ref([])
const fetchQuestions = async() => {
  var res = await findFreeQuestions(topicId)
  console.log(res.data)
  questions.value = res.data.data
}
fetchQuestions()
</script>
<style lang="scss"></style>
