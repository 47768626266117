import store from '@/store/index'

import { saveTestLog, initTestLog } from '@/apis/test'

export const saveAnswers = async({ finish, doInit }) => {
  var _finish = finish || '0'
  var _doInit = doInit || false
  var _paper = store.getters.paperInfo

  var _listeningResult = handleListening()
  var _readingResult = handleReading()
  var _writingResult = handleWriting()

  var _testLog = {
    'isFinished': _finish,
    'objectId': _paper.paperId,
    'objectType': _paper.paperType,
    'objectTitle': _paper.paperTitle,
    'objectNum': _paper.paperNum,
    'task1': _writingResult.task1,
    'task2': _writingResult.task2,
    'listening': _listeningResult.listening,
    'listeningScore': _listeningResult.score,
    'reading': _readingResult.reading,
    'readingScore': _readingResult.score,
    'testType': _paper.paperType
  }
  var _testLogId
  var res
  if (_doInit) {
    res = await initTestLog(_testLog)
  } else {
    res = await saveTestLog(_testLog)
  }
  _testLogId = res.data.data['testLogId']
  if (_finish === '1') {
    store.dispatch('clearAnswer')
  }
  return _testLogId
}

export const checkAnswer = (type, right, your) => {
  if (your === null || your === undefined || your === '' || your === 'null') {
    return { isRight: false, count: 0 }
  }

  switch (type) {
    case '48':
      return { isRight: right === your, count: right === your ? 1 : 0 }
    case '49':
      var _right = right.split(',').sort()
      if (your.length > _right.length) {
        return { isRight: false, count: 0 }
      }
      var _your = your.length > 1 ? your.sort() : your
      var _count = 0
      for (var i = 0; i < _your.length; i++) {
        for (var j = 0; j < _right.length; j++) {
          if (_your[i] === _right[j]) {
            _count++
          }
        }
      }
      return { isRight: _right.length === _count, count: _count }
    case '50':
      var _your50 = your.replace(/(^\s*)|(\s*$)/g, '')
      if (right.split('/').length > 0) {
        var _re = false
        right.split('/').forEach((e) => {
          if (e.toLowerCase() === _your50.toLowerCase()) {
            _re = true
            return
          }
        })
        return { isRight: _re, count: _re ? 1 : 0 }
      } else {
        var _result = right.toLowerCase() === _your50.toLowerCase()
        return { isRight: _result, count: _result ? 1 : 0 }
      }
    case '51':
      return { isRight: right === your, count: right === your ? 1 : 0 }
    case '62':
      return { isRight: right === your, count: right === your ? 1 : 0 }
    case '101':
      var _right1 = right.split(',').sort()
      if (your.length > _right1.length) {
        return { isRight: false, count: 0 }
      }
      var _your1 = your.length > 1 ? your.sort() : your
      var _count1 = 0
      for (var i1 = 0; i1 < _your1.length; i1++) {
        for (var j1 = 0; j1 < _right1.length; j1++) {
          if (_your1[i1] === _right1[j1]) {
            _count1++
          }
        }
      }
      return { isRight: _right1.length === _count1, count: _count1 }
    case '63':
      return { isRight: right === your, count: right === your ? 1 : 0 }
    case '65':
      return { isRight: right === your, count: right === your ? 1 : 0 }
    case '61':
      var _your61 = your.replace(/(^\s*)|(\s*$)/g, '')
      if (right.split('/').length > 0) {
        var _re1 = false
        right.split('/').forEach((e) => {
          if (e.toLowerCase() === _your61.toLowerCase()) {
            _re1 = true
            return
          }
        })
        return { isRight: _re1, count: _re1 ? 1 : 0 }
      } else {
        var _result1 = right.toLowerCase() === _your61.toLowerCase()
        return { isRight: _result1, count: _result1 ? 1 : 0 }
      }
    case '68':
      var _your68 = your.replace(/(^\s*)|(\s*$)/g, '')
      if (right.split('/').length > 0) {
        var _re68 = false
        right.split('/').forEach((e) => {
          if (e.toLowerCase() === _your68.toLowerCase()) {
            _re68 = true
            return
          }
        })
        return { isRight: _re68, count: _re68 ? 1 : 0 }
      } else {
        var _result68 = right.toLowerCase() === _your68.toLowerCase()
        return { isRight: _result68, count: _result68 ? 1 : 0 }
      }
    case '0306':
      return { isRight: right === your, count: right === your ? 1 : 0 }
    default:
      return { isRight: false, count: 0 }
  }
}

export const handleListening = () => {
  var _listeningParts = store.getters.listeningParts
  if (_listeningParts === null || _listeningParts.length === 0) {
    return { listening: undefined, score: 0 }
  }

  var correctCount = 0
  var questionResult = []
  _listeningParts.forEach((part) => {
    var _activityList = part.activityList ?? []
    _activityList.forEach((activity) => {
      var _questionList = activity.questionList ?? []
      _questionList.forEach((question) => {
        var _rightAnswer = question.questionAnswer
        var _yourAnswer = getListeningAnswer(question.questionNum)
        var _checkResult = checkAnswer(
          activity.activityTypeId, _rightAnswer, _yourAnswer) ?? { isRight: false, count: 0 }
        correctCount = correctCount + _checkResult.count
        if (activity.activityTypeId === '49') {
          for (var i = activity.startQuestionNum; i <= activity.endQuestionNum; i++) {
            var _result0 = {
              questionNum: i,
              rightAnswer: _rightAnswer,
              yourAnswer: JSON.stringify(_yourAnswer ?? 'No answer'),
              isRight: _checkResult.isRight
            }
            questionResult.push(_result0)
          }
        } else {
          var _result = {
            questionNum: question.questionNum,
            rightAnswer: _rightAnswer,
            yourAnswer: JSON.stringify(_yourAnswer ?? 'No answer'),
            isRight: _checkResult.isRight
          }
          questionResult.push(_result)
        }
      })
    })
  })
  return { listening: questionResult, score: correctCount }
}

export const getListeningAnswer = (num) => {
  var _listeningAnswer = store.getters.listeningAnswer
  var _questionAnswer
  _listeningAnswer.forEach(element => {
    if (parseInt(element.questionNumber) === num) {
      _questionAnswer = element.yourAnswer
      return
    }
  })
  return _questionAnswer
}

export const handleReading = () => {
  var _readingParts = store.getters.readingParts
  if (_readingParts === null || _readingParts.length === 0) {
    return { reading: undefined, score: 0 }
  }

  var correctCount = 0
  var questionResult = []
  _readingParts.forEach((part) => {
    var _activityList = part.activityList ?? []
    _activityList.forEach((activity) => {
      var _questionList = activity.questionList ?? []
      _questionList.forEach((question) => {
        var _rightAnswer = question.questionAnswer
        var _yourAnswer = getReadingAnswer(question.questionNum)
        var _checkResult = checkAnswer(
          activity.activityTypeId, _rightAnswer, _yourAnswer) ?? { isRight: false, count: 0 }
        correctCount = correctCount + _checkResult.count
        if (activity.activityTypeId === '101') {
          for (var i = activity.startQuestionNum; i <= activity.endQuestionNum; i++) {
            var _result0 = {
              questionNum: i,
              rightAnswer: _rightAnswer,
              yourAnswer: JSON.stringify(_yourAnswer ?? 'No answer'),
              isRight: _checkResult.isRight
            }
            questionResult.push(_result0)
          }
        } else {
          var _result = {
            questionNum: question.questionNum,
            rightAnswer: _rightAnswer,
            yourAnswer: JSON.stringify(_yourAnswer ?? 'No answer'),
            isRight: _checkResult.isRight
          }
          questionResult.push(_result)
        }
      })
    })
  })
  return { reading: questionResult, score: correctCount }
}

export const getReadingAnswer = (num) => {
  var _readingAnswer = store.getters.readingAnswer
  var _questionAnswer
  _readingAnswer.forEach(element => {
    if (parseInt(element.questionNumber) === num) {
      _questionAnswer = element.yourAnswer
      return
    }
  })
  return _questionAnswer
}

export const handleWriting = () => {
  var _writingParts = store.getters.writingParts
  if (_writingParts === null || _writingParts.length === 0) {
    return { task1: undefined, task2: undefined }
  }

  var _task1
  var _task2
  _writingParts.forEach((part) => {
    var _yourAnswer = getWritingAnswer(part.partId)
    if (part.typeCategoryId === '38') {
      _task1 = _yourAnswer ?? undefined
    } else {
      _task2 = _yourAnswer ?? undefined
    }
  })
  return { task1: _task1, task2: _task2 }
}

export const getWritingAnswer = (num) => {
  var _writingAnswer = store.getters.writingAnswer
  var _questionAnswer
  _writingAnswer.forEach(element => {
    if (element.partId === num) {
      _questionAnswer = element.yourAnswer
      return
    }
  })
  return _questionAnswer
}

export const loadTestLog = (testLog) => {
  var _resultInfo = JSON.parse(testLog.resultInfo)
  var _listening = _resultInfo.listening
  if (_listening !== undefined) {
    _listening.forEach(e => {
      store.dispatch('saveListeningAnswer', {
        'questionNumber': e.questionNum,
        'yourAnswer': JSON.parse(e.yourAnswer) === 'No answer' ? undefined : JSON.parse(e.yourAnswer)
      })
    })
  }
  var _reading = _resultInfo.reading
  if (_reading !== undefined) {
    _reading.forEach(e => {
      store.dispatch('saveReadingAnswer', {
        'questionNumber': e.questionNum,
        'yourAnswer': JSON.parse(e.yourAnswer) === 'No answer' ? undefined : JSON.parse(e.yourAnswer)
      })
    })
  }
  var _writing = [testLog.task1, testLog.task2]
  if (_writing !== undefined) {
    var _writingParts = store.getters.writingParts
    _writingParts.forEach((e, index) => {
      store.dispatch('saveWritingAnswer', {
        'partId': e.partId,
        'yourAnswer': _writing[index]
      })
    })
  }
}

export const handleScore = (rightCount) => {
  if (rightCount >= 39 && rightCount <= 40) {
    return '9.0'
  } else if (rightCount >= 37 && rightCount <= 38) {
    return '8.5'
  } else if (rightCount >= 35 && rightCount <= 36) {
    return '8.0'
  } else if (rightCount >= 33 && rightCount <= 34) {
    return '7.5'
  } else if (rightCount >= 30 && rightCount <= 32) {
    return '7.0'
  } else if (rightCount >= 27 && rightCount <= 29) {
    return '6.5'
  } else if (rightCount >= 23 && rightCount <= 26) {
    return '6.0'
  } else if (rightCount >= 20 && rightCount <= 22) {
    return '5.5'
  } else if (rightCount >= 16 && rightCount <= 19) {
    return '5.0'
  } else if (rightCount >= 13 && rightCount <= 15) {
    return '4.5'
  } else if (rightCount >= 10 && rightCount <= 12) {
    return '4.0'
  } else if (rightCount >= 6 && rightCount <= 9) {
    return '3.5'
  } else if (rightCount >= 4 && rightCount <= 5) {
    return '3.0'
  } else if (rightCount === 3) {
    return '2.5'
  } else if (rightCount === 2) {
    return '2.0'
  } else if (rightCount === 1) {
    return '1.0'
  } else {
    return '0.0'
  }
}
