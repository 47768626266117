<template>
  <div class="common-layout">
    <el-card :body-style="{ padding: '16px'}" style="margin-bottom:16px;">
      <el-page-header :icon="ArrowLeft" style="display:flex;" @back="onBack">
        <template #content>
          <span class="text-large font-600 mr-3"> 雅思写作题库 </span>
        </template>
      </el-page-header>
    </el-card>
    <el-row :gutter="16">
      <el-col :span="12">
        <el-card :body-style="{ padding: '16px'}" style="height:calc(100vh - 106px);">
          <dl class="query_form">
            <dt>作文类型:</dt>
            <dd>
              <span :class="{'active':queryForm.typeCategoryId ===38}" @click="handleChangeType(38)">Task 1</span>
            </dd>
            <dd>
              <span :class="{'active':queryForm.typeCategoryId ===39}" @click="handleChangeType(39)">Task 2</span>
            </dd>
          </dl>
          <dl class="query_form">
            <dt>写作题库:</dt>
            <dd>
              <span :class="{'active':queryForm.testCategoryId ===150}" @click="handleChangeTest(150)">9分达人</span>
            </dd>
            <dd>
              <span :class="{'active':queryForm.testCategoryId ===234}" @click="handleChangeTest(234)">官方真题</span>
            </dd>
            <dd>
              <span @click="goFreeWriting">自选题目</span>
            </dd>
          </dl>
          <el-table
            v-loading="dataLoading"
            highlight-current-row
            :data="tableData"
            border
            stripe
            style="width: 100%; margin-top:16px;"
            height="calc(100vh - 313px)"
            @current-change="handleCurrentChange"
          >
            <el-table-column prop="partTitle" label="题目" />
            <el-table-column prop="partId" label="编号" width="180" align="center" />
          </el-table>
          <el-pagination background layout="total, prev, next" :current-page="current" :page-size="size" :default-page-size="size" :total="total" style="float:right; margin-top:16px; margin-bottom: 16px;" @current-change="currentChangeHandle" />
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card v-loading="partInfoLoading" :body-style="{ padding: '0 0 0 16px'}" style="height:calc(100vh - 188px);">
          <el-scrollbar style="height:calc(100vh - 186px); padding: 16px 16px 16px 0;">
            <div v-if="currentPart === undefined" style="margin-top:16px;">请先选择题库</div>
            <div v-else>
              <h2>{{ currentPart.partTitle }}</h2>
              <div v-for="a in currentPart.activityList" :key="a.activityId">
                <div class="activity_content" v-html="a.activityContent??''" />
              </div>
            </div>
          </el-scrollbar>
        </el-card>
        <el-card :body-style="{ padding: '16px'}" style="margin-top:16px; text-align: center;">
          <el-button type="primary" :disabled="currentPart === undefined" @click="goWriting"><el-icon style="margin-right:5px;"><EditPen /></el-icon> 开始写作</el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, onUnmounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'

import { ElMessage } from 'element-plus'
import { findParts, findPartInfo } from '@/apis/part'
import { EditPen, ArrowLeft } from '@element-plus/icons-vue'

const tableData = ref([])
const current = ref(1)
const size = ref(18)
const total = ref(0)
const currentPart = ref(undefined)
const queryForm = reactive({
  testCategoryId: 150,
  typeCategoryId: 38,
  status: 0
})
const dataLoading = ref(true)
const partInfoLoading = ref(false)

onMounted(() => {
  nextTick(() => {
    // 禁用右键
    document.oncontextmenu = new Function('event.returnValue=false')
    // 禁用选择
    document.onselectstart = new Function('event.returnValue=false')
  })
})

onUnmounted(() => {
  // 取消禁用右键
  document.oncontextmenu = new Function('event.returnValue=true')
  // 取消禁用选择
  document.onselectstart = new Function('event.returnValue=true')
})

const fetchParts = () => {
  dataLoading.value = true
  findParts(current.value, size.value, queryForm).then(res => {
    tableData.value = res.data.data.records
    current.value = res.data.data.current
    total.value = res.data.data.total
    dataLoading.value = false
  }).catch(e => {
    dataLoading.value = false
  })
}
fetchParts()

const findPart = (partId) => {
  partInfoLoading.value = true
  findPartInfo(partId).then(res => {
    currentPart.value = res.data.data
    partInfoLoading.value = false
  }).catch(e => {
    partInfoLoading.value = false
  })
}

const handleQuery = () => {
  current.value = 1
  fetchParts()
}

const handleChangeType = (v) => {
  queryForm.typeCategoryId = v
  handleQuery()
}

const handleChangeTest = (v) => {
  queryForm.testCategoryId = v
  handleQuery()
}

const currentChangeHandle = (val) => {
  current.value = val
  fetchParts()
}

const handleCurrentChange = (val) => {
  findPart(val.partId)
}

const router = useRouter()
const goWriting = () => {
  var part = currentPart.value
  if (part === undefined) {
    ElMessage.error('请先选择题库！')
    return
  }
  router.push({
    path: '/writing/info',
    query: { 'partId': part.partId }
  }, () => {})
}

const goFreeWriting = () => {
  router.push({
    path: '/writing/free'
  }, () => {})
}

const onBack = () => {
  router.back()
}
</script>
<style lang="scss" scoped>
.common-layout{
  background-color: #FFFFFF;
  min-height: 100vh;
  padding: 16px;
}

.page-head{
  background-color: #FFFFFF;
}

.activity_content{
  line-height: 1.5rem;
  font-size: 14;
}
.activity_content:deep(img){
  margin: 0 auto;
  display: block;
  width: 70%;
  height: auto;
}

dl.query_form{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  dt{
    font-weight: bolder;
  }
  dd{
    span{
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 32px;
      font-size: 14px;
      border-radius: 4px;
    }
    span.active,span:hover{
      background-color: #ecf5ff;
      color: #409eff;
      border: 1px solid #409eff;
    }
  }
}

</style>
