import Layout from '@/layout/FrontLayout'

import NineSpeakingHome from '@/views/nine/SpeakingHome'
import SpeakingContent from '@/views/nine/SpeakingContent'
import SpeakingBook from '@/views/nine/SpeakingBook'
import SpeakingMedia from '@/views/nine/media/SpeakingMedia'
import SpeakingQuestion from '@/views/nine/media/SpeakingQuestion'

export default [
  {
    path: '/nine',
    redirect: '/nine/speaking',
    meta: {
      isAuth: false
    },
    children: [
      {
        path: 'speaking',
        component: Layout,
        children: [
          {
            path: '',
            name: 'NineSpeakingHome',
            component: NineSpeakingHome
          },
          {
            path: 'content/:contentId',
            name: 'SpeakingContent',
            component: SpeakingContent
          },
          {
            path: 'book',
            name: 'SpeakingBook',
            component: SpeakingBook
          },
          {
            path: 'media',
            name: 'SpeakingMedia',
            component: SpeakingMedia
          },
          {
            path: ':topicId/question',
            name: 'SpeakingQuestion',
            component: SpeakingQuestion
          }
        ]
      }
    ]
  }
]
