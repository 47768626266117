<template lang="">
  <div
    ref="lyricView"
    class="lyric-view"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
  >
    <ol
      v-if="lyric"
      ref="lyricWrapper"
      class="lyric-wrapper"
      :style="{
        transform: `translate3d(0, ${state.nowTranslateY}px, 0)`,
        transition: `${state.isDragging? 'none':`all ease ${props.lyricScrollTime}ms`}`
      }"
    >
      <li
        v-for="(item, index) in allLyric"
        ref="lyricLine"
        :key="index"
        :style="{padding: `${unitDivide(props.lyricMargin, 2)} 16px`, 'min-height':'65px'}"
        :class="{
          [props.lyricCenterClass]: index === state.centerLyricIdx,
          [props.lyricActiveClass]: index === activeLyricIdx
        }"
        @click="changeActiveIndex(index)"
      >
        <template v-if="props.showText">
          <p class="en_text" :style="{lineHeight: props.lyricLineheight, 'margin-bottom':'8px'}">{{ item[1] }}</p>
          <template v-if="props.showCn">
            <p class="cn_text" :style="{lineHeight: props.lyricLineheight}">{{ item[2] }}</p>
          </template>
        </template>
        <template v-else>
          <div ref="textRefs" class="text_container">
            已隐藏原文
          </div>
        </template>
      </li>
    </ol>

    <!-- 拖拽时中间标记 -->
    <div v-if="state.isDragging" class="center-mark">
      <div
        class="triangle"
        :style="{
          borderColor: `transparent transparent transparent ${props.triangleColor}`,
          borderWidth: `${unitDivide(props.triangleWidth, 1.732)} 0 ${unitDivide(props.triangleWidth, 1.732)} ${props.triangleWidth}`
        }"
        @click="changeCurrentTime"
      />
      <div class="line" :style="{background: props.centerLineColor}" />
      <div
        class="target-time"
        :style="{color: props.centerTimeColor}"
      >{{ allLyric[state.centerLyricIdx] && timeToStr(allLyric[state.centerLyricIdx][0]) }}</div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, defineProps, defineEmits, defineExpose, computed, watch, nextTick } from 'vue'

const emit = defineEmits(['changeCurrentTime', 'submit'])

const props = defineProps({
  currentTime: {
    type: Number,
    required: true
  },
  // 原词，格式为{开始时间: 歌词, ...}
  lyric: {
    type: Object,
    required: true
  },
  showText: {
    type: Boolean,
    default: false
  },
  showCn: {
    type: Boolean,
    default: false
  },
  // 当前唱到的歌词类名
  lyricActiveClass: String,
  // 拖拽时中间歌词类名
  lyricCenterClass: String,
  // 滚动到目标歌词时间，单位ms
  lyricScrollTime: {
    type: Number,
    default: 400
  },
  // 拖拽结束后隔多长后恢复滚动
  dragendWaitTime: {
    type: Number,
    default: 3000
  },
  // 每句歌词及翻译与下一句歌词及翻译的间隔
  lyricMargin: {
    type: String,
    default: '20px'
  },
  // 每句歌词及翻译行高
  lyricLineheight: {
    type: String,
    default: '1.5em'
  },
  // 拖拽时左边出现的三角形颜色
  triangleColor: {
    type: String,
    default: 'orange'
  },
  // 拖拽时左边出现的等边三角形的高度
  triangleWidth: {
    type: String,
    default: '40px'
  },
  // 拖拽时中间线的颜色
  centerLineColor: {
    type: String,
    default: '#E6A23C'
  },
  // 拖拽时中间歌词开始时间颜色
  centerTimeColor: {
    type: String,
    default: '#E6A23C'
  }
})

const state = reactive({
  // 开始接触的clientY
  startClientY: 0,
  // 开始接触的translateY
  startTranslateY: 0,
  // 是否正在拖拽
  isDragging: false,
  // 当前歌词容器滚动的高度
  nowTranslateY: 0,
  // touchend后设置的定时器
  timer: null,
  // 找出当前视野中最接近中间的歌词，返回下标
  centerLyricIdx: -1,
  // 每句歌词的高度
  offsetHeightList: [],
  // 包裹全部歌词的容器
  wrapper: null,
  // 容器高度
  wrapperHeight: 0,
  // 歌词可视区高度
  viewHeight: 0,
  firstLyricHeight: 0,
  lastLyricHeight: 0
})

const lyricWrapper = ref(null)
onMounted(() => {
  state.wrapper = lyricWrapper.value
})

// 包含原词和译词（如果有的话）
// 格式：[[开始时间, 原词, 译词],...]
const allLyric = computed(() => {
  const result = props.lyric
  result.sort((a, b) => a[0] - b[0])
  return result
})

// 每句歌词及其翻译对应的div的offsetTop
const offsetTopList = computed(() => {
  const resultArr = []
  let totalHeight = 0
  lyricLine.value.forEach(line => {
    const height = line.offsetHeight
    state.offsetHeightList.push(height)
    resultArr.push(totalHeight)
    totalHeight += height
  })
  return resultArr
})

// 当前高亮的歌词下标（也就是第几句，从0开始）
const activeLyricIdx = computed(() => {
  const currentTime = props.currentTime * 1000
  const _allLyric = allLyric.value
  for (let i = 0, len = _allLyric.length; i < len; i++) {
    if (
      _allLyric[i][0] <= currentTime &&
          ((_allLyric[i + 1] && _allLyric[i + 1][0] > currentTime) ||
            !_allLyric[i + 1])
    ) {
      return i
    }
  }
  return 0
})

// 第一句歌词距容器顶部的最大距离
const lyricTopPadding = computed(() => {
  return state.viewHeight / 2 - state.firstLyricHeight / 2
})

// 最后一句歌词距容器底部的最大距离
const lyricBottomPadding = computed(() => {
  return state.viewHeight / 2 - state.lastLyricHeight / 2
})

// nowTranslateY的最小值，也就是歌词向上滚动的最大值
const minTranslateY = computed(() => {
  return -(state.wrapperHeight - state.viewHeight + lyricBottomPadding.value)
})

// 带单位的字符串除以数字，如60px/2=15px
const unitDivide = (unitNum, num) => {
  const num1 = parseFloat(unitNum)
  const unit = unitNum.replace(num1, '')
  return `${num1 / num}${unit}`
}

// 把秒数转换为xx:xx的形式
const timeToStr = (num) => {
  num = Math.round(num / 1000)
  let second = num % 60
  let minute = Math.floor(num / 60)
  if (second < 10) {
    second = '0' + second
  }
  if (minute < 10) {
    minute = '0' + minute
  }
  return minute + ':' + second
}

// 设置第1句和最后1句歌词高度
const lyricLine = ref([])
const setFirstLastLyricHeight = () => {
  const _lyricLine = lyricLine.value
  state.firstLyricHeight = _lyricLine[0].offsetHeight
  state.lastLyricHeight = _lyricLine[_lyricLine.length - 1].offsetHeight
}

const changeActiveIndex = (index) => {
  const _allLyric = allLyric.value
  emit('changeCurrentTime', _allLyric[index][0])
  activeLyricIdx.value = index
  updateLyricPos()
}

// 拖拽后点击视野中间左侧三角形更改进度
const changeCurrentTime = () => {
  const _allLyric = allLyric.value
  emit('changeCurrentTime', _allLyric[state.centerLyricIdx][0])
  nextTick(() => {
    // 访问更新后的 DOM
    state.isDragging = false
  })
}

// 实时获取当前元素的translateY
const getTranslateY = (el) => {
  const curStyle = window.getComputedStyle(el)
  const curTransform = curStyle.transform
  return curTransform.split(',')[5].replace(')', '')
}

// 更新歌词位置
const updateLyricPos = () => {
  // 没有拖拽歌词
  if (!state.isDragging) {
    const _offsetTopList = offsetTopList.value
    const scrollTarget =
    _offsetTopList[activeLyricIdx.value] +
    state.offsetHeightList[activeLyricIdx.value] / 2 -
    state.viewHeight / 2
    state.nowTranslateY = -scrollTarget
  }
}

// 触屏事件
const onTouchStart = (e) => {
  state.isDragging = true
  clearTimeout(state.timer)
  state.startClientY = e.touches[0].clientY
  state.startTranslateY = Number(getTranslateY(state.wrapper))
  state.nowTranslateY = state.startTranslateY
}

const onTouchMove = (e) => {
  e.preventDefault() // 防止屏幕跟着滚动
  const clientY = e.touches[0].clientY
  const targetTranslateY =
  state.startTranslateY + clientY - state.startClientY

  if (targetTranslateY > lyricTopPadding.value) {
    // 抵达上边界
    state.nowTranslateY = lyricTopPadding.value
  } else if (targetTranslateY < minTranslateY.value) {
    // 移出下边界
    state.nowTranslateY = minTranslateY.value
  } else {
    state.nowTranslateY = targetTranslateY
    state.centerLyricIdx = findCenterLyricIdx()
  }
}

const onTouchEnd = () => {
  state.timer = setTimeout(() => {
    state.isDragging = false
    state.centerLyricIdx = -1
  }, props.dragendWaitTime)
}

// 找出当前视野中最接近中间的歌词，返回下标
const findCenterLyricIdx = () => {
  const halfViewHeight = state.viewHeight / 2
  const nowTranslateY = state.nowTranslateY
  const _offsetTopList = offsetTopList.value
  for (let i = 0, len = _offsetTopList.length; i < len; i++) {
    const v = _offsetTopList[i]
    if (
      (nowTranslateY + v < halfViewHeight &&
      _offsetTopList[i + 1] &&
            nowTranslateY + _offsetTopList[i + 1] > halfViewHeight) ||
          !_offsetTopList[i + 1]
    ) {
      return i
    }
  }
}

watch(() => props.currentTime, (newX) => {
  updateLyricPos()
})

const lyricView = ref(null)
watch(allLyric, (newX) => {
  nextTick(() => {
    // 获取第1句 和最后 1句歌词高度
    setFirstLastLyricHeight()
    // 获取可视区高度
    state.viewHeight = lyricView.value.offsetHeight
    // 获取容器高度
    state.wrapperHeight = lyricWrapper.value.offsetHeight
    // 设置初始滚动
    state.nowTranslateY = lyricTopPadding.value
  })
})

const handleBackward = () => {
  console.log('handleBackward2', activeLyricIdx.value)
  if (activeLyricIdx.value > 0) {
    changeActiveIndex(activeLyricIdx.value - 1)
  }
}

const handleForward = () => {
  if (activeLyricIdx.value < allLyric.value.length - 1) {
    changeActiveIndex(activeLyricIdx.value + 1)
  }
}

defineExpose({
  handleBackward,
  handleForward
})
</script>
<style lang="scss" scoped>
.lyric-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .center-mark {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    align-items: center;
    // 拖拽三角形
    .triangle {
      border-style: solid;
    }
    // 中间指示线
    .line {
      flex: 1;
      height: 1px;
      margin: 0 16px;
    }
  }
}
.lyric-wrapper {
  transition: ease 0.3s;
  list-style: decimal-leading-zero;
  // 每一句歌词及其翻译
  & > div p {
    padding: 0;
    margin: 0;
  }
  li{
    cursor: pointer;
  }
}

.en_text{
  font-size: 16px;
  color: #303133;
}

.cn_text{
  color: #606266;
  font-size: 14px;
  margin-bottom: 0;
}

.text_container{
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#F5F7FA;
  color:#606266;
  border-radius: 4px;
}
</style>
