<template lang="">
  <button class="btn font-weight-bolder pointer" :class="{'highlight':currentNum == questionNum, 'answered':questionAnswer != undefined && questionAnswer != '', 'for-review ':indexOf(questionNum) >= 0}" @click="onClick" @tap="onClick">{{ questionNum }}</button>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AnswerButton',
  props: {
    partId: {
      type: String,
      default: undefined
    },
    reviewList: {
      type: Array,
      default: undefined
    },
    questionNum: {
      type: Number,
      default: 1
    }
  },
  emits: ['on-handel'],
  data() {
    return {
      questionAnswer: undefined
    }
  },
  computed: {
    ...mapGetters({ listeningAnswer: 'listeningAnswer', currentNum: 'currentQuestionNum' }),
    getCurrentNum() {
      return this.currentNum
    },
    getAnswers() {
      return this.listeningAnswer
    }
  },
  watch: {
    getCurrentNum: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
      }
    },
    getAnswers: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        var _this = this
        this.listeningAnswer.forEach(element => {
          if (parseInt(element.questionNumber) === parseInt(_this.questionNum)) {
            _this.questionAnswer = element.yourAnswer
          }
        })
      }
    }
  },
  created() {
    this.listeningAnswer.forEach(element => {
      if (parseInt(element.questionNumber) === parseInt(this.questionNum)) {
        this.questionAnswer = element.yourAnswer
      }
    })
  },
  methods: {
    onClick() {
      this.$store.dispatch('setCurrentQuestionNum', parseInt(this.questionNum))
      var _data = {
        'partId': this.partId,
        'questionNum': parseInt(this.questionNum)
      }
      this.$emit('on-handel', _data)
    },
    indexOf(val) {
      if (this.reviewList === undefined) return -1
      for (var i = 0; i < this.reviewList.length; i++) {
        if (this.reviewList[i] === val) return i
      }
      return -1
    }
  }
}
</script>
<style lang="">

</style>
