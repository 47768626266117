<template>
  <label class="one-radio" :class="{'is-checked':label == value}">
    <span class="one-radio_input">
      <input
        v-model="model"
        type="radio"
        class="one-radio_label"
        :value="label"
        :name="name"
      >
    </span>
    <span class="one-radio_label">
      <slot />
      <!-- 如果没有传值，就把label作为文本显示 -->
      <template v-if="!$slots.default">{{ label }}</template>
    </span>
  </label>
</template>

<script>
// 定义一个value属性,value值是接收到的label
export default {
  name: 'HiRadio',
  inject: {
    RadioGroup: {
      default: ''
    }
  },
  props: {
    label: {
      type: String,
      default: '0'
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  computed: {
    // 如果用双向绑定一个计算属性，必须提供一个get和set;需要写成一个对象
    model: {
      get() {
        // model的值是父组件传过来的value
        // console.log('value')
        // return this.value
        return this.isGroup ? this.RadioGroup.value : this.value
      },
      set(value) {
        // 触发祖先组件RadioGroup给当前组件注册的input事件
        // this.$emit('input',value)
        this.isGroup ? this.RadioGroup.$emit('input', value) : this.$emit('input', value)
      }
    },
    isGroup() {
      // 用于判断radio是否被radioGroup所包裹(使用2个感叹号，将其改为布尔值)
      return !!this.RadioGroup
    }
  }
}
</script>
<style lang="scss" scoped>
.one-radio{
  color: #333;
  font-weight: 400;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: nowrap;
  outline: none;
  margin-bottom: 5px;
  .one-radio_label{
    display: inline-block ;
    padding-left: 10px;
    line-height: 1.5rem;
    white-space: normal;
    word-wrap: break-word;
  }
}
// 选中的样式
.one-radio.is-checked{
  .one-radio_input{
    .one-radio_inner{
      border-color: #409EFF;
      background-color: #409EFF;
      &:after{
        transform: translate(-50%,-50%) scale(1);
      }
    }
  }
  .one-radio_label{
    color:#409EFF;
  }
}
</style>
