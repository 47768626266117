<template lang="">
  <div class="chapter">
    <div class="chapter-title" @click="showLessons = !showLessons">
      <div>{{ props.lesson.title }}</div>
      <div @click.stop="showLessons = !showLessons">
        <font-awesome-icon
          v-if="showLessons"
          icon="fa-solid fa-folder-open"
          style="color: #303133"
        />
        <font-awesome-icon
          v-else
          icon="fa-solid fa-folder-closed"
          style="color: #303133"
        />
      </div>
    </div>
    <Transition name="run" appear>
      <ul v-show="showLessons">
        <li
          v-for="child in props.lesson.children"
          :key="child.courseItemId"
          class="lesson-list-item"
        >
          <div class="lesson-list-item-left">
            <span style="margin-right: 8px">
              <el-tag>视频</el-tag>
            </span>
            <span class="lesson-title">
              {{ child.title }}
            </span>
            <span class="lesson-duration">
              {{ handleTime(child.courseDuration) }}
            </span>
          </div>
          <div class="lesson-list-item-right">
            <font-awesome-icon
              v-if="child.isFree === '1' || props.isValid"
              icon="fa-solid fa-circle-play"
              style="color: #409eff; font-size: 16px"
              @click.stop="goLesson(child.courseItemId)"
            />
            <font-awesome-icon
              v-else
              icon="fa-solid fa-lock"
              style="color: #909399; font-size: 16px"
            />
          </div>
        </li>
      </ul>
    </Transition>
  </div>
</template>
<script setup>
import { ref, defineProps } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  courseId: {
    type: String,
    default: ''
  },
  lesson: {
    type: Object,
    default: () => {}
  },
  isValid: {
    type: Boolean,
    default: false
  }
})

console.log('isValid', props.isValid)

const showLessons = ref(false)

const router = useRouter()
const goLesson = (id) => {
  router.push({
    path: '/course/' + props.courseId + '/lesson',
    query: { lessonId: id }
  })
}

const handleTime = (val) => {
  var time = val
  var minutes = Math.floor(time / 60)
  var seconds = Math.ceil(time % 60)

  if (seconds < 10) {
    seconds = '0' + seconds
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }
  return minutes + ':' + seconds
}
</script>
<style lang="scss" scoped>
ul,
li {
  padding: 0;
  margin: 0;
  margin-inline-start: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.chapter {
  width: 100%;
}
.chapter-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;
  span:first-child {
    flex: 1;
  }
}
ul.lesson-list {
  li {
    padding: 16px 0 0 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}
.lesson-list-item-left {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  span.lesson-title {
    flex: 1;
    color: #222;
  }
  span.lesson-duration {
    font-size: 14px;
    color: #909399;
  }
}
.lesson-list-item-right {
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.run-enter-active {
  animation: fadeIn 1s linear 0s;
}
.run-leave-active {
  animation: fadeOut 1s linear 0s;
}
</style>
