<template lang="">
  <p class="activity-questions bold">{{ activity.activityType }}</p>
  <p
    v-if="activity.startQuestionNum === activity.endQuestionNum"
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Question {{ activity.startQuestionNum }}</p>
  <p
    v-else
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</p>
  <p
    class="activity-title"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activityTitle"
  />
  <p
    class="activity-note"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activitySubTitle"
  />
  <div
    class="activity-content"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >
    <div v-html="activity.activityContent" />
    <ul style="margin-top:15px;">
      <li
        v-for="question in questionList"
        :id="'question_' + question.questionNum"
        :key="question.questionId"
      >
        <span class="q-number">{{ question.questionNum }}.</span>
        <span class="q-title">{{ question.questionTitle }}</span>
        <el-select v-model="queModels[question.questionNum]" placeholder="Select the correct letter" @change="onChange($event, question.questionNum)">
          <el-option
            v-for="(item, index) in answerList"
            :key="item.index + '' + index + question.questionNum"
            :label="item.index + ' ' +item.content"
            :value="item.index"
          />
        </el-select>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Question0304Component',
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: [],
      questionList: [],
      answerList: [],
      size: '0'
    }
  },
  computed: {
    ...mapGetters({ fontSize: 'fontSize', readingAnswer: 'readingAnswer', currentNum: 'currentQuestionNum' }),
    getFontSize() {
      return this.fontSize
    },
    getCurrentNum() {
      return this.currentNum
    }
  },
  watch: {
    getCurrentNum: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.handleFocus()
      }
    },
    getFontSize: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.size = this.fontSize
      }
    }
  },
  created() {
    this.questionList = this.activity.questionList
    this.answerList = JSON.parse(this.activity.activityFormwork)

    var _this = this
    this.readingAnswer.forEach(element => {
      _this.queModels[element.questionNumber] = element.yourAnswer
    })
  },
  mounted() {
    this.size = this.fontSize
  },
  methods: {
    onChange(e, questionNum) {
      this.$store.dispatch('setCurrentQuestionNum', questionNum)
      this.$store.dispatch('saveReadingAnswer', {
        'questionNumber': questionNum,
        'yourAnswer': e
      })
    },
    handleFocus() {
      var node = document.getElementById('question_' + this.currentNum)
      if (node) {
        // node.scrollIntoView({
        //   behavior: 'smooth'
        // })
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.activity-questions{
  margin-bottom: 15px;
}
.activity-title{
  margin-bottom: 5px;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li{
  margin-bottom: 10px;
  line-height: 2.0em;
}

ul li .q-number{
  font-weight: bold;
  margin-right: 5px;
}

ul li .q-title{
  margin-right: 10px;
}
</style>
