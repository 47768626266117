<template lang="">
  <div class="answers">
    <div v-html="text" />
  </div>
</template>
<script>
import { compile } from 'vue/dist/vue.esm-bundler.js'
import question from './ListeningQuestionInput'

export default {
  name: 'QuestionComponent',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    question
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    activityId: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    return compile(props.text)
  },
  data() {
    return {
      qNum: ''
    }
  },
  methods: {
  }

}
</script>
<style lang="scss" scoped>
div.answers:deep(div, p) {
  line-height: 40px;
  margin-bottom: 10px;
}

div.answers:deep(strong) {
  font-size: 18px;
}

div.answers:deep(table div) {
  margin-bottom: 5px;
}

div.answers:deep(table) {
  border: 1px solid #333;

  td {
    border: 1px solid #333;
  }
}
</style>
