<template lang="">
  <div class="d-flex justify-space-between align-start mb-8">
    <audio
      ref="audioRef"
      autoplay
      @play="onPlay"
      @pause="onPause"
      @ended="onEnded"
    >
      <source :src="currentChat.audio" type="audio/mpeg">
    </audio>
    <v-avatar>
      <v-img src="https://cdn.vuetifyjs.com/images/lists/5.jpg" alt="John" />
    </v-avatar>
    <div
      class="flex-grow-1 d-flex flex-column justify-start align-stretch ml-2"
    >
      <span class="font-weight-bold">Cath</span>
      <div
        class="d-flex justify-space-between align-start bg-indigo-lighten-5 rounded-xl rounded-ts-0 my-2 pa-4"
        style="max-width: 80%; min-width: 50%"
      >
        <div class="audio-text" v-html="currentChat.content" />
        <div v-if="currentChat.audio !== null" class="ml-4">
          <v-btn
            v-if="!playing"
            icon="mdi-play"
            color="indigo"
            size="small"
            density="comfortable"
            @click="play"
          />
          <v-btn
            v-else
            icon="mdi-pause"
            color="indigo"
            size="small"
            density="comfortable"
            @click="pause"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, defineProps } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})
const currentChat = ref(props.item)
watch(
  () => props.item,
  () => {
    currentChat.value = props.item
    if (currentChat.value.audio !== null) {
      audioRef.value.src = currentChat.value.audio
      audioRef.value.play()
    }
  }
)

const audioRef = ref()
const play = () => {
  audioRef.value.play()
}

const pause = () => {
  audioRef.value.pause()
  playing.value = false
}

// 当音频开始播放
const playing = ref(false)
const onPlay = (res) => {
  playing.value = true
}

const onPause = (res) => {
  playing.value = false
}

const onEnded = (res) => {
  playing.value = false
}
</script>
<style lang="scss" scoped>
.audio-text {
  font-size: 16px;
  line-height: 1.75rem;
  :deep(p) {
    margin: 0;
    padding: 0;
  }
  :deep(div) {
    margin: 0;
    padding: 0;
  }
}
</style>
