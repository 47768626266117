import Layout from '@/layout'

import SpeakingHome from '@/views/speaking/SpeakingHome'
import TestDetail from '@/views/speaking/TestDetail'
import TopicDetail from '@/views/speaking/TopicDetail'

export default [
  {
    path: '/speaking',
    component: Layout,
    redirect: '/speaking',
    children: [
      {
        path: '',
        name: 'SpeakingHome',
        component: SpeakingHome
      },
      {
        path: 'test/:testId',
        name: 'TestDetail',
        component: TestDetail
      },
      {
        path: 'topic/:topicId',
        name: 'TopicDetail',
        component: TopicDetail
      }
    ]
  }
  // {
  //   path: '/course/:courseId',
  //   component: LessonLayout,
  //   redirect: '/',
  //   children: [
  //     {
  //       path: '',
  //       name: 'CourseDetail',
  //       component: CourseDetail
  //     },
  //     {
  //       path: 'lesson',
  //       name: 'LessonPlay',
  //       component: LessonPlay
  //     }
  //   ]
  // }
]
