<template lang="">
  <div class="mx-4">
    <div class="py-2 font-weight-bold">共有 {{ topics.length }} 个话题</div>
    <div>
      <v-card
        v-for="topic in topics"
        :key="topic.speakingTopicId"
        class="elevation-2 mb-4"
      >
        <v-progress-linear
          bg-color="deep-purple-lighten-3"
          color="deep-purple-lighten-1"
          :model-value="topic.finished"
          :max="topic.questionCount"
        />
        <v-card-text>
          <div class="mb-3 text-h6">
            {{ topic.name }}
            <v-icon
              v-if="topic.newStatus === '1'"
              icon="mdi-new-box"
              color="pink"
            />
          </div>
          <div class="d-flex justify-start align-center">
            <v-chip
              v-if="topic.cateName !== null"
              label
              color="teal"
              class="mr-4"
              size="small"
            >
              {{ topic.cateName }}
            </v-chip>
            <v-chip label color="indigo" class="mr-4" size="small">
              <template v-if="topic.partType === 'PART1'">Part 1</template>
              <template v-else-if="topic.partType === 'PART2&3'">Part 2&3</template>
              <template v-else>串题</template>
            </v-chip>
            <v-chip label color="deep-purple" class="mr-4" size="small">
              <strong class="mr-1">{{ topic.finished }}</strong>
              <small class="mr-1">/ {{ topic.questionCount || 0 }}</small>
              Questions
            </v-chip>
            <v-chip
              v-if="topic.isFree === '1'"
              label
              color="green"
              size="small"
            >
              Free
            </v-chip>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            append-icon="mdi-dots-vertical"
            :disabled="!props.isValid && topic.isFree === '0'"
            color="indigo"
            @click="goTopic(topic.speakingTopicId)"
          >More</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, defineProps } from 'vue'
import { findTopicList } from '@/apis/speaking'
import { useRouter } from 'vue-router'

const props = defineProps({
  testId: {
    type: String,
    default: ''
  },
  cateId: {
    type: String,
    default: ''
  },
  newStatus: {
    type: String,
    default: ''
  },
  partType: {
    type: String,
    default: ''
  },
  isValid: {
    type: Boolean,
    default: false
  }
})

watch(
  () => props.testId,
  () => {
    fetchTopics()
  }
)

watch(
  () => props.cateId,
  () => {
    fetchTopics()
  }
)

watch(
  () => props.newStatus,
  () => {
    fetchTopics()
  }
)

watch(
  () => props.partType,
  () => {
    fetchTopics()
  }
)

// 获取题目
const topics = ref([])
const fetchTopics = async() => {
  var dataForm = {
    testId: props.testId,
    cateId: props.cateId,
    newStatus: props.newStatus,
    partType: props.partType
  }
  var res = await findTopicList(dataForm)
  topics.value = res.data.data
}
fetchTopics()

const router = useRouter()
const goTopic = (topicId) => {
  router.push({ path: '/speaking/topic/' + topicId })
}
</script>
<style lang="scss"></style>
