<template lang="">
  <el-container class="on-test">
    <el-header>
      <top-bar v-if="paper.paperType === '1'" :limit-minutes="60" :start="true" :hide-time="false" :test-type="'Reading'" @handle-finish="handleFinish" />
      <top-bar-practice v-else :start="true" :test-type="'Reading'" @handle-finish="handleFinish" />
    </el-header>
    <el-main class="app-main">
      <div id="highlightable-content">
        <div class="test-container">
          <div class="part-title">
            <h3> Part  {{ currentPartIndex + 1 }}</h3>
            <p v-html="parts[currentPartIndex].partDesc" />
          </div>
          <div v-for="part in parts" :key="'part-' + part.partId">
            <div v-show="currentPartId === part.partId" class="part-container">
              <div class="part-content">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-scrollbar height="calc(100vh - 251px)" style="padding-right:16px;">
                      <div
                        class="part-content-en"
                        :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
                        v-html="part.partContentEn"
                      />
                    </el-scrollbar>
                  </el-col>
                  <el-col :span="12">
                    <el-scrollbar height="calc(100vh - 251px)" style="padding-right:16px;">
                      <section v-for="activity in part.activityList" :key="'section'+activity.activityId" class="activity needs-focus">
                        <question-0301 v-if="activity.activityTypeId === '62'" :activity="activity" />
                        <question-0302 v-if="activity.activityTypeId === '101'" :activity="activity" />
                        <question-0303 v-if="activity.activityTypeId === '63'" :activity="activity" />
                        <question-0304 v-if="activity.activityTypeId === '65'" :activity="activity" />
                        <question-0305 v-if="activity.activityTypeId === '61'" :activity="activity" />
                        <question-0305 v-if="activity.activityTypeId === '68'" :activity="activity" />
                      </section>
                    </el-scrollbar>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <el-row class="question-navigation">
            <el-col :span="2">
              <div class="input-group">
                <button class="btn btn-block review" @click="handleReview"><span :class="'check '+(isReview ? 'checked':'') " />Review</button>
              </div>
            </el-col>
            <el-col :span="19">
              <div class="question-nav">
                <ul class="question-buttons">
                  <template v-for="(part, index) in parts" :key="'question-buttons-' + part.partId">
                    <li class="part-label-holder">
                      <div class="btn disabled part-label">Part {{ index+1 }}:</div>
                    </li>
                    <template v-for="num in part.endQuestionNum ">
                      <li v-if="num >= part.startQuestionNum" :key="num">
                        <answer-button :part-id="part.partId" :question-num="num" :review-list="reviewList" @on-handel="onHandel" />
                      </li>
                    </template>
                  </template>
                </ul>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="arrows">
                <div class="row">
                  <div class="col">
                    <button v-if="currentNum===1" disabled class="btn prev" @click="onPrev"><span class="arrow-left" /></button>
                    <button v-else class="btn prev" @click="onPrev"><span class="arrow-left" /></button>
                    <button v-if="currentNum===40" disabled class="btn next" @click="onNext"><span class="arrow-right" /></button>
                    <button v-else class="btn next" @click="onNext"><span class="arrow-right" /></button>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import TopBar from '@/components/TopBar'
import TopBarPractice from '@/components/TopBarPractice'
import { mapGetters } from 'vuex'

import Question0301 from '../components/questions/Question0301'
import Question0302 from '../components/questions/Question0302'
import Question0303 from '../components/questions/Question0303'
import Question0304 from '../components/questions/Question0304'
import Question0305 from '../components/questions/Question0305'

import AnswerButton from '../components/buttons/ReadingAnswerButton'

import { initContextMenu, destroyContextMenu } from '@/plugins/highlight'

export default {
  name: 'ReadingTestPage',
  components: { TopBar, TopBarPractice, AnswerButton, Question0301, Question0302, Question0303, Question0304, Question0305 },
  emits: ['readingNextStep'],
  data() {
    return {
      queModels: {},
      currentPart: {},
      currentPartIndex: 0,
      currentPartId: undefined,
      reviewList: [],
      isReview: false,
      parts: [],
      size: '0'
    }
  },
  computed: {
    ...mapGetters({ fontSize: 'fontSize',
      paper: 'paperInfo',
      readingParts: 'readingParts',
      currentNum: 'currentQuestionNum',
      startTime: 'testStartTime' }),
    getReview() {
      return this.currentNum
    },
    getFontSize() {
      return this.fontSize
    }
  },
  watch: {
    getReview: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.isReview = this.indexOf(this.reviewList, this.currentNum) >= 0
      }
    },
    getFontSize: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.size = this.fontSize
      }
    }
  },
  created() {
    this.parts = this.readingParts
    this.currentPartId = this.parts[0].partId

    if (this.startTime === undefined || this.startTime === '') {
      this.$store.dispatch('setTestStartTime', new Date())
    }
  },
  mounted() {
    this.size = this.fontSize
    initContextMenu()
  },
  unmounted() {
    destroyContextMenu()
  },
  methods: {
    onHandel(data) {
      this.currentPartId = data.partId
      this.getPartIndexById(data.partId)
    },
    handleFinish(val) {
      this.$emit('readingNextStep', val)
    },
    onPrev() {
      if (this.currentNum > 1) {
        this.getPartIndex(this.currentNum - 1)
        this.$store.dispatch('setCurrentQuestionNum', this.currentNum - 1)
      }
    },
    onNext() {
      if (this.currentNum < 40) {
        this.getPartIndex(this.currentNum + 1)
        this.$store.dispatch('setCurrentQuestionNum', this.currentNum + 1)
      }
    },
    handleReview() {
      var has = this.indexOf(this.reviewList, this.currentNum) >= 0
      if (!has) {
        this.reviewList.push(this.currentNum)
        this.isReview = true
      } else {
        this.remove(this.reviewList, this.currentNum)
        this.isReview = false
      }
    },
    indexOf(list, val) {
      for (var i = 0; i < list.length; i++) {
        if (list[i] === val) return i
      }
      return -1
    },
    remove(list, val) {
      var _index = this.indexOf(list, val)
      if (_index > -1) {
        list.splice(_index, 1)
      }
      return list
    },
    getPartIndex(num) {
      var _this = this
      var i = 0
      this.parts.forEach(e => {
        if (e.startQuestionNum <= num && e.endQuestionNum >= num) {
          _this.currentPartId = e.partId
          _this.currentPartIndex = i
          return
        }
        i++
      })
    },
    getPartIndexById(partId) {
      var _this = this
      var i = 0
      this.parts.forEach(e => {
        if (e.partId === partId) {
          _this.currentPartIndex = i
          return
        }
        i++
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.activity{
  margin-bottom: 20px;
}
.part-content-en{
  text-align: justify;
}

.part-content-en.small{
  font-size: 14px;
}
.part-content-en.standard{
  font-size: 16px;
}
.part-content-en.large{
  font-size: 18px;
}
.part-content-en.extra_large{
  font-size: 20px;
}
</style>
