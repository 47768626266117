<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="24">
          <div class="text-h4 my-4">
            雅思真词汇
          </div>
          <el-form ref="wordFormRef" :model="state.form" inline class="mt-4" :rules="rules">
            <el-form-item label="单词" prop="word">
              <el-input v-model="state.form.word" placeholder="请输入单词" clearable />
            </el-form-item>
            <el-form-item>
              <el-button v-loading.fullscreen.lock="fullscreenLoading" type="primary" class="text-white" :icon="Search" @click="doAnalyze">查询</el-button>
              <el-button @click="resetAnalyze">重置</el-button>
            </el-form-item>
          </el-form>
          <div class="text-h6 my-4">
            单词
            <span class="font-weight-black text-pink-darken-1 px-2"> {{ state.form.word ?? '--' }} </span>，
            累计出现
            <span class="font-weight-bold text-pink-darken-1 px-2">{{ totalCount }}</span>
            次
          </div>
          <el-tabs v-if="analyzeDatas.length > 0" v-model="activeName" class="demo-tabs" type="border-card">
            <el-tab-pane v-for="item in analyzeDatas" :key="item.file" :label="item.file +'【 '+ item.count+' 】'" :name="item.file">
              <div class="pa-4">
                <template v-if="item.sentences.length > 0">
                  <ol>
                    <li v-for="(sent, index) in item.sentences" :key="index">
                      <div class="d-flex justify-space-between align-start">
                        <div>
                          <p v-html="sent.sentence" />
                          <p>Page {{ sent.page ?? '-' }} </p>
                        </div>
                        <div class="ml-4">
                          <el-button :icon="Delete" circle @click="deleteAnalyze(sent.sentence)" />
                        </div>
                      </div>
                      <el-divider border-style="dashed" />
                    </li>
                  </ol>
                </template>
                <template v-else>
                  <div>暂无数据</div>
                </template>
              </div>
            </el-tab-pane>
          </el-tabs>
          <el-card v-else class="no-data">
            没有数据
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { Delete, Search } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

const state = reactive({
  form: {
    word: undefined
  }
})

const wordFormRef = ref()
const rules = reactive({
  word: [
    { required: true, message: '请输入单词', trigger: 'blur' }
  ]
})

const fullscreenLoading = ref(false)
const totalCount = ref(0)
const activeName = ref('剑雅4')
const analyzeDatas = ref([])

const instance = axios.create({
  baseURL: 'https://ielts.hibookapp.com'
})

const doAnalyze = async() => {
  if (!wordFormRef) return
  console.log(state.form.word)
  await wordFormRef.value.validate((valid, fields) => {
    if (valid) {
      if (state.form.word === undefined || state.form.word.replace(/\s/g, '').length === 0) {
        ElMessage.error('请输入要分析的词！')
        state.form.word = undefined
        return
      }
      const form = new FormData()
      form.append('word', state.form.word)
      fullscreenLoading.value = true
      instance.post('/analyze_file/', form)
        .then((res) => {
          analyzeDatas.value = res.data.results
          totalCount.value = res.data.total_count
          fullscreenLoading.value = false
        })
        .catch((error) => {
          console.log(error)
          fullscreenLoading.value = false
          ElMessage.error('解析错误！')
        })
    } else {
      console.log('Error submit!', fields)
    }
  })
}

const resetAnalyze = () => {
  state.form.word = undefined
  analyzeDatas.value = []
  totalCount.value = 0
}
</script>
<style lang="scss">
.demo-tabs > .el-tabs__content {
  padding: 16px;
  color: #6b778c;
  height: 700px;
  overflow:scroll;
}
.no-data{
  padding: 16px;
  color: #6b778c;
  height: 700px;
}
mark{
  background-color: #C2DEB9;
  color: #222222;
  padding: 0 6px;
}
</style>
