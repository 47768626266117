<template lang="">
  <p
    v-if="activity.startQuestionNum === activity.endQuestionNum"
    class="activity-questions bold"
  >
    Question {{ activity.startQuestionNum }}
  </p>
  <p v-else class="activity-questions bold">
    Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}
  </p>
  <p class="activity-title" v-html="activity.activityTitle" />
  <p class="activity-note" v-html="activity.activitySubTitle" />
  <ul class="questions">
    <li
      v-for="question in activity.questionList"
      :id="'question_' + question.questionNum"
      :key="question.questionNum"
      style="margin-bottom: 20px"
    >
      <div
        :class="
          'question-title font-weight-bold ' +
            (getResult(question.questionNum).isRight ||
              getResult(question.questionNum).right
              ? 'right'
              : 'wrong') +
            (currentNum >= activity.startQuestionNum &&
              currentNum <= activity.endQuestionNum
              ? ' highlight'
              : '')
        "
        @click="changeNum(question.questionNum)"
      >
        <span class="q-number">{{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</span>
        {{ question.questionTitle }}
      </div>
      <ul class="answers__">
        <li
          v-for="answer in JSON.parse(question.questionContent)"
          :key="answer.index"
        >
          <template
            v-if="
              getResult(question.questionNum).isRight ||
                getResult(question.questionNum).right
            "
          >
            <template v-if="answer.isRight">
              <Icon name="checkbox-checked" class="checkIcon right" />
              <span class="right">
                <strong>{{ answer.index }}</strong> {{ answer.content }}
              </span>
            </template>
            <template v-else>
              <Icon name="checkbox" class="checkIcon" />
              <span>
                <strong>{{ answer.index }}</strong> {{ answer.content }}
              </span>
            </template>
          </template>
          <template v-else>
            <template
              v-if="
                getAnswer(
                  question.questionNum,
                  answer.index,
                  answer.isRight
                ) === '1'
              "
            >
              <Icon name="checkbox-checked" class="checkIcon right" />
              <span class="right">
                <strong>{{ answer.index }}</strong> {{ answer.content }}
              </span>
            </template>
            <template
              v-else-if="
                getAnswer(
                  question.questionNum,
                  answer.index,
                  answer.isRight
                ) === '2'
              "
            >
              <Icon name="checkbox-checked" class="checkIcon right" />
              <span class="right">
                <strong>{{ answer.index }}</strong> {{ answer.content }}
              </span>
            </template>
            <template
              v-else-if="
                getAnswer(
                  question.questionNum,
                  answer.index,
                  answer.isRight
                ) === '3'
              "
            >
              <Icon name="checkbox-checked" class="checkIcon wrong" />
              <span class="wrong">
                <strong>{{ answer.index }}</strong> {{ answer.content }}
              </span>
            </template>
            <template v-else>
              <Icon name="checkbox" class="checkIcon" />
              <span>
                <strong>{{ answer.index }}</strong> {{ answer.content }}
              </span>
            </template>
          </template>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'Answer0203Component',
  inject: ['listeningAnswers', 'currentNum', 'changeNum'],
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: []
    }
  },
  computed: {
    getCurrentNum() {
      return this.currentNum
    }
  },
  watch: {
    getCurrentNum: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.handleFocus()
      }
    }
  },
  methods: {
    getResult(num) {
      var r
      this.listeningAnswers.value.forEach((e) => {
        if (e.questionNum === num) {
          r = e
          return
        }
      })
      return r
    },
    getAnswer(num, index, rightAnswer) {
      var r
      this.listeningAnswers.value.forEach((e) => {
        if (e.questionNum === num) {
          r = e
          return
        }
      })
      var answer = JSON.parse(r.yourAnswer).toString()
      if (rightAnswer && answer.includes(index)) {
        return '1'
      }
      if (rightAnswer && !answer.includes(index)) {
        return '2'
      }
      if (!rightAnswer && answer.includes(index)) {
        return '3'
      }
      return '4'
    },
    handleFocus() {
      var node = document.getElementById('question_' + this.currentNum)
      if (node) {
        // node.scrollIntoView({
        //   behavior: 'smooth'
        // })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.activity-questions {
  margin-bottom: 15px;
}

.activity-title {
  margin-bottom: 5px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.questions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

ul.questions li {
  flex: 0 0 100%;
  margin: 0 0 20px 0;
  font-size: 14px;
}

ul.questions li > .question-title {
  font-size: 16px;
  margin-bottom: 5px;
  cursor: pointer;
}

ul.questions li > .question-title.right.highlight {
  background-color: #f0f9eb;
  border: 1px solid #40d039;
  color: #40d039;
  border-radius: 5px;
  padding: 5px 10px;
}

ul.questions li > .question-title.wrong.highlight {
  background-color: #fef0f0;
  border: 1px solid #e31837;
  color: #e31837;
  border-radius: 5px;
  padding: 5px 10px;
}

ul.answers__ li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.answers__ li span.right {
  color: #40d039;
}

.answers__ li span.wrong {
  color: #e31837;
}

.answers__ li strong {
  margin-right: 10px;
}

ul.answers__ li .checkIcon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  display: block;
  flex-basis: auto;
  white-space: nowrap;
}

ul.answers__ li .checkIcon.right {
  color: #40d039;
}

ul.answers__ li .checkIcon.wrong {
  color: #e31837;
}
</style>
