<template>
  <div class="one-radio-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HiRadioGroup',
  provide() {
    return {
      RadioGroup: this
    }
  },
  props: ['modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.one-radio-group{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-left: 10px;
}
.one-radio-group.small:deep(.one-radio){
  font-size: 14px;
}
.one-radio-group.standard:deep(.one-radio){
  font-size: 16px;
}
.one-radio-group.large:deep(.one-radio){
  font-size: 18px;
}
.one-radio-group.extra_large:deep(.one-radio){
  font-size: 20px;
}
</style>
