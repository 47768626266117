<template lang="">
  <div class="common-layout">
    <el-card :body-style="{ padding: '16px'}" style="margin-bottom:10px;">
      <el-page-header :icon="ArrowLeft" style="display:flex;" @back="onBack">
        <template #content>
          <span class="text-large font-600 mr-3"> 雅思机考记录 </span>
        </template>
      </el-page-header>
    </el-card>
    <el-card :body-style="{ padding: '0'}" height="calc(100vh - 88px)">
      <template #header>
        <div class="card-header">
          <span><font-awesome-icon icon="fa-solid fa-calendar-days" /> 机考记录</span>
          <div class="search-container">
            <el-form :model="queryForm">
              <el-form-item>
                <span style="margin:0 15px;font-weight:700;">类型:</span>
                <el-checkbox-group v-model="filterTypes" size="large" @change="handleFilter">
                  <el-checkbox key="0" label="0">
                    练习
                  </el-checkbox>
                  <el-checkbox key="1" label="1">
                    模考
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </template>
      <div style="background-color:#ffffff;display:flex;flex-direction:column; padding-bottom:15px;">
        <el-table :data="dataList" stripe size="large" style="width: 100%" height="calc(100vh - 220px)">
          <el-table-column
            label="时间"
            header-align="left"
            align="left"
            width="180px"
          >
            <template #default="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column
            label="题库"
            header-align="left"
            align="left"
          >
            <template #default="scope">
              {{ JSON.parse(scope.row.resultInfo).objectTitle }}
              <el-tag v-if="scope.row.isFinished !== '1'" size="small" type="warning" effect="dark">In progress</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="类型"
            header-align="center"
            align="center"
            width="100px"
          >
            <template #default="scope">
              {{ scope.row.testType === '0'?'练习':'模考' }}
            </template>
          </el-table-column>
          <el-table-column
            label="成绩"
            header-align="center"
          >
            <el-table-column
              label="阅读"
              header-align="center"
              align="center"
              width="100px"
            >
              <template #default="scope">
                <div v-if="scope.row.isFinished === '1'">
                  <span v-if="scope.row.testType === '1' || JSON.parse(scope.row.resultInfo).reading !== undefined">
                    {{ getScore(JSON.parse(scope.row.resultScore).readingScore) }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              label="听力"
              header-align="center"
              align="center"
              width="100px"
            >
              <template #default="scope">
                <div v-if="scope.row.isFinished === '1'">
                  <span v-if="scope.row.testType === '1' || JSON.parse(scope.row.resultInfo).listening !== undefined">
                    {{ getScore(JSON.parse(scope.row.resultScore).listeningScore) }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="240px"
          >
            <template #default="scope">
              <el-button v-if="scope.row.isFinished === '1'" size="small" type="success" plain @click="retry(scope.row.objectId)">
                重做
              </el-button>
              <el-button v-else size="small" color="#9C27B0" plain @click="retry(scope.row.objectId)">
                继续
              </el-button>
              <el-button :disabled="scope.row.isFinished !== '1'" size="small" color="#2196F3" plain @click="review(scope.row.testLogId)">
                解析
              </el-button>
              <el-button size="small" type="danger" plain @click="handleDelete(scope.row.testLogId)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            :current-page="queryForm.current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryForm.count"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'

import { ElMessageBox, ElMessage } from 'element-plus'
import { ArrowLeft } from '@element-plus/icons-vue'

import { fetchTestLogList, fetchTestLogCount, deleteTestLog } from '@/apis/test'
import { handleScore } from '@/utils/test'

const dataList = ref([])
const filterTypes = ref([])
const total = ref(0)
const queryForm = reactive({
  first: 0,
  count: 20,
  current: 1,
  types: []
})

const router = useRouter()
const retry = (id) => {
  router.push('/test/' + id + '/view')
}
const onBack = () => {
  router.push('/paper')
}

const review = (id) => {
  router.push({ name: 'TestResultView', params: { 'logId': id }, query: { 'direct': '/paper/history' }})
}

const getScore = (c) => {
  return handleScore(c)
}

const findDataList = () => {
  queryForm.first = queryForm.count * (queryForm.current - 1)
  fetchTestLogList(queryForm).then(res => {
    dataList.value = res.data.data
    findTotal()
  })
}
findDataList()

const handleDelete = (id) => {
  ElMessageBox.confirm(
    '是否确认删除考试记录？删除后不可恢复！',
    'Warning',
    {
      confirmButtonText: '确认删除',
      cancelButtonText: '我再想想',
      type: 'warning'
    }
  )
    .then(() => {
      deleteTestLog(id).then(res => {
        ElMessage.success('删除成功')
        findDataList()
      })
      return
    })
    .catch(() => {
      return
    })
}

const findTotal = () => {
  fetchTestLogCount(queryForm.types).then(res => {
    total.value = res.data.data
  })
}
findTotal()

const handleFilter = (e) => {
  queryForm.types = []
  queryForm.types.push(e)
  findDataList()
}

// 每页数
const sizeChangeHandle = (val) => {
  queryForm.count = val
  queryForm.current = 1
  findDataList()
}

// 当前页
const currentChangeHandle = (val) => {
  queryForm.current = val
  findDataList()
}

</script>
<style lang="scss" scoped>
.common-layout{
  background-color: #FFFFFF;
  min-height: 100vh;
  padding: 10px;
}

.page-head{
  background-color: #FFFFFF;
}
  .el-card::v-deep(.el-card__header){
  padding: 8px 16px;
  }

  .card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  }

  .search-container .el-form-item {
  margin-bottom: 0;
  }

  .pagination-container {
  background-color: white;
  padding: 10px;
  margin-top: 0;
  float: right;
  }
</style>
