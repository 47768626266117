<template lang="">
  <p class="activity-questions bold">{{ activity.activityType }}</p>
  <p
    v-if="activity.startQuestionNum === activity.endQuestionNum"
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Question {{ activity.startQuestionNum }}</p>
  <p
    v-else
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</p>
  <p
    class="activity-title"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activityTitle"
  />
  <p
    class="activity-note"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activitySubTitle"
  />
  <el-row :gutter="20">
    <el-col :span="12">
      <div
        class="activity-content"
        :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
        v-html="activity.activityContent"
      />
    </el-col>
    <el-col :span="12">
      <div
        class="drag-question"
        :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
      >
        <div v-for="question in questionList" :id="'question_'+question.questionNum" :key="question.questionNum" style="display:flex; flex-direction:row; justify-content:flex-start; align-items:center;">
          <span style="margin-bottom: 0rem; display:inline-block;">
            <strong>{{ question.questionNum }}</strong> {{ question.questionTitle }}
          </span>
          <div class="droparea droptarget">
            <el-select v-model="queModels[question.questionNum]" placeholder="Select the correct letter" @change="onChange($event, question.questionNum)">
              <el-option
                v-for="(item, index) in answerList"
                :key="item.index + '' + index + question.questionNum"
                :label="item.index"
                :value="item.index"
              />
            </el-select>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Question0204Component',
  components: { },
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: [],
      questionList: [],
      answerList: [],
      size: '0'
    }
  },
  computed: {
    ...mapGetters({ fontSize: 'fontSize', listeningAnswer: 'listeningAnswer', currentNum: 'currentQuestionNum' }),
    getFontSize() {
      return this.fontSize
    },
    getCurrentNum() {
      return this.currentNum
    }
  },
  watch: {
    getFontSize: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.size = this.fontSize
      }
    },
    getCurrentNum: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.handleFocus()
      }
    }
  },
  created() {
    this.questionList = this.activity.questionList
    this.answerList = JSON.parse(this.activity.activityFormwork)

    var _this = this
    this.listeningAnswer.forEach(element => {
      _this.queModels[element.questionNumber] = element.yourAnswer
    })
  },
  mounted() {
    this.size = this.fontSize
  },
  methods: {
    onChange(e, questionNum) {
      this.$store.dispatch('setCurrentQuestionNum', questionNum)
      this.$store.dispatch('saveListeningAnswer', {
        'questionNumber': parseInt(questionNum),
        'yourAnswer': e
      })
    },
    handleFocus() {
      var node = document.getElementById('question_' + this.currentNum)
      if (node) {
        // node.scrollIntoView({
        //   behavior: 'smooth'
        // })
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.activity-content:deep(img){
display: block;
width: 100%;
height: auto;
}
.option-item {
  cursor: pointer;
  font-size: 18px;
  color: #333;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  float: left;
  clear: both;
  min-height: 32px;
  margin-top: 15px;
  line-height: 32px;
  background: #dfe9fc;
  border-radius: 2px;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 20px;
  line-height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #dee5f5;
  -webkit-box-shadow: 0 1px 3px 0 #b9bdc5;
  box-shadow: 0 1px 3px 0 #b9bdc5;
  border-radius: 2px;
  list-style: none;
  min-height: 20px;
}

.droparea {
    margin: 5px 8px;
}
.droparea .q-index {
  display: none;
  font-size: 14px;
  padding: 0 10px;
  line-height: 32px;
}
.droparea .show {
  display: inline-block;
  width: 160px;
  height: 32px;
  background: #fff;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  border: 1px solid #407dab;
  border-radius: 2px;
  color: #000;
  font-weight: 700;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li{
  margin: 5px auto;
}
.drag-question.small{
  font-size: 14px;
}
.drag-question.standard{
  font-size: 16px;
}
.drag-question.large{
  font-size: 18px;
}
.drag-question.extra_large{
  font-size: 20px;
}
</style>

