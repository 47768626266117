<template lang="">
  <el-container class="on-test">
    <el-header>
      <top-bar v-if="paper.paperType === '1'" :limit-minutes="35" :hide-time="false" :audio-model="audio" :test-type="'Listening'" @handle-finish="handleFinish" />
      <top-bar-practice v-else :test-type="'Listening'" @handle-finish="handleFinish" />
    </el-header>
    <el-main>
      <section class="app-main">
        <div v-show="stepIndex === 0" class="step-one">
          <div class="test-sound">
            <audio ref="audioTest" :src="require('@/assets/audio/soundcheck.mp3')" />
            <img src="@/assets/images/sound.png" alt="">
            <p class="bc-box-title">Test sound</p>
            <div class="bc-box">
              <p>Put on your headphones and click the <strong>Play sound</strong> button to play a sample sound.</p>
              <el-button v-if="!testAudioPlaying" class="btn bc-button" @click="testAudio">Play sound</el-button>
              <el-button v-else class="btn bc-button" @click="pauseAudio">Pause</el-button>
              <p class="bc-box-attention">if you can not hear the sound clearly, please tell the invigilator</p>
              <div style="text-align:center;">
                <el-button class="btn bc-button" @click="toNextStep">Continue</el-button>
              </div>
            </div>
          </div>
        </div>
        <el-row v-show="stepIndex === 1" style="margin-top:20px; padding-left:20px;">
          <el-col :span="24">
            <div class="text-left">
              <h5>IELTS Listening</h5>
              <p>Time: Approximately 30 minutes</p>
              <h5>INSTRUCTIONS TO CANDIDATES</h5>
              <ul>
                <li>Answer <strong>all</strong> the questions.</li>
                <li>You can change your answers at any time during the test.</li>
              </ul>
              <h5>INFORMATION FOR CANDIDATES</h5>
              <ul>
                <li>There are 40 questions in this test.</li>
                <li>Each question carries one mark.</li>
                <li>There are four parts to the test</li>
                <li>You will hear each part once.</li>
                <li>For each part of the test there will be time for you to look through the questions and time for you to check your answers.</li>
              </ul>
            </div>
            <div class="text-center">
              <p>Do not click 'Start test' until you are told to do so.</p>
              <el-button class="btn bc-button" style="width:200px;" @click="$emit('listeningNextStep','ListeningTestView')">Start test</el-button>
            </div>
          </el-col>
        </el-row>
      </section>
    </el-main>
    <Note :text="texts[stepIndex]" />
  </el-container>
</template>
<script>

import { mapGetters } from 'vuex'
import TopBar from '@/components/TopBar'
import TopBarPractice from '@/components/TopBarPractice'
import Note from '@/components/Note'
const _texts = [
  'Please click the “Continue” button to progress into the familiarisation test, in your real IELTS test there will be an invigilator in the room to advise when to do this.',
  'Please click the “Start test” button to start the familiarisation test, in your real IELTS test there will be an invigilator in the room to advise when to do this.'
]

export default {
  name: 'ListeningStartPage',
  components: { TopBar, TopBarPractice, Note },
  emits: ['listeningNextStep'],
  data() {
    return {
      testAudioPlaying: false,
      stepIndex: 0,
      audio: undefined,
      texts: _texts
    }
  },
  computed: {
    ...mapGetters({ paper: 'paperInfo' })
  },
  mounted() {
    this.audio = this.$refs.audioTest
  },
  methods: {
    toNextStep() {
      this.stepIndex += 1
    },
    testAudio() {
      const audio = this.$refs.audioTest

      if (this.testAudioPlaying) {
        return
      }

      if (audio === null || audio.length === 0) {
        return
      }

      audio.volume = 0.8
      audio.currentTime = 0
      audio.loop = true
      audio.play()

      this.testAudioPlaying = true
    },
    pauseAudio() {
      const audio = this.$refs.audioTest
      if (!this.testAudioPlaying) {
        return
      }
      audio.pause()
      this.testAudioPlaying = false
    },
    handleFinish(val) {
      this.$emit('listeningNextStep', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.student-details, .test-sound {
    width: 100%;
    max-width: 1120px;
    margin: 80px auto 0 auto;
    text-align: left;
}
.bc-box-title {
    display: inline-block;
    font-weight: bold;
}
.bc-box {
    display: block;
    width: 100%;
    max-width: 1120px;
    border: solid 1px white;
    border-radius: 10px;
    padding: 15px;
    margin: 0 auto;
}
.btn.bc-button {
    margin: 0 auto;
    display: block;
    background-color: transparent;
    color: #283e53;
    font-weight: bold;
    border: none;
    box-shadow: 0 2px 2px grey;
}
p.bx-box-info, p.bc-box-attention {
    padding-left: 25px;
    background-position: left 0.15em;
    background-repeat: no-repeat;
}
p.bc-box-attention {
    background-image: url('@/assets/images/iconAttention16.png');
}
</style>
