<template>
  <Transition name="bounce">
    <div v-show="backTopShow" class="goTop">
      <div class="pa-4">
        <v-btn icon="mdi-chevron-up" color="deep-purple" dark @click="goTop" />
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, onMounted } from 'vue'
const backTopShow = ref(false)
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

const handleScroll = () => {
  if (document.documentElement.scrollTop + document.body.scrollTop > 100) {
    backTopShow.value = true
  } else {
    backTopShow.value = false
  }
}

const goTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}
</script>

<style lang="scss" scoped>
.goTop {
  bottom: 0px;
  z-index: 1004;
  position: fixed;
  right: 0px;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
