/**
 * 全站权限配置
 *
 */
import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/get-page-title'

import { validatenull } from '@/utils/validate'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/post', '/nine/*', '/analyze'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // 缓冲设置
  if (to.meta.keepAlive === true) {
    to.meta.$keepAlive = true
  } else {
    NProgress.start()
    if (to.meta.keepAlive === true && validatenull(to.meta.$keepAlive)) {
      to.meta.$keepAlive = true
    } else {
      to.meta.$keepAlive = false
    }
  }
  // set page title
  document.title = getPageTitle(to.meta.title)

  const meta = to.meta || {}
  if (store.getters.access_token) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      // NOTE: 当用户角色不存在时，会存在无限请求用户信息接口的问题
      const hasUserInfo =
        store.getters.userInfo && store.getters.userInfo !== undefined
      if (hasUserInfo) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          await store.dispatch('GetUserInfo')
          next({ ...to, replace: true })
        } catch (error) {
          store.dispatch('FedLogOut').then(() => {
            next({ path: '/login' })
          })
          NProgress.done()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1 || meta.isAuth === false) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
