<template lang="">
  <div class="answers">
    <div v-html="text" />
  </div>
</template>
<script>
import { compile } from 'vue/dist/vue.esm-bundler.js'
import question from './ReadingQuestionInput'

export default {
  name: 'QuestionComponent',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    question
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    activityId: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    return compile(props.text)
  },
  data() {
    return {
      qNum: ''
    }
  },
  methods: {
  }

}
</script>
<style lang="scss" scoped>
  .answers:deep(div), .answers:deep(p){
    line-height: 2rem;
    margin-bottom: 5px;
  }

  .answers:deep(strong){
    font-size: 18px;
  }
 .answers:deep(table div){
    margin-bottom: 5px;
  }
</style>
