<template>
  <div class="sign_container">
    <el-alert class="alert" title="建议使用Chrome、Safari、火狐等浏览器访问本网站" type="warning" show-icon center :closable="false" effect="dark" />
    <el-row>
      <el-col :md="12" class="hidden-sm-and-down" style="background-color: #37474F; height:100vh;">
        <el-carousel height="calc(100vh - 120px)" class="carousel" indicator-position="outside">
          <el-carousel-item v-for="item in 3" :key="item">
            <img style="width:100%;height:auto;display:block;" :src="require('@/assets/svgs/slide0'+item+'.svg')">
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :md="12" style="background-color: #ffffff; padding: 0 60px; height:100vh; display: flex; flex-direction: column; justify-content: center; align-items: stretch;">
        <div class="logo">
          <img :src="require('@/assets/images/logo.png')">
        </div>
        <span class="title">Take the IELTS on computer familiarisation test</span>
        <el-divider style="margin:8px auto;" />
        <span class="sub-title">Please enter your phone number to access the familiarisation test.</span>
        <div>
          <component :is="activeName" />
          <el-divider style="margin:8px auto;" border-style="dashed" />
          <div class="login-menu">
            <a :class="{'active':activeName==='CodeLogin'}" href="javascript:void(0);" @click.stop="activeName='CodeLogin'">短信登录</a>
            <a :class="{'active':activeName==='UserLogin'}" href="javascript:void(0);" @click.stop="activeName='UserLogin'">密码登录</a>
            <!-- <a href="#" @click.stop="activeName='UserRegister'">用户注册</a> -->
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import CodeLogin from './components/CodeLogin'
import UserLogin from './components/UserLogin'
import UserRegister from './components/UserRegister'

export default {
  name: 'SignInPage',
  components: { CodeLogin, UserLogin, UserRegister },
  data() {
    return {
      activeName: 'CodeLogin'
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.sign_container{
 height: 100vh;
}
.alert{
  position: absolute;
  top: 0;
  z-index: 1000;
}
.carousel{
  padding: 40px;

}
.carousel img{
  display: block;
  width: 100%;
  height: auto;
}
.logo{
  margin-top: 80px;
  text-align: center;
}
.logo img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.title{
  display: block;
  font-size: 26px;
  line-height: 1.5em;
  margin-top: 32px;
}
.sub-title{
  display: block;
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 20px;
}
.login-menu {
  margin-top: 24px;
  width: 100%;
  text-align: center;
  a {
    color: #999;
    font-size: 16px;
    margin: 0px 8px;
  }
  a:hover {
    text-decoration: underline;
  }
  a.active{
    color: #222;
    font-weight: bolder;
  }
}
</style>
