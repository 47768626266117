<template lang="">
  <div>
    <v-card
      class="elevation-4"
      style="height: calc(100vh - 86px)"
      :loading="loadingQuestion"
    >
      <template #loader="{ isActive }">
        <v-progress-linear
          :active="isActive"
          color="deep-purple"
          height="4"
          indeterminate
        />
      </template>
      <v-card-title>ANSWERS</v-card-title>
      <v-card-text
        class="overflow-y-auto py-4"
        style="height: calc(100vh - 133px)"
      >
        <ChatComponent
          v-for="(chat, index) in chatList"
          :key="index"
          :item="chat"
          :question="question"
          @record="handleRecord"
        />
        <v-spacer />
        <v-dialog v-model="recordDialog" width="50%">
          <div class="d-flex px-4 flex-column justify-center align-stretch">
            <v-sheet
              border
              class="d-flex px-4 flex-column justify-center align-center rounded py-4"
            >
              <v-sheet
                v-if="
                  recordText !== undefined &&
                    recordText !== '' &&
                    recordText !== null
                "
                width="100%"
                class="pa-4 bg-deep-purple-lighten-5 rounded"
              >
                <template v-if="testType === 0">
                  <div v-html="recordText" />
                </template>
                <template v-else>
                  <v-textarea
                    v-model="recordText"
                    variant="outlined"
                    auto-grow
                    rows="10"
                    hide-details
                  />
                  <div class="mt-4">
                    <v-btn
                      prepend-icon="mdi-content-save-check"
                      color="deep-purple"
                      variant="tonal"
                      size="small"
                      @click="saveTextOnly"
                    >仅保存文本</v-btn>
                  </div>
                  <div class="mt-1">
                    <small>PS:临时保存在本地，如需永久保存，请保存后提交到云端保存!</small>
                  </div>
                </template>
              </v-sheet>
              <div style="height: 80px; width: 300px" class="ctrlProcessWave" />
              <template v-if="!recording">
                <v-btn
                  icon="mdi-microphone"
                  variant="tonal"
                  size="x-large"
                  color="deep-purple"
                  @click="recStart"
                />
              </template>
              <template v-else>
                <v-btn
                  icon="mdi-stop-circle"
                  variant="tonal"
                  size="x-large"
                  color="deep-purple"
                  @click="recStop"
                />
              </template>
              <span class="mt-2 text-deep-purple text-h6">
                {{ durationString }}
              </span>
            </v-sheet>
            <div
              v-if="recOpenDialogShow"
              style="
                z-index: 99999;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: fixed;
                background: rgba(0, 0, 0, 0.3);
              "
            >
              <div style="display: flex; height: 100%; align-items: center">
                <div style="flex: 1" />
                <div
                  style="
                    width: 240px;
                    background: #fff;
                    padding: 15px 20px;
                    border-radius: 10px;
                  "
                >
                  <div style="padding-bottom: 10px">
                    录音功能需要麦克风权限，请允许；如果未看到任何请求，请点击忽略~
                  </div>
                  <div style="text-align: center">
                    <a style="color: #0b1" @click="waitDialogClick">忽略</a>
                  </div>
                </div>
                <div style="flex: 1" />
              </div>
            </div>
          </div>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>
<script setup>
import { ref, defineProps, watch, onMounted } from 'vue'
import { findQuestion, findSpeakingLogs } from '@/apis/speaking'

import ChatComponent from './ChatComponent'

/** ******加载RecordApp需要用到的支持文件*********/
// 必须引入的app核心文件，换成require也是一样的。注意：app.js会自动往window下挂载名称为RecordApp对象，全局可调用window.RecordApp，也许可自行调整相关源码清除全局污染
import RecordApp from 'recorder-core/src/app-support/app'

// 可选开启Native支持，需要引入此文件
import 'recorder-core/src/app-support/app-native-support'
// 可选开启IOS上微信录音支持，需要引入此文件
import 'recorder-core/src/app-support/app-ios-weixin-support'

/** *******加载Recorder需要的文件***********/
// 必须引入的核心，所有需要的文件都应当引入，引入后会检测到组件已自动加载
// 不引入也可以，app.js会去用script动态加载，应确保app.js内BaseFolder目录的正确性(参阅RecordAppBaseFolder)，否则会导致404 js加载失败
import Recorder from 'recorder-core'

// 【要打包到一个js就解开注释】本demo是通过script动态引入这些引擎js文件
// 需要使用到的音频格式编码引擎的js文件统统加载进来，这些引擎文件会比较大
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'

// 由于大部分情况下ios-weixin的支持需要用到amr解码器，应当把amr引擎也加载进来，这些引擎文件会比较大
import 'recorder-core/src/engine/beta-amr'
import 'recorder-core/src/engine/beta-amr-engine'
import 'recorder-core/src/engine/wav' // amr依赖了wav引擎

// 可选的扩展支持项
import 'recorder-core/src/extensions/waveview'

RecordApp.AlwaysUseWeixinJS = !!(
  +localStorage['RecordApp_AlwaysUseWeixinJS'] || 0
)
RecordApp.AlwaysAppUseJS = !!(+localStorage['RecordApp_AlwaysAppUseJS'] || 0)

// 立即加载环境，自动把Recorder加载进来
RecordApp.Install(
  function() {
    console.log('RecordApp.Install成功')
  },
  function(err) {
    var msg = 'RecordApp.Install出错：' + err
    console.log(msg)
    alert(msg)
  }
)

const rec = ref(false)
const recOpenDialogShow = ref(0)
const dialogInt = ref('')
const recReq = () => {
  rec.value = false
  dialogInt.value = setTimeout(function() {
    // 定时8秒后打开弹窗，用于监测浏览器没有发起权限请求的情况
    showDialog()
  }, 8000)
  RecordApp.RequestPermission(
    () => {
      rec.value = true
      dialogCancel()
    },
    () => {
      dialogCancel()
    }
  )
  waitDialogClickFn()
}

const props = defineProps({
  questionId: {
    type: String,
    default: undefined
  },
  questionIndex: {
    type: Number,
    default: 0
  }
})

const currId = ref(undefined)
currId.value = props.questionId
const currIndex = ref(0)
currIndex.value = props.questionIndex
watch(
  () => props.questionId,
  async() => {
    currId.value = props.questionId
    currIndex.value = props.questionIndex
    fetchQuestion(props.questionId)
  }
)

const loadingQuestion = ref(false)
const question = ref()
const fetchQuestion = async(questionId) => {
  if (questionId === undefined || questionId === '') {
    return
  }
  loadingQuestion.value = true
  const res = await findQuestion(questionId)
  var _data = res.data.data
  question.value = _data
  await fetchAnswers(questionId)
  handleChatList()
  loadingQuestion.value = false
}

const answers = ref([])
const fetchAnswers = async(questionId) => {
  if (questionId === undefined || questionId === '') {
    return
  }
  const res = await findSpeakingLogs(questionId)
  var _data = res.data.data
  answers.value = _data
}

onMounted(() => {
  fetchQuestion(props.questionId)
})

const recordDialog = ref(false)

const chatList = ref([])
const handleChatList = () => {
  chatList.value = []
  var _question = question.value
  chatList.value.push({
    type: 'question',
    content: _question.title,
    audio: _question.titleAudio
  })
  if (_question.answer1 !== null) {
    chatList.value.push({
      type: 'answer1',
      content: _question.answer1,
      audio: _question.answerAudio1
    })
  }
  if (_question.answer2 !== null) {
    chatList.value.push({
      type: 'answer2',
      content: _question.answer2,
      audio: _question.answerAudio2
    })
  }
  var _answers = answers.value
  _answers.forEach((e) => {
    chatList.value.push({
      type: 'custom',
      isCloud: true,
      text: e.answerText,
      correctAiResult: e.correctAiResult,
      correctAiStatus: e.correctAiStatus,
      correctResult: e.correctResult,
      correctStatus: e.correctStatus,
      audio: e.answer,
      testType: e.testType
    })
  })
}

const recordText = ref()
const testType = ref(0)
const handleRecord = (v, t) => {
  recordDialog.value = true
  recordText.value = v
  testType.value = t
}

const type = ref('wav')
const bitRate = ref(16)
const sampleRate = ref(16000)
const duration = ref(0)
const powerLevel = ref(0)
const durationString = ref('00:00')
const wave = ref()
const recording = ref(false)

const recStart = () => {
  if (!rec.value || !RecordApp.Current) {
    recReq()
  }

  var set = {
    type: type.value,
    bitRate: bitRate.value,
    sampleRate: sampleRate.value,
    onProcess: function(buffers, pl, dur, sampleRate) {
      duration.value = dur
      durationString.value = formartTime(dur)
      powerLevel.value = pl
      wave.value.input(buffers[buffers.length - 1], pl, sampleRate)
    }
  }

  RecordApp.Start(
    set,
    () => {
      recording.value = true
      // 此处创建这些音频可视化图形绘制浏览器支持妥妥的
      wave.value = Recorder.WaveView({ elem: '.ctrlProcessWave' })
    },
    (err) => {
      reclog(RecordApp.Current.Key + '开始录音失败：' + err, 1)
    }
  )
}

const recStop = () => {
  var _rec = rec.value
  rec.value = false
  recordDialog.value = false
  if (!_rec || !RecordApp.Current) {
    return
  }

  RecordApp.Stop(
    (blob, duration) => {
      recording.value = false
      durationString.value = '00:00'
      chatList.value.push({
        type: 'custom',
        isCloud: false,
        audio: (window.URL || webkitURL).createObjectURL(blob),
        blob: blob,
        text: recordText.value,
        testType: testType.value
      })
    },
    (msg) => {
      reclog('录音失败：' + msg, 1)
    }
  )
}
const saveTextOnly = () => {
  recordDialog.value = false
  chatList.value.push({
    type: 'custom',
    isCloud: false,
    audio: undefined,
    blob: undefined,
    text: recordText.value,
    testType: testType.value
  })
}
const waitDialogClickFn = () => {
  dialogCancel()
}

const showDialog = () => {
  // 我们可以选择性的弹一个对话框：为了防止移动端浏览器存在第三种情况：用户忽略，并且（或者国产系统UC系）浏览器没有任何回调
  if (!/mobile/i.test(navigator.userAgent)) {
    return // 只在移动端开启没有权限请求的检测
  }
  recOpenDialogShow.value = 1
}

const dialogCancel = () => {
  clearTimeout(dialogInt.value)
  recOpenDialogShow.value = 0
}

const waitDialogClick = () => {
  dialogCancel()
  waitDialogClickFn()
}

const logs = ref([])
const reclog = (msg, color, res) => {
  logs.value.splice(0, 0, {
    idx: logs.value.length,
    msg: msg,
    color: color,
    res: res,
    playMsg: '',
    down: 0,
    down64Val: ''
  })
}

const formartTime = (time) => {
  let minute = '00'
  let second = '00'
  var times = time / 1000
  // 计算分钟数 转化为整数
  var m = parseInt((times / 60) % 60)
  // 如果分钟数小于 10，要变成 0 + 数字的形式 赋值给盒子
  minute = m < 10 ? '0' + m : m
  // 计算描述 转化为整数
  var s = parseInt(times % 60)
  // 如果秒钟数小于 10，要变成 0 + 数字的形式 赋值给盒子
  second = s < 10 ? '0' + s : s
  return minute + ':' + second
}
</script>
<style lang="scss"></style>
