<template lang="">
  <el-row class="speak-container" :gutter="20">
    <el-col :span="6">
      <el-card :body-style="{padding:0}">
        <template #header>
          <div class="card-header">
            <span :class="activeName === 'part1'?'active':''" style="margin-right:10px;" @click="handleCard('part1')">Part 1</span>
            <span :class="activeName === 'part23'?'active':''" @click="handleCard('part23')">Part 2&3</span>
          </div>
        </template>
        <el-scrollbar style="height:calc(100vh - 65px);">
          <ul v-show="activeName === 'part1'">
            <li v-for="(topic,index) in part1List" :key="topic.contentId" :class="(currentTopic !== undefined && currentTopic.contentId === topic.contentId) ? 'active':''" @click="changTopic('part1',index)">
              {{ index +1 }} {{ topic.title }}
            </li>
          </ul>
          <ul v-show="activeName === 'part23'">
            <li v-for="(topic,index) in part23List" :key="topic.contentId" :class="(currentTopic !== undefined && currentTopic.contentId === topic.contentId) ? 'active':''" @click="changTopic('part23',index)">
              {{ index +1 }} {{ topic.title }}
            </li>
          </ul>
        </el-scrollbar>
      </el-card>
    </el-col>
    <el-col :span="18">
      <el-scrollbar height="100vh">
        <el-card>
          <div v-if="currentTopic === undefined" style="text-align:center;">
            <img style="width:100%; height:auto; display:block;" :src="require('@/assets/svgs/topic.svg')">
          </div>
          <div v-else>
            <!--阿里云播放器-->
            <aliyun-player
              ref="player"
              :encrypt-type="1"
              :source="videoUrl"
              :playauth="playAuth"
              :use-flash-prism="false"
              :autoplay="true"
              :is-live="false"
              :show-buffer="true"
              :cover="cover"
              :show-bar-time="500"
              :disable-seek="true"
              width="100%"
              control-bar-visibility="always"
            />
            <el-scrollbar height="400px">
              <div style="margin-top:20px;" v-html="currentTopic.content " />
            </el-scrollbar>
          </div>
        </el-card>
      </el-scrollbar>
    </el-col>
  </el-row>
</template>
<script>
import { findList } from '@/apis/content'
import AliyunPlayer from '@/plugins/AliyunPlayer/index.vue'

export default {
  name: 'SpeakPage',
  components: { AliyunPlayer },
  data() {
    return {
      activeName: 'part1',
      part1List: [],
      part23List: [],
      currentTopic: undefined,
      cover: '', // 视频封面
      videoUrl: '', // 视频vid
      playAuth: '' // 鉴权地址

    }
  },
  created() {
    this.findPart1List()
    this.findPart23List()
  },
  methods: {
    findPart1List() {
      findList({ cateId: 157 }).then(res => {
        var _datas = res.data.data
        this.part1List = _datas.reverse()
      })
    },
    findPart23List() {
      findList({ cateId: 158 }).then(res => {
        var _datas = res.data.data
        this.part23List = _datas.reverse()
      })
    },
    changTopic(p, index) {
      if (p === 'part1') {
        this.currentTopic = this.part1List[index]
      } else {
        this.currentTopic = this.part23List[index]
      }
      var _this = this
      this.$nextTick(() => {
        _this.videoUrl = this.currentTopic.description
        _this.$refs.player.initAliplayer()
      })
    },
    handleCard(t) {
      this.activeName = t
    }
  }
}
</script>
<style lang="scss" scoped>
.speak-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  height: 100vh;
  background-color: #ffffff;
}

.card-header{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.card-header span{
  cursor: pointer;
}

.card-header span.active{
  color: #409EFF;
  font-weight: bold;
}

ul{
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li{
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
}

ul li:nth-child(odd){
  background-color: #fafafa;
}

ul li:hover{
  background-color: #f5f7fa;
}
ul li.active{
  color: #409EFF;
  background-color:  #ecf5ff;
}
</style>
