<template lang="">
  <div class="py-6">
    <v-row>
      <v-col offset="2" cols="8">
        <v-card class="elevation-4 pa-0">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div class="d-flex flex-column justify-space-between pa-2">
              <v-card-title class="d-flex align-center">
                2023年5月-8月口语题库
                <v-icon color="red" icon="mdi-fire" size="small" />
              </v-card-title>
              <v-card-subtitle> 适合23年5月-12月口语考试使用 </v-card-subtitle>
              <v-card-text>
                <span class="text-h5 text-red">￥98.00</span>
                <span
                  class="text-decoration-line-through text-medium-emphasis ml-1"
                >￥198.00</span>
              </v-card-text>
              <v-card-actions>
                <router-link to="/speaking/test/1660461864624705538">
                  <v-btn
                    append-icon="mdi-chevron-right"
                    class="ms-2"
                    variant="outlined"
                    color="deep-purple"
                  >
                    进入题库
                  </v-btn>
                </router-link>
              </v-card-actions>
            </div>
            <v-avatar class="ma-4" size="280" rounded="0">
              <v-img
                src="http://pig.hibookapp.com/admin/sys-file/ielts-test/4e4321489ae443d38b97f364683811bc.jpg"
              />
            </v-avatar>
          </div>
        </v-card>
        <v-card class="elevation-1 pa-0 mt-8">
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-img
              :width="160"
              aspect-ratio="16/9"
              cover
              src="http://pig.hibookapp.com/admin/sys-file/ielts-test/2d64f86177364668ae231b355a44ab11.jpg"
            />
            <div
              class="flex-grow-1 d-flex flex-column justify-space-between pa-2"
            >
              <v-card-title class="d-flex align-center">
                雅思口语资料
              </v-card-title>
              <v-card-subtitle>
                口语题库会员及雅思机考会员免费开放
              </v-card-subtitle>
              <v-card-text>
                <div class="text-decoration-line-through text-h5 text-red">
                  ￥98.00
                </div>
              </v-card-text>
              <v-card-actions>
                <router-link to="/speak">
                  <v-btn
                    append-icon="mdi-chevron-right"
                    class="ms-2"
                    variant="outlined"
                  >
                    查看资料
                  </v-btn>
                </router-link>
              </v-card-actions>
            </div>
          </div>
        </v-card>
        <!-- <v-card class="mt-6 elevation-4">
          <v-card-title> 9分口语题库介绍 </v-card-title>
          <v-card-text>
            <div class="test-detail">
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/0e911064f1cf4dc0ab621be630971607.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/583a42a137d44f0c9e3136e1e24db7c8.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/4e5a4da126f74eaea60a61bd0d30bbe8.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/70aa5675e2b1434ba6bb0efc854db4b5.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/c251b5143d3f4ad5960908c36078dc40.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/085bb85ebf994859b77e5b9f16efafd5.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/1e86c8ad15e54f8798a73c3d489c9de1.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/ede5f28c44ab4c51b63283c20ce4c977.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/62c516ecf7404c79bcdec978b306bfc8.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/ffb32c36e8f14d43bfa03f0595d94bd9.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/7a10e0c402ce4890819359310f5810f9.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/6a7cc3d7ae7146b2abcbdfd08adf6c9c.jpg"
              >
              <img
                class="wscnph"
                src="/admin/sys-file/ielts-test/edb74dfe300d48d980cdeb16db0c7259.jpg"
              >
            </div>
          </v-card-text>
        </v-card> -->
      </v-col>
    </v-row>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.test-detail {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
