/* eslint-disable no-unused-vars */
import $ from 'jquery'
import rangy from 'rangy'
import 'rangy/lib/rangy-highlighter'
import 'rangy/lib/rangy-textrange'
import 'rangy/lib/rangy-classapplier'
import Note from './notes.js'

var handlingIt = false
var started = false

var highestZ = 0
var highestId = 0

export function destroyContextMenu() {
  $.contextMenu('destroy', '#highlightable-content')
}

/**
 * Context menu; Text highlighting; Notes
 */
export function initContextMenu() {
  // 1. Check highlight overlap
  // 2. Store notes in global var - keyed by highlight ID
  //      2.1 Generate ID randomly? Or hash the note content (might be slow; what if note is empty? - maybe no need to store)
  // 3. Toggle context menu items in regards to note status
  $.contextMenu({
    selector: '#highlightable-content',
    items: {
      highlight: {
        name: 'Highlight',
        icon: 'highlight',
        callback: highlightSelection
      },
      note: {
        name: 'Notes',
        icon: 'edit',
        callback: highlightSelection
      },
      clear: {
        name: 'Clear',
        icon: 'clear',
        disabled: true,
        callback: clearThisHighlight,
        title: 'Clears this highlighting'
      },
      clearAll: {
        name: 'Clear all',
        icon: 'clearAll',
        disabled: true,
        callback: clearAllHighlight,
        title: 'Clears all highlighting on this page'
      }
    },
    events: {
      show: checkSelection
    }
  })

  /**
   * Rangy stuff
   */
  const highlightClass = 'highlighted'

  rangy.init()

  var CssApplier
  var store = {}
  var Ranges = []
  var RangesOrder = []
  var NotesOptions = {}
  var Screen = {}

  CssApplier = rangy.createClassApplier(highlightClass, {
    ignoreWhiteSpace: true,
    tagNames: ['span', 'a']
  })

  Screen.notes = {}
  Screen.textAreaSelection = null
  Screen.mainSelector = '#highlightable-content'

  // screen.mainSelector = '[role~="main"] [connect\\:class~="columns"] [connect\\:class~="row"]';

  function highlightSelection(action, opt) {
    var str
    var $note
    var key = makeid()
    var range
    var rangeCheck
    var rkey

    if (Screen.sel.rangeCount === 0 || Screen.sel.isCollapsed) {
      // If range is empty
      // range = rangy.getSelection().getRangeAt(0)
      rkey = getContextualRange(Screen.crntTarget)
      range = Ranges[rkey]
    } else {
      range = Screen.sel.getRangeAt(0)
    }

    var $this = $(this)
    rangeCheck = checkHighlightedRanges(range)
    if (rangeCheck) {
      if (rangeCheck.update) {
        CssApplier.applyToRange(range)
        Ranges[rangeCheck.overlap] = range
        checkRangesOrdering(rangeCheck.overlap)
      }

      if (opt === 'note') {
        str = range.toString()
        if (Screen.notes[rangeCheck.overlap]) {
          $note = $(Screen.notes[rangeCheck.overlap].note)
          if (rangeCheck.update) {
            $note.find('.' + highlightClass).text(str)
          }
          $note.fadeIn()
        } else {
          Screen.notes[rangeCheck.overlap] = newNote(str, $this.offset())
        }
      }
    } else {
      CssApplier.applyToRange(range)
      Ranges[key] = range
      checkRangesOrdering(key)

      if (opt === 'note') {
        str = range.toString()
        Screen.notes[key] = newNote(str, $this.offset())
      }
    }
    Screen.sel.collapseToEnd()
  }

  function clearThisHighlight() {
    var key, pos
    for (key in Ranges) {
      if (Object.prototype.hasOwnProperty.call(Ranges, key)) {
        var trgtRng = Ranges[key]
        if (rangeIntersectsNode(trgtRng, Screen.crntTarget)) {
          CssApplier.undoToRange(trgtRng)
          if (Screen.notes[key]) {
            $(Screen.notes[key].note).remove()
            delete Screen.notes[key]
          }
          delete Ranges[key]
          pos = RangesOrder.indexOf(key)
          RangesOrder.splice(pos, 1)
          break
        }
      }
    }
  }

  function clearAllHighlight() {
    var key, pos
    for (key in Ranges) {
      if (Object.prototype.hasOwnProperty.call(Ranges, key)) {
        var trgtRng = Ranges[key]
        if (
          $(trgtRng.startContainer).parent().closest('#highlightable-content')
            .length > 0
        ) {
          CssApplier.undoToRange(trgtRng)
          if (Screen.notes[key]) {
            $(Screen.notes[key].note).remove()
            delete Screen.notes[key]
          }
          delete Ranges[key]
          pos = RangesOrder.indexOf(key)
          RangesOrder.splice(pos, 1)
        }
      }
    }
  }

  function newNote(txt, pos) {
    var note = new Note(NotesOptions)
    note.id = ++highestId
    if (pos) {
      note.left = pos.left + 'px'
      note.top = pos.top + 'px'
    } else {
      note.left = '80%'
      note.top = '60px'
    }
    note.zIndex = ++highestZ

    if (txt && NotesOptions.highlightText) {
      note.hText = txt
      note.onNoteClick()
    }
    return note
  }

  function makeid() {
    var text = ''
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (var i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

  /**
   * Check if the given selection is inside the area where highlighting is allowed
   * @param sel
   * @returns {boolean}
   */
  function checkValidSelection(sel) {
    var range = sel.getRangeAt(0)
    return (
      $(range.startContainer.parentNode).closest('#highlightable-content')
        .length > 0 &&
      $(range.endContainer.parentNode).closest('#highlightable-content')
        .length > 0
    )
  }

  /**
   * Check selection gotten from Rangy for possible context menu options and disable/enable options accordingly
   * Also, sets screen.sel to the selection
   *
   * @param opt
   * @returns {boolean}
   */
  function checkSelection(_opt) {
    Screen.sel = rangy.getSelection()

    var selContent = Screen.sel.toHtml()
    /* if (/</.test(selContent) && $(selContent).find('[connect\\:responseIdentifier],[connect\\:identifier]').length > 0) {
        return false;
    }*/
    var opt = _opt.data

    var isSelected =
      Screen.sel.anchorNode.parentNode.className === 'highlighted'
    if (Screen.sel.rangeCount === 0 || isSelected) {
      // If selection is empty disable context menu functions
      opt.items.highlight.disabled = true
      if ($(Screen.crntTarget).is('.highlighted')) {
        opt.items.note.disabled = false
        opt.items.clear.disabled = false
        opt.items.clearAll.disabled = false
      } else {
        opt.items.note.disabled = true
        opt.items.clear.disabled = true
        opt.items.clearAll.disabled = true
      }
    } else {
      console.log('checkValidSelection', checkValidSelection(Screen.sel))
      if (checkValidSelection(Screen.sel)) {
        opt.items.highlight.disabled = false
        opt.items.note.disabled = false
      } else {
        opt.items.highlight.disabled = true
        opt.items.note.disabled = true
      }
      opt.items.clear.disabled = true
      opt.items.clearAll.disabled = true
    }
    if (
      (opt.items.highlight.disabled &&
        opt.items.note.disabled &&
        opt.items.clear.disabled) ||
      (opt.items.highlight.disabled &&
        opt.items.note.disabled &&
        opt.items.clear.disabled &&
        opt.items.clearAll.disabled)
    ) {
      opt.$menu.addClass('hidden')
    } else {
      opt.$menu.removeClass('hidden')
    }
  }

  function checkRangeOverlap(focalRange, testRange) {
    var extremetiesOK =
      focalRange.compareBoundaryPoints(Range.END_TO_START, testRange) === -1
    var overlapAreaOK =
      focalRange.compareBoundaryPoints(Range.START_TO_END, testRange) === 1
    return extremetiesOK && overlapAreaOK
  }

  function checkHighlightedRanges(selRange) {
    var key
    var crntRange
    var startInRange
    var endInRange
    var check = false
    for (key in Ranges) {
      if (Object.prototype.hasOwnProperty.call(Ranges, key)) {
        crntRange = Ranges[key]
        if (checkRangeOverlap(selRange, crntRange)) {
          check = {
            overlap: key
          }
          startInRange = crntRange.isPointInRange(
            selRange.startContainer,
            selRange.startOffset
          )
          endInRange = crntRange.isPointInRange(
            selRange.endContainer,
            selRange.endOffset
          )
          if (!startInRange || !endInRange) {
            if (startInRange) {
              // crntRange.setEnd(selRange.endContainer, selRange.endOffset)
              selRange.setStart(crntRange.startContainer, crntRange.startOffset)
            }
            if (endInRange) {
              // crntRange.setStart(selRange.startContainer, selRange.startOffset)
              selRange.setEnd(crntRange.endContainer, crntRange.endOffset)
            }
            // crntRange = selRange
            delete Ranges[key]
            if (Screen.notes[key]) {
              delete Screen.notes[key]
            }
            check.update = true
          } else {
            selRange = crntRange
            check.update = false
          }
        }
      }
    }
    return check
  }

  function checkRangesOrdering(crntkey) {
    // var range = ranges[crntkey], trgtRange, key, newkey;
    var focalRange = Ranges[crntkey]
    var reordered = false
    var trgtRange
    var key
    var pos
    var focalpos
    for (key in Ranges) {
      if (
        Object.prototype.hasOwnProperty.call(Ranges, key) &&
        key !== crntkey
      ) {
        trgtRange = Ranges[key]
        pos = RangesOrder.indexOf(key)
        if (
          (!focalpos || focalpos > pos) &&
          (trgtRange.startContainer.parentNode.parentNode ===
            focalRange.startContainer.parentNode.parentNode ||
            trgtRange.startContainer.parentNode.parentNode ===
              focalRange.endContainer.parentNode.parentNode) &&
          focalRange.compareBoundaryPoints(Range.START_TO_END, trgtRange) === -1
        ) {
          RangesOrder.splice(pos, 0, crntkey)
          focalpos = pos
          reordered = true
        }
      }
    }
    if (!reordered) {
      RangesOrder.push(crntkey)
    }
  }

  function rangeIntersectsNode(range, node) {
    var nodeRange = node.ownerDocument.createRange()
    try {
      nodeRange.selectNode(node)
    } catch (e) {
      nodeRange.selectNodeContents(node)
    }
    return checkRangeOverlap(range, nodeRange)
  }

  function getContextualRange1(testRange) {
    var key, trgtRng
    for (key in Ranges) {
      if (Object.prototype.hasOwnProperty.call(Ranges, key)) {
        trgtRng = Ranges[key]
        if (
          trgtRng.isPointInRange(
            testRange.startContainer,
            testRange.startOffset
          )
        ) {
          // var x = 1;
          return key
        }
      }
    }
    return false
  }

  function getContextualRange(node) {
    var key, trgtRng
    for (key in Ranges) {
      if (Object.prototype.hasOwnProperty.call(Ranges, key)) {
        trgtRng = Ranges[key]
        if (rangeIntersectsNode(trgtRng, node)) {
          return key
        }
      }
    }
    return false
  }

  CssApplier = rangy.createClassApplier(highlightClass, {
    ignoreWhiteSpace: true,
    tagNames: ['span', 'a']
  })

  // $('[role~="banner"], [role~="main"], [role~="navigation"]').on('mousedown', function (e) {
  $('#highlightable-content').on('mousedown', function(e) {
    var target
    var interactions
    var respId
    var fadeMaybe = false
    var regexp = /#\w+-\w+-\w+-\w+/ // matches a hash character followed by a response identifier
    var activeItemBody = $('div[connect\\:class="itemBody activeItem"]')

    Screen.crntTarget = e.target
    target = $(e.target)

    if (!target.is('.note') || target.parents('.note').length === 0) {
      $('div.note:visible').fadeOut(250) // make notes fadeout when click elsewhere on screen
    }

    // I don't understand any of this
    /* if (target.is('span.question-number')) {
		    target = target.parent();
		    fadeMaybe = true;
		} else if (target.is('a[connect\\:function="next"]') || target.is('a[connect\\:function="previous"]')) {
		    fadeMaybe = true;
		}

		if (fadeMaybe) {
		    respId = target.attr('href').match(regexp);
		    if (respId) {
		        respId = respId.join('').substring(1);
		        interactions = activeItemBody.find('*[connect\\:responseIdentifier~="' + respId + '"]');

		        if (interactions.length > 0) {
		            fadeMaybe = false;
		        }
		    }

		    if (fadeMaybe) {
		        $('div.note:visible').fadeOut(250); //make notes fadeout when click elsewhere on screen
		    }
		}*/
  })

  $('#highlightable-content').on(
    'mouseup',
    '.' + highlightClass,
    function(event) {
      if (event.which === 1) {
        // only for left mouse button usage
        var rkey = getContextualRange(Screen.crntTarget)
        if (rkey && Screen.notes[rkey]) {
          $(Screen.notes[rkey].note).fadeIn()
        }
      }
    }
  )

  $('#highlightable-content').on(
    'mouseover',
    '.' + highlightClass,
    function(event) {
      var htext = event.target
      var rkey = getContextualRange(htext)
      var notes = $(htext).find('span.notesIcon')
      if (rkey && Screen.notes[rkey]) {
        if (notes.length === 0) {
          $(htext).prepend('<span class="notesIcon"></span>')
        } else {
          notes.show()
        }
      }
    }
  )

  $('#highlightable-content').on(
    'mouseleave',
    '.' + highlightClass,
    function(event) {
      var htext = event.target
      var rkey = getContextualRange(htext)
      if (rkey && Screen.notes[rkey]) {
        $(htext).find('span.notesIcon').hide()
      }
    }
  )

  $('#highlightable-content').on('mouseout', '.notesIcon', function(event) {
    $(this).hide()
  })

  NotesOptions.highlightText = false
  /*    if (options) {
	        if (options.noteHtext === false) {
	            notesOptions.highlightText = false;
	        }
	    }*/
}
