// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import ViewsRouter from './views'
import TestRouter from './views/test'
import CourseRouter from './views/course'
import FrontRouter from './views/front'
import SpeakingRouter from './views/speaking'
import NotFound from '@/views/404/NotFound'

export const completeRoutes = [
  {
    name: '404',
    path: '/404',
    component: NotFound
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: '404'
    }
  }
]

export const constantRoutes = [
  ...ViewsRouter,
  ...TestRouter,
  ...CourseRouter,
  ...FrontRouter,
  ...SpeakingRouter,
  ...completeRoutes
]

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes: constantRoutes
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
