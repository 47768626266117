<template lang="">
  <div class="common-layout">
    <el-card :body-style="{ padding: '16px'}" style="margin-bottom:10px;">
      <div class="writing">
        <div v-if="state.isMember" style="font-weight:bold;">
          <template v-if="state.member.status === '1'">
            会员账户被冻结，请联系管理员处理
          </template>
          <template v-else>
            会员有效期至<span class="quantity">{{ state.member.endTime }}</span>，  剩余<span class="quantity">{{ state.remainDays }}</span> 天
          </template>
        </div>
        <div v-else style="font-weight:bold;">
          您还不是会员，购买会员卡，激活会员权益！
        </div>
        <div>
          <el-button type="danger" plain @click="showGoods"><font-awesome-icon icon="fa-solid fa-cart-shopping" style="margin-right:5px;" />购买/激活会员</el-button>
          <router-link :to="'/paper/history'" style="margin-left:16px;">
            <el-button plain @click="dialogFormVisible = true"><font-awesome-icon icon="fa-solid fa-clipboard-list" style="margin-right:5px;" />答题记录</el-button>
          </router-link>
        </div>
      </div>
    </el-card>
    <PapersComponent style="height:calc(100vh - 97px);" />
    <GoodsComponent ref="goodsRef" />
  </div>
</template>
<script setup>
import { ref, reactive, watch, onMounted } from 'vue'
import { useStore } from 'vuex'

import { getDateDiff } from '@/utils/util'
import GoodsComponent from '@/components/Goods/GoodsComponent'
import PapersComponent from './components/PapersComponent'

const store = useStore()
const state = reactive({
  member: undefined,
  isMember: false,
  remainDays: 0
})

watch(
  () => store.getters.memberInfo,
  (newVal, oldVal) => {
    handleMember()
  }
)

const goodsRef = ref(undefined)
const showGoods = () => {
  goodsRef.value.show()
}

onMounted(() => {
  handleMember()
})

const handleMember = () => {
  state.member = store.getters.memberInfo
  if (state.member === undefined || JSON.stringify(state.member) === '{}') {
    state.isMember = false
    state.remainDays = 0
    return
  }

  state.isMember = true
  var _range = getDateDiff(state.member.endTime, 'day')
  if (_range > 0) {
    state.remainDays = _range
  } else {
    state.remainDays = 0
  }
}

</script>
<style lang="scss" scoped>
.common-layout {
  background-color: #FFFFFF;
  min-height: 100vh;
  padding: 10px;
}

.writing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #414042;
}

.quantity {
  font-weight: bolder;
  color: #F56C6C;
  font-size: 22px;
  margin: 0 5px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.member {
  width: 100%;
  height: 220px;
  background-color: #414042;
  color: #dcb71d;
  margin-bottom: 15px;
}

.member .member-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
}

.member .member-card .card-info {
  display: inline-block;
}

.member .member-card img {
  display: block;
  width: 150px;
  height: auto;
}

.member .member-card .username {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
}

.member .member-card .member-num {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.member .member-card .end-time {
  font-size: 16px;
  margin-bottom: 10px;
}

.member .member-card .end-time span {
  color: #E31837;
}

.member .member-card .member-note {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 20px;
}

</style>
