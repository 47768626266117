<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer">
      <dl class="menu">
        <router-link to="/nine/speaking">
          <dt
            :class="{
              active: key === '/nine/speaking'
            }"
          >
            <v-icon icon="mdi-home" :size="22" class="mr-2" /> Home
          </dt>
        </router-link>
        <router-link to="/nine/speaking/content/1648264020884197377">
          <dt
            :class="{
              active: key === '/nine/speaking/content/1648264020884197377'
            }"
          >
            <v-icon icon="mdi-newspaper-variant" :size="22" class="mr-2" />
            Useful IELTS Language
          </dt>
        </router-link>
        <router-link to="/nine/speaking/content/1648261440841977858">
          <dd
            :class="{
              active: key === '/nine/speaking/content/1648261440841977858'
            }"
          >
            Common Collocations
          </dd>
        </router-link>
        <router-link to="/nine/speaking/content/1648518384467054594">
          <dd
            :class="{
              active: key === '/nine/speaking/content/1648518384467054594'
            }"
          >
            Common Connectives and Discourse Markers
          </dd>
        </router-link>
        <router-link to="/nine/speaking/content/1648538995650203650">
          <dd
            :class="{
              active: key === '/nine/speaking/content/1648538995650203650'
            }"
          >
            Idioms – What are they?
          </dd>
        </router-link>
        <router-link to="/nine/speaking/content/1648566300053344257">
          <dd
            :class="{
              active: key === '/nine/speaking/content/1648566300053344257'
            }"
          >
            Tenses and Timelines
          </dd>
        </router-link>
      </dl>
    </v-navigation-drawer>
    <v-app-bar :color="'deep-purple accent-4'" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-toolbar-title> 9分达人口语宝典 </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view v-slot="{ Component }" :key="key">
        <transition name="fade-transform" mode="out-in">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </transition>
      </router-view>
    </v-main>
    <GoToTop />
  </v-app>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import GoToTop from '@/components/GoToTop'

const route = useRoute()
const key = computed(() => {
  return route.path
})
const drawer = ref(false)
</script>
<style lang="scss" scoped>
dl.menu {
  dt {
    font-weight: bolder;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  dd {
    padding: 8px 10px 8px 40px;
  }
  .active {
    background-color: #ede7f6;
    color: #673ab7;
    font-weight: bolder;
  }
}
</style>
