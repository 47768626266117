<template lang="">
  <div style="padding-top:10px;">
    <el-input
      v-model="taskValue"
      :rows="25"
      type="textarea"
      placeholder="Please input"
      @input="onInput($event, partId)"
    />
    <span style="font-size:14px; color:grey;">Words: {{ wordsCount }}</span>
  </div>
</template>
<script>

export default {
  props: {
    task: {
      type: String,
      default: ''
    },
    partId: {
      type: String,
      default: ''
    },
    typeCategoryId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      taskValue: undefined,
      wordsCount: 0
    }
  },
  computed: {
    getValue() {
      return this.task
    }
  },
  watch: {
    getValue: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.taskValue = this.task
        this.wordsCount = this.getWordsCount(this.taskValue)
      }
    }
  },
  mounted() {
    this.taskValue = this.task
    this.wordsCount = this.getWordsCount(this.taskValue)
  },
  methods: {
    onInput(e, partId) {
      this.wordsCount = this.getWordsCount(e)
      this.$emit('onInput', e, partId)
    },
    getWordsCount(val) {
      if (val === null || val === '' || val === undefined || val === 'null' || val === 'undefined') {
        return 0
      }
      var value = val
      // 替换中文字符为空格
      value = value.replace(/[\u4e00-\u9fa5]+/g, ' ')
      // 将换行符，前后空格不计算为单词数
      value = value.replace(/\n|\r|^\s+|\s+$/gi, ' ')
      // 多个空格替换成一个空格
      value = value.replace(/\s+/gi, ' ')
      // 更新计数
      var length = 0
      var match = value.match(/\s/g)
      if (match) {
        length = match.length + 1
      } else if (value) {
        length = 1
      }
      return length
    }
  }

}
</script>
<style lang="">

</style>
