<template lang="">
  <div
    class="audio_wraper px-2"
    :class="customClass"
    :style="{ background: bColor, color: tColor }"
  >
    <audio
      ref="audioRef"
      controlsList="nodownload"
      :src="state.audioList[state.currentIndex]"
      preload="auto"
      @play="onPlay"
      @error="onError"
      @waiting="onWaiting"
      @pause="onPause"
      @timeupdate="onTimeupdate"
      @loadedmetadata="onLoadedmetadata"
      @ended="onEnded"
    >
      您的浏览器不支持 audio 元素。
    </audio>
    <div class="control_button">
      <template v-if="!controls.noBackward">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="上一句"
          placement="top"
        >
          <a
            class="mx-2"
            href="javascript:void(0);"
            style="margin-right: 16px"
            @click="handleBackward"
          ><font-awesome-icon icon="fa-solid fa-backward" /></a>
        </el-tooltip>
      </template>
      <a class="mx-2" href="javascript:void(0);" @click="startPlayOrPause">
        <font-awesome-icon
          v-if="audio.playing"
          icon="fa-solid fa-circle-pause"
          size="2x"
        />
        <font-awesome-icon v-else icon="fa-solid fa-circle-play" size="2x" />
      </a>
      <template v-if="!controls.noForward">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="下一句"
          placement="top"
        >
          <a
            class="mx-2"
            href="javascript:void(0);"
            @click="handleForward"
          ><font-awesome-icon icon="fa-solid fa-forward" /></a>
        </el-tooltip>
      </template>
    </div>
    <div
      v-if="!controls.noBackward5 || !controls.noForward5"
      class="control_button mx-2"
    >
      <template v-if="!controls.noBackward5">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="倒退5秒"
          placement="top"
        >
          <a class="mx-2" href="javascript:void(0);" @click="backward5Seconds">
            <font-awesome-icon
              icon="fa-solid fa-rotate-left"
              style="font-size: 20px"
            />
          </a>
        </el-tooltip>
      </template>
      <template v-if="!controls.noForward5">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="前进5秒"
          placement="top"
        >
          <a class="mx-2" href="javascript:void(0);" @click="forward5Seconds">
            <font-awesome-icon
              icon="fa-solid fa-rotate-right"
              style="font-size: 20px"
            />
          </a>
        </el-tooltip>
      </template>
    </div>
    <template v-if="!controls.noProcess">
      <div class="slider-audio-block mx-2" style="z-index: 1000">
        <v-slider
          v-model="sliderTime"
          :color="thumbColor"
          :min="0"
          :max="audio.maxTime"
          hide-details
          @update:modelValue="changeCurrentTime"
        />
        <!-- <el-slider
          v-model="sliderTime"
          :min="0"
          :max="audio.maxTime"
          :format-tooltip="formatProcessToolTip"
          @input="changeCurrentTime"
        /> -->
      </div>
    </template>
    <template v-if="!controls.noTime">
      <div class="slider_times mx-2">
        {{ formatSecond(audio.currentTime) }} /
        {{ formatSecond(audio.maxTime) }}
      </div>
    </template>
    <div>
      <template v-if="!controls.noMuted">
        <a class="mx-2" href="javascript:void(0);" @click="changeMuted">
          <template v-if="audio.muted">
            <font-awesome-icon icon="fa-solid fa-volume-xmark" />
          </template>
          <template v-else>
            <font-awesome-icon icon="fa-solid fa-volume-off" />
          </template>
        </a>
      </template>
      <template v-if="!controls.noSpeed">
        <el-popover placement="top" trigger="hover">
          <template #reference>
            <a
              class="mx-2"
              href="javascript:void(0);"
            >倍速:{{ audio.speed.toString() }}X</a>
          </template>
          <ul class="spead_list">
            <li
              v-for="(speed, index) in props.speeds"
              :key="index"
              :class="{ active: audio.speed.toFixed(2) === speed.toFixed(2) }"
              @click="changeSpeed(index)"
            >
              {{ speed.toFixed(2) }} X
            </li>
          </ul>
        </el-popover>
      </template>
      <template v-if="!controls.noVolume">
        <el-popover placement="top" trigger="hover">
          <template #reference>
            <a class="mx-2" href="javascript:void(0);">
              <font-awesome-icon
                v-if="state.volume === 0"
                icon="fa-solid fa-volume-xmark"
                style="font-size: 20px"
              />
              <font-awesome-icon
                v-else-if="state.volume < 80"
                icon="fa-solid fa-volume-low"
                style="font-size: 20px"
              />
              <font-awesome-icon
                v-else
                icon="fa-solid fa-volume-high"
                style="font-size: 20px"
              />
            </a>
          </template>
          <div class="volume_slider">
            <v-slider
              v-model="state.volume"
              color="#000000"
              :min="0"
              :max="100"
              :step="1"
              hide-details
              thumb-label
              @update:modelValue="changeVolume"
            />
          </div>
        </el-popover>
      </template>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  reactive,
  defineProps,
  watch,
  nextTick,
  defineEmits,
  defineExpose
} from 'vue'

const emit = defineEmits([
  'changeCurrentTime',
  'timeupdate',
  'backward',
  'forward',
  'ended'
])

const props = defineProps({
  currentTime: {
    type: Number,
    default: 0
  },
  currentIndex: {
    type: Number,
    default: 0
  },
  audios: {
    type: Array,
    default: undefined
  },
  controls: {
    type: String,
    default: ''
  },
  autoPlay: {
    type: Boolean,
    default: true
  },
  speeds: {
    type: Array,
    default() {
      return [0.5, 0.75, 1.0, 1.25, 1.5]
    }
  },
  bColor: {
    type: String,
    default: '#1c2233'
  },
  tColor: {
    type: String,
    default: '#ffffff'
  },
  thumbColor: {
    type: String,
    default: 'rgb(254, 193, 113)'
  },
  customClass: {
    type: String,
    default: ''
  }
})

watch(
  () => props.audios,
  (newVar) => {
    state.audioList = props.audios
  }
)

const state = reactive({
  audioList: [],
  currentIndex: 0,
  volume: 100
})

const audio = reactive({
  currentTime: 0,
  maxTime: 0,
  playing: false,
  muted: false,
  speed: 1.0,
  waiting: true,
  preload: true
})

const controls = reactive({
  // 不显示后退5秒
  noBackward5: false,
  // 不显示快进5秒
  noForward5: false,
  // 不显示上一个
  noBackward: false,
  // 不显示下一个
  noForward: false,
  // 不显示下载
  noDownload: false,
  // 不显示静音
  noMuted: false,
  // 不显示音量条
  noVolume: false,
  // 不显示进度条
  noProcess: false,
  // 只能播放一个
  onlyOnePlaying: false,
  // 不显示倍速
  noSpeed: false,
  // 不显示时间
  noTime: false
})

const initControls = () => {
  const controlList = props.controls.split(' ')
  controlList.forEach((item) => {
    if (controls[item] !== undefined) {
      controls[item] = true
    }
  })
}
initControls()

const sliderTime = ref(0)
const audioRef = ref(null)

state.audioList = props.audios
const initAudio = () => {
  if (state.audioList === undefined) {
    return
  }

  if (
    props.currentIndex !== undefined &&
    props.currentIndex < state.audioList.length
  ) {
    state.currentIndex = props.currentIndex
  } else {
    state.currentIndex = 0
  }
}
initAudio()

const onTimeupdate = () => {
  audio.currentTime =
    audioRef.value === undefined ? 0 : audioRef.value.currentTime
  sliderTime.value = parseInt(audio.currentTime)
  // if (audio.currentTime >= audio.maxTime) {
  //   audioRef.value.pause()
  //   audio.currentTime = 0
  // }
  emit('timeupdate', audioRef.value.currentTime)
}

// 改变倍速
const changeSpeed = (index) => {
  audio.speed = props.speeds[index]
  audioRef.value.playbackRate = audio.speed
}

// 改变音量
const changeVolume = (v) => {
  state.volume = v
  audioRef.value.volume = v / 100

  audioRef.value.muted = v === 0
  audio.muted = v === 0
}

// 改变静音
const changeMuted = () => {
  audioRef.value.muted = !audioRef.value.muted
  audio.muted = audioRef.value.muted
  if (audioRef.value.muted) {
    changeVolume(0)
  } else {
    changeVolume(50)
  }
}

const formatSecond = (second = 0) => {
  var secondType = typeof second
  if (secondType === 'number' || secondType === 'string') {
    second = parseInt(second)
    var hours = Math.floor(second / 3600)
    second = second - hours * 3600
    var mimute = Math.floor(second / 60)
    second = second - mimute * 60
    return (
      (hours > 0 ? hours + ':' : '') +
      ('0' + mimute).slice(-2) +
      ':' +
      ('0' + second).slice(-2)
    )
  } else {
    return '0:00:00'
  }
}

const onLoadedmetadata = (res) => {
  audio.waiting = false
  if (!props.autoPlay) {
    audio.playing = false
  }
  audio.maxTime = parseInt(res.target.duration)
  if (props.autoPlay) {
    audioRef.value.play()
  }
}

// 播放跳转
const changeCurrentTime = (val) => {
  audioRef.value.currentTime = parseInt(val)
}

const startPlayOrPause = () => {
  if (state.audioList === undefined || state.audioList.length <= 0) {
    return
  }
  return audio.playing ? pausePlay() : startPlay()
}

// 开始播放
const startPlay = async() => {
  audioRef.value.play()
}

// 停止播放
const stopPlay = () => {
  audioRef.value.pause()
}

// 暂停播放
const pausePlay = () => {
  audioRef.value.pause()
}

// 当音频开始播放
const onPlay = (res) => {
  audio.playing = true
  audio.waiting = false
  // if (!controls.onlyOnePlaying) {
  //   return
  // }
  // const target = res.target
  // const audios = document.getElementsByTagName('audio');
  // [...audios].forEach((item) => {
  //   if (item !== target) {
  //     item.pause()
  //   }
  // })
}

// 当音频暂停
const onPause = () => {
  audio.playing = false
}

// 当发生错误, 就出现loading状态
const onError = () => {
  audio.waiting = true
}

// 当音频开始等待
const onWaiting = (res) => {
  if (res.type === 'waiting') {
    audio.waiting = true
  }
}

// 进度条toolTip
const formatProcessToolTip = (val = 0) => {
  return formatSecond(val)
}

// 后退5秒
const backward5Seconds = () => {
  var cur = audioRef.value.currentTime
  if (parseInt(cur) >= 5) {
    audioRef.value.currentTime = cur - 5
  }
}

// 前进5秒
const forward5Seconds = () => {
  var cur = audioRef.value.currentTime
  var max = audio.maxTime
  if (parseInt(cur) + 5 < parseInt(max)) {
    audioRef.value.currentTime = cur + 5
  }
}

const handleBackward = () => {
  emit('backward')
}

const handleForward = () => {
  emit('forward')
}

// 当音频结束
const onEnded = () => {
  var _len = state.audioList === undefined ? 0 : state.audioList.length
  var _cur = state.currentIndex
  if (_cur < _len - 1) {
    window.setTimeout(() => {
      changeAudio(_cur + 1)
    }, 1000)
  } else {
    audioRef.value.pause()
  }
  emit('ended')
}

const changeAudio = (index) => {
  audioRef.value.pause()
  if (state.currentIndex !== index) {
    state.currentIndex = index
    nextTick(() => {
      startPlay()
    })
  }
}

defineExpose({
  changeCurrentTime,
  changeAudio,
  startPlay,
  stopPlay
})
</script>
<style lang="scss" scoped>
.audio_wraper {
  background: #1c2233;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 32px;

  a {
    display: inline-block;
  }
}

.control_button {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.slider-audio-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  .el-slider {
    flex: 1;
  }

  .slider_times {
    display: inline-block;
    text-align: center;
  }
}

ul.spead_list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }

  li:hover,
  li.active {
    font-weight: bold;
    background-color: #f5f7fa;
  }
}

.volume_slider {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
