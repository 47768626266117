<template>
  <div class="common-layout">
    <el-card :body-style="{ padding: '16px' }" style="margin-bottom: 10px">
      <div class="home_header">
        <div class="home_title">
          <font-awesome-icon icon="fa-solid fa-house-chimney" /> 首页
        </div>
        <div class="home_user_info">
          <el-avatar :size="32" :src="require('@/assets/images/logo.png')" />
          <span class="username">{{ username }}</span>
        </div>
      </div>
    </el-card>
    <el-row :gutter="10">
      <el-col :md="12">
        <el-card
          :body-style="{ padding: '0' }"
          style="height: calc(100vh - 96px)"
        >
          <template #header>
            <div class="card-header">
              <span>更新日志</span>
            </div>
          </template>
          <el-scrollbar style="height: calc(100vh - 165px)">
            <div class="content" v-html="updateLog.content" />
          </el-scrollbar>
        </el-card>
      </el-col>
      <el-col :md="12">
        <el-card
          :body-style="{ padding: '0' }"
          style="height: calc(100vh - 96px)"
        >
          <template #header>
            <div class="card-header">
              <span>使用说明</span>
            </div>
          </template>
          <el-scrollbar style="height: calc(100vh - 165px)">
            <div class="content" v-html="content.content" />
          </el-scrollbar>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { ElMessageBox } from 'element-plus'
import { mapGetters } from 'vuex'
import { findContent } from '@/apis/content'
import { getObj } from '@/apis/sys_param'

import { ref } from 'vue'
export default {
  name: 'HomePage',
  data() {
    return {
      visible: ref(false),
      content: {},
      updateLog: {},
      readingContent: {},
      listeningContent: {},
      showReading: false,
      showAlert: false,
      username: ''
    }
  },
  computed: {
    ...mapGetters({ userInfo: 'userInfo' }),
    getUser() {
      return this.userInfo
    }
  },
  watch: {
    getUser: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.handleUsername()
      }
    }
  },
  created() {
    this.showAlertHandle()
    this.fetchContent()
    this.findUpdateLog()
    this.fetchReading()
    this.fetchListeing()
    this.handleUsername()
  },
  methods: {
    handleUsername() {
      var _userInfo = this.userInfo
      if (_userInfo === undefined || JSON.stringify(_userInfo) === '{}') {
        this.username = ''
        return
      }
      this.username =
        this.userInfo.username.length === 11
          ? this.geTel(this.userInfo.username)
          : this.userInfo.username
    },
    showAlertHandle() {
      getObj('HOME_SHOW_ALERT_KEY').then((res) => {
        if (res.data.data === '1') {
          ElMessageBox.alert(
            '<p>系统升级维护中，请耐心等候...</p> <p>期间为避免数据丢失，请不要进行任何操作！</p>',
            '温馨提示',
            {
              autofocus: false,
              confirmButtonText: 'OK',
              showClose: false,
              showConfirmButton: false,
              dangerouslyUseHTMLString: true,
              callback: (action) => {
                return
              }
            }
          )
        }
      })
    },
    fetchContent() {
      var _id = '1533688115911245825'
      findContent(_id).then((res) => {
        this.content = res.data.data
      })
    },
    findUpdateLog() {
      var _id = '1549943356255211522'
      findContent(_id).then((res) => {
        this.updateLog = res.data.data
      })
    },
    fetchReading() {
      var _id = '1600018245842210817'
      findContent(_id).then((res) => {
        this.readingContent = res.data.data
      })
    },
    fetchListeing() {
      var _id = '1600018185003831297'
      findContent(_id).then((res) => {
        this.listeningContent = res.data.data
      })
    },
    geTel(tel) {
      var reg = /^(\d{3})\d{4}(\d{4})$/
      if (tel !== undefined) {
        return tel.replace(reg, '$1****$2')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.common-layout {
  background-color: #ffffff;
  min-height: 100vh;
  padding: 10px;
}

.common-layout img {
  display: block;
  width: 100%;
  height: auto;
}

.content {
  font-size: 16px;
  line-height: 2em;
  padding: 20px;
}
.content:deep(table) {
  border: 1px solid #cdd0d6;
  td {
    border: 1px solid #cdd0d6;
    padding: 0 5px;
  }
}

.content:deep(img) {
  display: block;
  width: 100%;
  height: auto;
}

.member {
  width: 100%;
  height: 220px;
  background-color: #414042;
  color: #dcb71d;
  margin-bottom: 15px;
}

.member .member-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
}

.member .member-card .card-info {
  display: inline-block;
}

.member .member-card img {
  display: block;
  width: 150px;
  height: auto;
}

.member .member-card .username {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 8px;
}

.member .member-card .member-num {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}

.member .member-card .end-time {
  font-size: 16px;
  margin-bottom: 8px;
}

.member .member-card .end-time span {
  color: #e31837;
}

.member .member-card .member-note {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.qrcode_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.qrcode_container .qrcode_img {
  margin-right: 30px;
}

.qrcode_container .qrcode_img img {
  display: block;
  width: 180px;
  height: auto;
}

.qrcode_container .introduce ol {
  margin: 8px 0 0 0;
  padding: 0;
  padding-inline-start: 20px;
  line-height: 1.5rem;
}

.item {
  margin-top: 16px;
}

.home_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .home_title {
    font-size: 18px;
    font-weight: bolder;
  }

  .home_user_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .username {
      font-weight: bolder;
      margin-left: 5px;
    }
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .action {
    span {
      cursor: pointer;
    }
    span:first-child {
      margin-right: 16px;
    }
    span.active {
      color: #3a71a8;
      font-weight: bolder;
    }
  }
}
</style>
