<template lang="">
  <p class="activity-questions bold">{{ activity.activityType }}</p>
  <p
    v-if="activity.startQuestionNum === activity.endQuestionNum"
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Question {{ activity.startQuestionNum }}</p>
  <p
    v-else
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</p>
  <p
    class="activity-title"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activityTitle"
  />
  <p
    class="activity-note"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activitySubTitle"
  />
  <ul class="questions">
    <li v-for="question in activity.questionList" :key="question.questionNum" style="margin-bottom:20px;">
      <div
        :id="'question_'+question.questionNum"
        class="question-text font-weight-bold"
        :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4', 'highlight needs-focus':currentNum == question.questionNum}"
      >
        <span class="q-number">{{ question.questionNum }}</span> {{ question.questionTitle }}
      </div>
      <hi-radio-group
        v-model="queModels[question.questionNum]"
        :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
        @change="onChange($event, question.questionNum)"
      >
        <hi-radio v-for="answer in JSON.parse(question.questionContent)" :key="answer.index" :label="answer.index" :name="'question'+question.questionNum">
          <strong>{{ answer.index }}</strong> {{ answer.content }}
        </hi-radio>
      </hi-radio-group>
    </li>
  </ul>
</template>
<script>
import { mapGetters } from 'vuex'
import HiRadio from '../radio/Radio'
import HiRadioGroup from '../radio/RadioGroup'
export default {
  name: 'Question0201Component',
  components: { HiRadio, HiRadioGroup },
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: [],
      size: '0'
    }
  },
  computed: {
    ...mapGetters({ fontSize: 'fontSize', listeningAnswer: 'listeningAnswer', currentNum: 'currentQuestionNum' }),
    getFontSize() {
      return this.fontSize
    },
    getCurrentNum() {
      return this.currentNum
    }
  },
  watch: {
    getFontSize: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.size = this.fontSize
      }
    },
    getCurrentNum: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.handleFocus()
      }
    }
  },
  created() {
    var _this = this
    this.listeningAnswer.forEach(element => {
      _this.queModels[element.questionNumber] = element.yourAnswer
    })
  },
  mounted() {
    this.size = this.fontSize
    this.handleFocus()
  },
  methods: {
    onChange(e, questionNum) {
      this.$store.dispatch('setCurrentQuestionNum', questionNum)
      this.$store.dispatch('saveListeningAnswer', {
        'questionNumber': parseInt(questionNum),
        'yourAnswer': e.target.value
      })
    },
    handleFocus() {
      var node = document.getElementById('question_' + this.currentNum)
      if (node) {
        // node.scrollIntoView({
        //   behavior: 'smooth'
        // })
        node.focus()
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.el-radio-group{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.questions{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
ul.questions li{
  flex: 0 0 50%;
  margin: 0 0 20px 0;
}

ul.answers{
  padding-left: 25px;
}

ul.answers li{
  margin-bottom: 5px;
}
</style>
