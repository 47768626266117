<template lang="">
  <div class="answers" v-html="text" />
</template>
<script>
import { compile } from 'vue/dist/vue.esm-bundler.js'
import question from './ReadingQuestion'

export default {
  name: 'ReadingQuestionComponent',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    question
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    activityId: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    return compile(props.text)
  },
  data() {
    return {
      qNum: ''
    }
  },
  methods: {
  }

}
</script>
<style lang="scss" scoped>
  .answer{
    font-size: 14px;
  }
  .answers:deep(div, p){
    line-height: 2rem;
    margin-bottom: 5px;
  }

  .answers:deep(strong){
    font-size: 18px;
  }
 .answers:deep(table div){
    margin-bottom: 5px;
  }
</style>
