<template lang="">
  <component :is="currentComponents" @reading-next-step="handleToNextStep" />
</template>
<script>
import ReadingStartView from './ReadingStartView'
import ReadingTestView from './ReadingTestView'
import ReadingEndView from './ReadingEndView'
export default {
  name: 'ReadingIndexPage',
  components: { ReadingStartView, ReadingTestView, ReadingEndView },
  data() {
    return {
      currentView: 0,
      currentComponents: 'ReadingStartView'
    }
  },
  created() {

  },
  methods: {
    handleToNextStep(val) {
      this.currentComponents = val
    }
  }

}
</script>
<style lang="">

</style>
