<template lang="">
  <div class="common-layout">
    <el-card :body-style="{ padding: '16px'}" style="height:56px;">
      <el-page-header :icon="ArrowLeft" style="display:flex;" @back="onBack">
        <template #content>
          <span class="text-large font-600 mr-3"> {{ state.partName }} </span>
        </template>
      </el-page-header>
    </el-card>
    <div class="lyric-container">
      <el-card :body-style="{ padding: '0' }" style="flex:1; height:calc(100vh - 136px)">
        <el-scrollbar style="height:calc(100vh - 136px)">
          <div class="lyric">
            <LyricComponent
              ref="lyricRef"
              :lyric="lyric"
              :lyric-active-class="'lyric-active'"
              :lyric-center-class="'lyric-center'"
              triangle-width="14px"
              triangle-color="#337ecc"
              center-line-color="#337ecc"
              center-time-color="#337ecc"
              :current-time="currentTime"
              :show-text="showText"
              :show-cn="showCn"
              @change-current-time="handleChangeCurrentTime"
            />
          </div>
        </el-scrollbar>
        <!-- <ul>
            <li v-for="sent in state.sentences" :key="sent.sentenceId">
              <div>
                {{ sent.enText }}
              </div>
              <div>
                {{ sent.cnText }}
              </div>
            </li>
          </ul> -->
      </el-card>
      <el-card :body-style="{ padding: '0' }" class="sent_index">
        <ul class="sent_index_list">
          <li>
            原文: <el-switch v-model="showText" />
          </li>
          <li>
            翻译: <el-switch v-model="showCn" @change="handleShowCn" />
          </li>
        </ul>
      </el-card>
    </div>
    <div class="audio-wrapper">
      <!-- <audio ref="audio" :src="state.audioUrl" controls @timeupdate="timeupdate" /> -->
      <AudioPlayer ref="audioRef" :audios="[state.audioUrl]" :current-time="currentTime" :controls="'noMuted'" @timeupdate="timeupdate" @backward="handleBackward" @forward="handleForward" />
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { fetchSentences } from '@/apis/test'

import { ArrowLeft } from '@element-plus/icons-vue'

import AudioPlayer from '@/components/AudioPlayer/AudioPlayer.vue'
import LyricComponent from './components/LyricComponent'

// const currentSentence = ref(1)
const state = reactive({
  audioUrl: '',
  partName: '',
  sentences: []
})

const showText = ref(false)
const showCn = ref(false)

const route = useRoute()
const partId = route.params.partId

const currentTime = ref(0)

const lyric = computed(() => {
  const result = []
  state.sentences.forEach(e => {
    result.push([Number(e.start) * 1000, e.enText, e.cnText])
  })
  return result
})

const findSentences = () => {
  fetchSentences(partId).then(res => {
    state.audioUrl = res.data.data.audioUrl
    state.partName = res.data.data.partName
    state.sentences = res.data.data.sentences
  })
}

if (partId !== undefined && partId !== '') {
  findSentences()
}

const router = useRouter()
const onBack = () => {
  router.back()
}

const audioRef = ref(null)
const handleChangeCurrentTime = (time) => {
  audioRef.value.changeCurrentTime(time / 1000)
}

const changeCurrentTime = (newTime) => {
  currentTime.value = newTime
}

// 更新播放时间
const timeupdate = (v) => {
  changeCurrentTime(v)
}

const handleShowCn = (v) => {
  console.log(v)
  if (v && !showText.value) {
    showText.value = true
  }
}

const lyricRef = ref(null)
const handleBackward = () => {
  lyricRef.value.handleBackward()
}

const handleForward = () => {
  lyricRef.value.handleForward()
}

</script>
<style lang="scss" scoped>
.common-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
}

.lyric-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
  padding: 8px;
}

.sent_index {
  width: 200px;
  height: calc(100vh - 136px);
  margin-left: 16px;
}

.sent_index_title {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  height: 56px;
  line-height: 56px;
  text-align: center;
  border-bottom: 1px solid #ebecf0;
  border-radius: 4px 4px 0 0;
  background: #fff;
}

ul.sent_index_list {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    height: 56px;
    line-height: 56px;
    border-bottom: 1px dashed #E4E7ED;
    padding-left: 16px;
    text-align: left;
    cursor: pointer;
    position: relative;

    span {
      width: 56px;
    }
  }

  li:after {
    content: "";
    width: 32px;
    height: 32px;
    display: block;
    position: absolute;
    top: 12px;
    right: 4px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
  }

  li.active:after {
    background-image: url('@/assets/audio.gif')
  }
}

.audio-wrapper {
  display: block;
  height: 64px;
  background: #1c2233;
}

.lyric {
  width: 100%;
  color: #1c2233;
  padding: 16px;
  box-sizing: border-box;
  text-align: left;
  height: calc(100vh - 136px);
}

.lyric:deep(.lyric-active) {
  background-color: #ecf5ff;
  border-radius: 4px;

  .en_text {
    color: #409EFF;
  }

  .cn_text {
    color: #409EFF;
  }

  .text_container {
    height: 45px;
    background-color: #ecf5ff;
  }
}

.lyric:deep(.lyric-center) {
  color: red;
}</style>
