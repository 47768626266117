<template lang="">
  <div>
    <ul class="lesson-list">
      <li
        v-for="lesson in state.lessons"
        :key="lesson.courseItemId"
        class="lesson-list-item"
      >
        <template v-if="lesson.courseItemType === 'LESSON'">
          <div class="lesson-list-item-left">
            <span style="margin-right: 8px">
              <el-tag>视频</el-tag>
            </span>
            <span class="lesson-title">
              {{ lesson.title }}
            </span>
            <span class="lesson-duration">
              {{ handleTime(lesson.courseDuration) }}
            </span>
          </div>
          <div class="lesson-list-item-right">
            <font-awesome-icon
              v-if="lesson.isFree === '1' || state.isValid"
              icon="fa-solid fa-circle-play"
              style="color: #409eff; font-size: 16px; cursor: pointer"
              @click="goLesson(lesson.courseItemId)"
            />
            <font-awesome-icon
              v-else
              icon="fa-solid fa-lock"
              style="color: #909399; font-size: 16px"
            />
          </div>
        </template>
        <template v-else>
          <LessonItem
            :lesson="lesson"
            :is-valid="state.isValid"
            :course-id="props.courseId"
          />
        </template>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { reactive, inject, watch, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { findLessons } from '@/apis/course'

import LessonItem from './LessonItem'

const props = defineProps({
  courseId: {
    type: String,
    default: ''
  }
})

const state = reactive({
  lessons: [],
  isValid: false
})
state.isValid = inject('courseValid')

console.log('state.isValid', state.isValid)

watch(
  () => props.courseId,
  (newVal) => {
    fetchLessons()
  }
)

const fetchLessons = async() => {
  var res = await findLessons(props.courseId)
  state.lessons = res.data.data
}
if (props.courseId !== undefined && props.courseId !== '') {
  fetchLessons()
}

const router = useRouter()
const goLesson = (lessonId) => {
  router.push({
    path: '/course/' + props.courseId + '/lesson',
    query: { lessonId: lessonId }
  })
}

const handleTime = (val) => {
  var time = val
  var minutes = Math.floor(time / 60)
  var seconds = Math.ceil(time % 60)

  if (seconds < 10) {
    seconds = '0' + seconds
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }
  return minutes + ':' + seconds
}
</script>
<style lang="scss" scoped>
ul,
li {
  padding: 0;
  margin: 0;
  margin-inline-start: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
ul.lesson-list {
  li.lesson-list-item {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #ebeef5;
  }
}

.lesson-list-item-left {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  span.lesson-title {
    flex: 1;
    color: #222;
  }
  span.lesson-duration {
    font-size: 14px;
    color: #909399;
  }
}
.lesson-list-item-right {
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.free {
  cursor: pointer;
}
</style>
