<template>
  <div class="hi-checkbox-group">
    <slot />
  </div>
</template>

<script>
import { computed, provide, defineComponent } from 'vue'

export default defineComponent({
  name: 'HiCheckboxGroup',
  props: {
    modelValue: Array
  },
  emits: ['change', 'update:modelValue'],
  setup(props, { emit }) {
    // vue3 provide inject computed watch getInstance
    // 将props属性创造出一个新的来交给儿子
    const modelValue = computed(() => props.modelValue)
    const changeEvent = (val) => { // 儿子等会调用这个方法去通知更新
      emit('change', val) // change
      emit('update:modelValue', val) // v-model
    }
    provide('HiCheckboxGroup', {
      name: 'HiCheckboxGroup',
      modelValue,
      changeEvent
    })
  }
})
</script>
<style scoped lang="scss">
.hi-checkbox-group{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-left: 10px;
}

.hi-checkbox-group.small:deep(.one-checkbox){
  font-size: 14px;
}
.hi-checkbox-group.standard:deep(.one-checkbox){
  font-size: 16px;
}
.hi-checkbox-group.large:deep(.one-checkbox){
  font-size: 18px;
}
.hi-checkbox-group.extra_large:deep(.one-checkbox){
  font-size: 20px;
}
</style>
