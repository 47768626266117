<template lang="">
  <div class="top-class">
    <div class="top-bar">
      <div>
        <div class="student">
          <img src="@/assets/images/icons/Icon.svg" alt="">
          <span>{{ username }} | {{ paperTitle }}</span>
        </div>
      </div>
      <div>
        <div class="middle-container">
          <div class="timer">
            <img src="@/assets/images/icons/Clock.svg" alt="">
            <span v-show="isPrecise" class="precise" @click="togglePrecise">
              <span class="minute">{{ minute }}</span>
              <span> : </span>
              <span class="second">{{ second }}</span>
            </span>
            <span v-show="!isPrecise" class="precise" @click="togglePrecise">
              <span>Show timer</span>
            </span>
          </div>
        </div>
      </div>
      <div class="help">
        <el-button
          v-if="isFinishSection"
          type=""
          class="gradient end-button"
          @click="handleFinishSection"
        >Finish section</el-button>
        <el-button
          v-else
          type=""
          class="gradient end-button"
          @click="handleFinishSection"
        >Finish test</el-button>
        <el-button
          type=""
          @click="dialogSettingVisible = true"
        >Setting</el-button>
        <el-button type="" @click="dialogHelpVisible = true">Help</el-button>
        <el-button type="" @click="dialogHideVisible = true">Hide</el-button>
      </div>
    </div>
    <el-dialog
      v-model="dialogSettingVisible"
      title="Setting"
      width="50%"
      align-center
    >
      <p>
        If you wish, you can change the text size to make the test easier to
        read.
      </p>
      <p
        style="
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        "
      >
        <span style="font-weight: bold; margin-right: 10px">Text size:</span>
        <el-radio-group v-model="size" @change="restFontSize">
          <el-radio-button label="-2">Small</el-radio-button>
          <el-radio-button label="0">Standard</el-radio-button>
          <el-radio-button label="2">Large</el-radio-button>
          <el-radio-button label="4">Extra large</el-radio-button>
        </el-radio-group>
      </p>
      <p
        style="
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        "
      >
        <span style="font-weight: bold; margin-right: 10px">Player type:</span>
        <el-radio-group v-model="player" @change="changePlayerType">
          <el-radio-button label="1">Default Player</el-radio-button>
          <el-radio-button label="2">Natvie Player</el-radio-button>
        </el-radio-group>
      </p>
    </el-dialog>
    <el-dialog
      v-model="dialogHelpVisible"
      title="Help"
      width="80%"
      align-center
    >
      <div v-html="content.content" />
    </el-dialog>
    <el-dialog
      v-model="dialogHideVisible"
      width="50%"
      :show-close="false"
      class="hide-class"
      :close-on-click-modal="false"
      align-center
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="test-ended-modal-title" class="modal-title">
              Screen hidden
            </h5>
          </div>
          <div class="modal-body">
            <p>Your answer have been stored.</p>
            <p>
              Please note that the clock is still runing.The time has not been
              paused.
            </p>
            <p>If you wish to leave the room, please tell your invigilator.</p>
            <p>Click the button bellow to go back to your test.</p>
            <el-button
              class="btn btn-primary bc-button"
              @click="dialogHideVisible = false"
            >Resume test</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { findContent } from '@/apis/content'
import { ElMessageBox, ElMessage } from 'element-plus'
import { mapGetters } from 'vuex'

import { saveAnswers } from '@/utils/test'

export default {
  props: {
    testType: {
      type: String,
      default: undefined
    },
    start: {
      type: Boolean,
      default: false
    }
  },
  emits: ['handleFinish', 'update:audioModel'],
  data() {
    return {
      content: {},
      saveInterval: undefined,
      timerInterval: undefined,
      minute: '00',
      second: '00',
      isPrecise: false,
      isFinishSection: true,
      dialogHelpVisible: false,
      dialogHideVisible: false,
      dialogSettingVisible: false,
      size: '0',
      username: 'XXXX XXXXX',
      paperTitle: '123456',
      player: '2'
    }
  },
  computed: {
    ...mapGetters({
      fontSize: 'fontSize',
      playerType: 'playerType',
      paperInfo: 'paperInfo',
      userInfo: 'userInfo'
    }),
    getStart() {
      return this.start
    },
    getUser() {
      return this.userInfo
    },
    getPaper() {
      return this.paperInfo
    },
    getFontSize() {
      return this.fontSize
    },
    getPlayerType() {
      return this.playerType
    }
  },
  watch: {
    getStart: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {}
    },
    getUser: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.handleUsername()
      }
    },
    getPaper: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.paperTitle = this.paperInfo.paperTitle
      }
    },
    getFontSize: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.size = this.fontSize
      }
    },
    getPlayerType: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.player = this.playerType
      }
    }
  },
  mounted() {
    if (this.start) {
      this.startTimer()
    }
    this.isFinishSection =
      this.paperInfo.paperType === '1' && this.testType !== 'Writing'
    this.size = this.fontSize
    this.player = this.playerType
  },
  unmounted() {
    clearInterval(this.timerInterval)
    clearInterval(this.saveInterval)
  },
  created() {
    this.fetchContent()
    this.handleUsername()

    this.paperTitle = this.paperInfo.paperTitle
  },
  methods: {
    togglePrecise() {
      this.isPrecise = !this.isPrecise
    },
    handleFinishSection() {
      var _this = this
      ElMessageBox.confirm(
        'You have selected to end the test, click OK to exit the test or Cancel to return to the ' +
          this.testType +
          ' section.',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(() => {
          _this.doFinish()
        })
        .catch(() => {
          return
        })
    },
    doFinish() {
      if (this.testType === 'Listening') {
        this.$emit('handleFinish', 'ListeningEndView')
      } else if (this.testType === 'Reading') {
        this.$emit('handleFinish', 'ReadingEndView')
      } else if (this.testType === 'Writing') {
        this.$emit('handleFinish', 'WritingEndView')
      }

      clearInterval(this.timerInterval)
      clearInterval(this.saveInterval)
    },

    startTimer() {
      var self = this
      // Get limit in seconds
      var time = 0
      // var time = (5 * 60) + 4;

      // Save answers every 5 seconds
      self.saveInterval = setInterval(function() {
        saveAnswers({})
      }, 10000)

      // Update the count down every 1 second
      self.timerInterval = setInterval(function() {
        // One second has passed
        time = time + 1

        // Time calculations for minutes
        var minutes = Math.ceil(time / 60)

        var preciseMinutes = Math.floor(time / 60)
        if (preciseMinutes < 10) {
          preciseMinutes = '0' + preciseMinutes
        }

        var seconds = time - preciseMinutes * 60
        if (seconds < 10) {
          seconds = '0' + seconds
        }

        // Display the result
        if (minutes <= 1) {
          self.minute = '00'
          self.second = seconds
        } else {
          self.minute = preciseMinutes
          self.second = seconds
        }
      }, 1000)
    },
    fetchContent() {
      var _id = '1509026228716777473'
      findContent(_id).then((res) => {
        this.content = res.data.data
      })
    },
    handleUsername() {
      var _userInfo = this.userInfo
      if (_userInfo === undefined || JSON.stringify(_userInfo) === '{}') {
        this.username = ''
        return
      }
      this.username =
        this.userInfo.username.length === 11
          ? this.geTel(this.userInfo.username)
          : this.userInfo.username
    },
    geTel(tel) {
      var reg = /^(\d{3})\d{4}(\d{4})$/
      if (tel !== undefined) {
        return tel.replace(reg, '$1****$2')
      }
    },
    restFontSize(val) {
      this.$store.dispatch('setFontSize', val)
      ElMessage.success('修改字体大小成功！')
    },
    changePlayerType(val) {
      this.$store.dispatch('changePlayerType', val)
      ElMessage.success('切换播放器成功！')
    }
  }
}
</script>
<style lang="scss" scoped>
.top-bar {
  background-image: -moz-linear-gradient(top, #152531, #434c51, #152531);
  background-image: -webkit-linear-gradient(top, #152531, #434c51, #152531);
  background-image: linear-gradient(to top, #152531, #434c51, #152531);
}

.top-bar {
  height: 66px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top-bar button {
  margin-right: 5px;
  margin-left: 5px;
  height: 36px;
}

.top-bar button.gradient {
  border: none;
  background: linear-gradient(0deg, #3697c4 0%, #b4dbf7 100%);
}

.top-bar img {
  height: 2em;
  padding-right: 10px;
}

.top-bar .student {
  color: #d1f0ce;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.top-bar .student > * {
  display: inline-block;
  text-align: left;
}

.top-bar .middle-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.top-bar .audio-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}

.top-bar .timer {
  color: #fff0bd;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1em;
}

.top-bar .timer.warning {
  color: #ff3333;
}

.top-bar .timer img {
  display: inline-block;
  vertical-align: bottom;
}

.top-bar .timer .time {
  font-size: 1em;
  font-weight: bold;
  margin-right: 5px;
}

.top-bar .timer .precise {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  width: 200px;
  font-size: 1em;
  display: inline-block;
}

.top-bar .help {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.top-bar .help button {
  color: black;
  background-color: #faf6f6;
  border-color: #faf6f6;
}

.top-bar .end-button {
  width: 130px;
}

.top-class:deep(.hide-class .el-dialog__header) {
  padding: 0;
  padding-bottom: 0;
  margin: 0;
}

.top-class:deep(.hide-class .el-dialog__body) {
  padding: 0;
  padding-bottom: 0;
  margin: 0;
}

.top-class:deep(.el-overlay) {
  background-color: rgba($color: #000000, $alpha: 0.9);
}

.modal-dialog {
  width: 100%;
}

// Actual modal
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: transparent;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

// Title text within header
.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-body a {
  max-width: 200px;
}

.btn.bc-button {
  margin: 0 auto;
  display: block;
  background-color: transparent;
  color: #283e53;
  font-weight: bold;
  border: none;
  box-shadow: 0 2px 2px grey;
}
</style>
