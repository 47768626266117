<template lang="">
  <p class="activity-questions bold">{{ activity.activityType }}</p>
  <p v-if="activity.startQuestionNum === activity.endQuestionNum" class="activity-questions bold">Question {{ activity.startQuestionNum }}</p>
  <p v-else class="activity-questions bold">Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</p>
  <p class="activity-title" v-html="activity.activityTitle" />
  <p class="activity-note" v-html="activity.activitySubTitle" />
  <div class="activity-content">
    <fill-blank :text="activity.activityContent" :activity-id="activity.activityId" />
  </div>
</template>
<script>
import FillBlank from './ReadingFillBlanks'
export default {
  name: 'Answer0305Component',
  components: { FillBlank },
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: []
    }
  }
}
</script>
<style lang="scss" scoped>
.activity-questions{
  margin-bottom: 15px;
}

.activity-title{
  margin-bottom: 5px;
}
.activity-content{
  margin-top:20px;
}
.activity-content table{
  border: 1px solid #F3F3F3;
}
</style>
