<template lang="">
  <component :is="currentComponents" @writing-next-step="handleToNextStep" />
</template>
<script>
import WritingStartView from './WritingStartView'
import WritingTestView from './WritingTestView'
import WritingEndView from './WritingEndView'
export default {
  name: 'WritingIndexPage',
  components: { WritingStartView, WritingTestView, WritingEndView },
  data() {
    return {
      currentView: 0,
      currentComponents: 'WritingStartView'
    }
  },
  created() {

  },
  methods: {
    handleToNextStep(val) {
      this.currentComponents = val
    }
  }

}
</script>
<style lang="">

</style>
