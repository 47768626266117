import Es6Promise from 'es6-promise'
Es6Promise.polyfill()

import { createApp } from 'vue'

import 'normalize.css/normalize.css'

import Element from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import 'element-plus/theme-chalk/display.css'

import ContextMenu from 'jquery-contextmenu'
import 'jquery-contextmenu/dist/jquery.contextMenu.min.css'

import '@/styles/index.scss' // global css
import 'animate.css'

import App from './App.vue'
import router from './router'
import store from './store'

import './permission' // permission control

import axios from 'axios'
import VueAxios from 'vue-axios'

import updatePageTags from './utils/updatePageTags'

import HighlightContainer from '@/components/HighlightContainer/index'
import Icon from '@/components/Icon'
import Loading from '@/components/Loading/Loading'

// 页面导出pdf
import { exportPDF } from '@/utils/exportPdf'

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives
})

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faFloppyDisk,
  faUpload,
  faDownload,
  faCartShopping,
  faPenToSquare,
  faCloudArrowUp,
  faXmarkCircle,
  faDesktop,
  faCalendarDays,
  faCrown,
  faFlag,
  faHouseChimney,
  faRightToBracket,
  faTableList,
  faCheck,
  faXmark,
  faClipboardList,
  faSquareCheck,
  faBars,
  faAnglesLeft,
  faVolumeOff,
  faVolumeLow,
  faVolumeHigh,
  faVolumeXmark,
  faRotateLeft,
  faRotateRight,
  faCirclePlay,
  faCirclePause,
  faForward,
  faBackward,
  faFolderOpen,
  faFolderClosed,
  faLock,
  faClapperboard,
  faAngleRight
} from '@fortawesome/free-solid-svg-icons'
import {
  faFlag as faFlagRegular,
  faSquare as faSquareRegular
} from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(
  faFloppyDisk,
  faUpload,
  faDownload,
  faCartShopping,
  faPenToSquare,
  faCloudArrowUp,
  faXmarkCircle,
  faDesktop,
  faCalendarDays,
  faCrown,
  faFlag,
  faFlagRegular,
  faHouseChimney,
  faRightToBracket,
  faTableList,
  faCheck,
  faXmark,
  faClipboardList,
  faSquareRegular,
  faSquareCheck,
  faBars,
  faAnglesLeft,
  faVolumeOff,
  faVolumeLow,
  faVolumeHigh,
  faVolumeXmark,
  faRotateLeft,
  faRotateRight,
  faCirclePlay,
  faCirclePause,
  faForward,
  faBackward,
  faFolderOpen,
  faFolderClosed,
  faLock,
  faClapperboard,
  faAngleRight
)

/* Update route title tags & page meta */
router.beforeEach(updatePageTags)

const customComponents = {
  HighlightContainer,
  FontAwesomeIcon,
  Icon,
  Loading
}

const app = createApp(App)
app.config.unwrapInjectedRef = true
app.config.productionTip = false
Object.keys(customComponents).forEach((item) => {
  app.component(item, customComponents[item])
})

app.config.globalProperties.$exportPdf = exportPDF

// 防止按钮重复点击提交
app.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 5000)
      }
    })
  }
})

app.use(Element)
app.use(ContextMenu)
app.use(VueAxios, axios)
app.use(vuetify)
app.use(router).use(store).mount('#app')
