<template lang="">
  <dl class="part_items">
    <dt>
      <el-row>
        <el-col :span="11"><span class="test_title">{{ testTitle }}</span></el-col>
        <el-col :span="9" class="text-align_center">场景</el-col>
        <el-col :span="4" class="text-align_center">练习答题</el-col>
      </el-row>
    </dt>
    <div
      v-loading="isLoading"
      element-loading-text="Loading..."
      style="height: 256px"
    >
      <dd v-for="part in state.parts" :key="part.partId">
        <el-row>
          <el-col :span="11">{{ part.originCategoryName }} {{ part.typeCategoryName }}</el-col>
          <el-col :span="9" class="text-align_center">{{
            part.scenes || '-'
          }}</el-col>
          <el-col :span="4" class="text-align_center action">
            <router-link to="#">答题</router-link>
            <router-link to="#">详解</router-link>
            <router-link
              class="link"
              :to="`/part/` + part.partId + `/intensive`"
            >精听</router-link>
          </el-col>
        </el-row>
      </dd>
    </div>
  </dl>
</template>
<script setup>
import { ref, reactive, defineProps } from 'vue'
import { findParts } from '@/apis/part'

const current = ref(1)
const size = ref(24)
const total = ref(0)
const isLoading = ref(true)

const props = defineProps({
  testTitle: {
    type: String,
    default: ''
  },
  testCategoryId: {
    type: Number,
    default: undefined
  },
  typeCategoryId: {
    type: Number,
    default: 28
  },
  originCategoryId: {
    type: Number,
    default: 42
  }
})

const testTitle = props.testTitle || ''
const state = reactive({
  parts: []
})

const queryForm = reactive({
  testCategoryId: props.testCategoryId,
  typeCategoryId: props.typeCategoryId,
  originCategoryId: props.originCategoryId,
  status: 0
})
const fetchParts = () => {
  isLoading.value = true
  findParts(current.value, size.value, queryForm).then((res) => {
    state.parts = res.data.data.records
    current.value = res.data.data.current
    total.value = res.data.data.total
    isLoading.value = false
  })
}
fetchParts()
</script>
<style lang="scss" scoped>
ul,
dl {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.text-align_center {
  text-align: center;
}

dl.part_items {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  dt {
    padding: 0 24px;
    height: 56px;
    line-height: 56px;
    color: #303133;
    background-color: #f7f9fc;

    .test_title {
      color: #303133;
      font-size: 18px;
      font-weight: bold;
    }
  }

  dd {
    margin: 0;
    padding: 0 24px;
    height: 64px;
    line-height: 64px;
    color: #333;

    .el-row {
      border-bottom: 1px solid #f7f9fc;
    }
  }

  dd:last-child {
    .el-row {
      border-bottom: none;
    }
  }
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
