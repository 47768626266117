<template lang="">
  <span class="question">{{ questionNum }}</span>
  <span :class="'answer '+(right?'right':'wrong') +(currentNum === parseInt(questionNum) ? ' highlight':'')" @click="changeNum(parseInt(questionNum))">{{ questionAnswer }}</span>
</template>
<script>
export default {
  name: 'LinsteningQuestionInput',
  inject: ['listeningAnswers', 'currentNum', 'changeNum'],
  data() {
    return {
      questionNum: undefined,
      questionAnswer: undefined,
      yourAnswer: undefined,
      right: false
    }
  },
  mounted() {
    var _questionNum = this.$slots.default()[0].children
    _questionNum = _questionNum.replace('【', '')
    _questionNum = _questionNum.replace('】', '')
    this.questionNum = _questionNum

    var result = this.getResult(parseInt(_questionNum))
    this.questionAnswer = result.rightAnswer
    this.yourAnswer = JSON.parse(result.yourAnswer)
    this.right = result.isRight || result.right
  },
  methods: {
    getResult(num) {
      try {
        this.listeningAnswers.value.forEach(e => {
          if (e.questionNum === num) {
            throw e
          }
        })
      } catch (e) {
        return e
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.question{
  font-weight: bold;
  font-size: 16px;
  margin-right: 5px;
  margin-left: 5px;
}
.answer{
  font-weight: bold;
  padding:3px 20px 2px 20px;
  font-size: 14px;
  line-height: 1.5rem;
  border-bottom: 1px dashed #303133;
  cursor: pointer;
}

.answer.right{
  color: #40d039;
}
.answer.right.highlight{
  background-color:  #f0f9eb;
}
.answer.wrong{
  color: #E31837;
}
.answer.wrong.highlight{
  background-color:  #fef0f0;
}
</style>
