import request from '@/utils/request'

// 获取课程列表
export function findList({ first = 0, size = 10 }) {
  var data = { first, size }
  return request({
    url: '/api/course/remote',
    method: 'post',
    data: data
  })
}

// 获取课程信息
export function findCourse(courseId) {
  return request({
    url: '/api/course/' + courseId + '/detail',
    method: 'get'
  })
}

// 获取课程目录
export function findLessons(courseId) {
  return request({
    url: '/api/course/' + courseId + '/lessons',
    method: 'get'
  })
}

// 获取第一节课
export function findFirst(courseId) {
  return request({
    url: '/api/course/' + courseId + '/first',
    method: 'get'
  })
}

// 获取课程权限
export function validCourse(courseId) {
  return request({
    url: '/api/course/' + courseId + '/valid',
    method: 'get'
  })
}

// 获取课时详情
export function findLesson(lessonId) {
  return request({
    url: '/api/lesson/' + lessonId + '/detail',
    method: 'get'
  })
}

// 保存学习记录
export function saveLearningLog(data) {
  return request({
    url: '/api/learning/saveLog',
    method: 'post',
    data: data
  })
}

// 获取全部学习记录
export function findLearned(data) {
  return request({
    url: '/api/learning/learned',
    method: 'post',
    data: data
  })
}

// 获取上次学习记录
export function findLast(data) {
  return request({
    url: '/api/learning/last',
    method: 'post',
    data: data
  })
}
