<template>
  <v-sheet>
    <v-container>
      <h1 class="text-h4 mb-2">{{ content.title }}</h1>
      <h2 class="text-h5">{{ content.subTitle }}</h2>
      <v-divider class="my-4" />
      <div class="text-body-1 content" v-html="content.content" />
    </v-container>
  </v-sheet>
</template>
<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { findContent } from '@/apis/content'

const content = ref({})
const route = useRoute()
const contentId = route.params.contentId
const fetchData = () => {
  findContent(contentId).then((res) => {
    content.value = res.data.data
  })
}
fetchData()
</script>
<style lang="scss" scoped>
.content {
  line-height: 1.75rem;
}

.content:deep(a) {
  text-decoration: underline;
  color: #673ab7;
}
.content:deep(blockquote) {
  background-color: #e8f5e9;
  padding: 8px;
  border-radius: 4px;
  border-left: 6px solid #a5d6a7;
  margin: 4px auto;
}
.content:deep(h2) {
  margin: 16px auto;
  line-height: 1.75rem;
}
.content:deep(h2:before) {
  content: '✥';
  margin-right: 4px;
}
.content:deep(h3) {
  background-color: #f9fbe7;
  font-size: 16px;
  line-height: 1.75rem;
  padding: 8px;
  border-radius: 4px;
  margin: 8px auto;
  font-weight: bolder;
}
.content:deep(ul) {
  display: block;
  list-style: none;
}
.content:deep(ul li) {
  margin: 8px auto;
}
.content:deep(ol) {
  display: block;
  list-style-position: inside;
}

.content:deep(ol li) {
  font-weight: 400;
}
.content:deep(table) {
  border: 1px solid #8bc34a;
  overflow-x: auto;
  margin: 8px auto;
}

.content:deep(table td) {
  border: 1px solid #8bc34a;
  padding: 8px;
}

.content:deep(img) {
  display: block;
  width: 100%;
  height: auto;
}
.content:deep(div.zy) {
  width: 100%;
  overflow: auto;
}

.content:deep(audio) {
  margin: 8px auto;
  width: 100%;
}
</style>
