<template lang="">
  <div class="common-layout">
    <el-card :body-style="{ padding: '16px' }" style="height: 64px">
      <div class="top-bar">
        <el-page-header :icon="ArrowLeft" style="display: flex" @back="onBack">
          <template #content>
            <div
              v-if="state.lesson !== undefined && state.course !== undefined"
            >
              <span class="text-large font-600 mr-3">{{ state.lesson.title }} | {{ state.course.name }}
              </span>
            </div>
            <div v-else>
              <span class="text-large font-600 mr-3"> Loading... </span>
            </div>
          </template>
        </el-page-header>
        <div class="action">
          <el-button
            v-if="!coursePermission"
            type="danger"
            class="end-button"
            plain
            @click.once="showGoods"
          ><font-awesome-icon
            icon="fa-solid fa-cart-shopping"
            style="margin-right: 5px"
          />购买/激活课程</el-button>
        </div>
      </div>
    </el-card>
    <div class="course_container">
      <div
        style="
          flex: 1;
          height: calc(100vh - 64px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
        "
      >
        <template v-if="isValid">
          <v-responsive
            v-if="state.lesson !== undefined"
            :aspect-ratio="16 / 9"
            class="border elevation-4"
          >
            <!--阿里云播放器-->
            <template
              v-if="
                !videoLoading && options.playauth !== '' && options.vid !== ''
              "
            >
              <AliPlayerV3
                ref="playerRef"
                :options="options"
                :css-link="cssLink"
                :script-src="scriptSrc"
                @ended="ended"
              />
            </template>
            <template v-else>
              <div
                class="d-flex flex-column justify-center align-center fill-height bg-black"
              >
                <v-progress-circular
                  :size="64"
                  :width="4"
                  indeterminate
                  color="primary"
                />
              </div>
            </template>
          </v-responsive>
          <v-responsive
            v-else
            :aspect-ratio="16 / 9"
            class="border elevation-4 bg-black"
          >
            <div
              class="d-flex flex-column justify-center align-center fill-height"
            >
              <div class="text-white mb-4">Loading...</div>
            </div>
          </v-responsive>
          <div class="course_tool" />
        </template>
        <template v-else>
          <v-responsive
            :aspect-ratio="16 / 9"
            class="border elevation-4 bg-black"
          >
            <div
              class="d-flex flex-column justify-center align-center fill-height"
            >
              <div class="text-white mb-4">课程权限或已过期</div>
              <v-btn color="primary" @click.once="showGoods">
                购买/激活课程
              </v-btn>
            </div>
          </v-responsive>
        </template>
      </div>
      <div class="lesson_wrapper">
        <el-scrollbar style="height: calc(100vh - 64px)">
          <dl class="lesson-list">
            <template v-for="(lesson, index) in state.lessons">
              <template v-if="lesson.courseItemType === 'LESSON'">
                <dd
                  :key="lesson.courseItemId"
                  class="lesson-list-item"
                  :class="{ curr: lesson.courseItemId === lessonId }"
                  @click="loadLesson(lesson.courseItemId)"
                >
                  <div class="lesson-list-item-left">
                    <span
                      v-if="!coursePermission && lesson.isFree === '1'"
                      class="lesson-start"
                    >
                      <el-tag size="small" type="success">试听</el-tag>
                    </span>
                    <span v-else class="lesson-start">
                      <font-awesome-icon
                        v-if="lesson.courseItemId === lessonId"
                        icon="fa-solid fa-circle-play"
                        color="#fff"
                        style="margin-right: 5px; font-size: 16px"
                      />
                      <font-awesome-icon
                        v-else-if="learned.indexOf(lesson.courseItemId) != -1"
                        icon="fa-solid fa-square-check"
                        color="#409EFF"
                        style="margin-right: 5px; font-size: 16px"
                      />
                    </span>
                    <span class="lesson-title">
                      {{ lesson.title }}
                    </span>
                  </div>
                  <div class="lesson-list-item-right">
                    <span class="lesson-duration">
                      时长:{{ handleTime(lesson.courseDuration) }}
                    </span>
                  </div>
                </dd>
              </template>
              <template v-else>
                <dt :key="lesson.courseItemId" class="chapter-name">
                  <span>章节</span>
                  <span class="chapter-index">{{ index + 1 }}</span>
                  <span style="flex: 1">{{ lesson.title }}</span>
                </dt>
                <dd
                  v-for="child in lesson.children"
                  :key="child.courseItemId"
                  class="lesson-list-item lesson-chapter"
                  :class="{ curr: child.courseItemId === lessonId }"
                  @click="loadLesson(child.courseItemId)"
                >
                  <div class="lesson-list-item-left">
                    <span
                      v-if="!coursePermission && child.isFree === '1'"
                      class="lesson-start"
                    >
                      <el-tag size="small" type="success">试听</el-tag>
                    </span>
                    <span v-else class="lesson-start">
                      <font-awesome-icon
                        v-if="child.courseItemId === lessonId"
                        icon="fa-solid fa-circle-play"
                        color="#fff"
                        style="margin-right: 5px; font-size: 16px"
                      />
                      <font-awesome-icon
                        v-else-if="learned.indexOf(child.courseItemId) != -1"
                        icon="fa-solid fa-square-check"
                        color="#409EFF"
                        style="margin-right: 5px; font-size: 16px"
                      />
                    </span>
                    <span class="lesson-title">
                      {{ child.title }}
                    </span>
                  </div>
                  <div class="lesson-list-item-right">
                    <span class="lesson-duration">{{
                      handleTime(child.courseDuration)
                    }}</span>
                  </div>
                </dd>
              </template>
            </template>
          </dl>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, watch, computed, onMounted, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ArrowLeft } from 'element-plus'
import { AliPlayerV3 } from 'vue-aliplayer-v3'

import {
  findCourse,
  findLessons,
  findLesson,
  validCourse,
  saveLearningLog,
  findLearned,
  findLast,
  findFirst
} from '@/apis/course'

import { getPlayAuth } from '@/apis/aliyun'

const cssLink =
  'https://g.alicdn.com/de/prismplayer/2.15.2/skins/default/aliplayer-min.css'
const scriptSrc = 'https://g.alicdn.com/de/prismplayer/2.15.2/aliplayer-min.js'
const options = reactive({
  isLive: false,
  format: 'm3u8',
  vid: '',
  playauth: '',
  height: '100%',
  width: '100%',
  videoWidth: '100%',
  videoHeight: 'auto',
  autoplay: false,
  useH5Prism: true,
  controlBarVisibility: 'hover'
})
const videoLoading = ref(true)

const state = reactive({
  course: {},
  lesson: {},
  video: undefined,
  lessons: []
})

const playerRef = ref()
const courseId = ref('')
const lessonId = ref('')
const route = useRoute()
courseId.value = route.params.courseId || ''
lessonId.value = route.query.lessonId || ''

// 检查权限
const coursePermission = ref(false)
const learningId = ref(undefined)
const findValid = async() => {
  var res = await validCourse(courseId.value)
  if (res.data.data === null) {
    coursePermission.value = false
  } else {
    coursePermission.value = true
    learningId.value = res.data.data
  }
}
findValid()

const isValid = computed(() => {
  return coursePermission.value || state.lesson.isFree === '1'
})

watch(state.lesson, () => {
  isValid.value = coursePermission.value || state.lesson.isFree === '1'
})

// 获取课时列表
const fetchLessons = async() => {
  var res = await findLessons(courseId.value)
  state.lessons = res.data.data
}

// 获取已学课时
const learned = ref('')
const fetchLearned = async() => {
  var dataForm = {
    learningPlanId: learningId.value,
    courseId: courseId.value
  }
  var res = await findLearned(dataForm)
  learned.value = res.data.data.toString()
}

// 获取上次学习
const lastLesson = ref('')
const fetchLast = async() => {
  var dataForm = {
    learningPlanId: learningId.value,
    courseId: courseId.value
  }
  var res = await findLast(dataForm)
  if (res.data.data !== null) {
    lastLesson.value = res.data.data.lessonId.toString()
  }
}

// 获取本课程第一课时
const firstLesson = ref('')
const fetchFirst = async() => {
  var res = await findFirst(courseId.value)
  if (res.data.data !== null) {
    firstLesson.value = res.data.data.courseItemId.toString()
  }
}

const fetchCourse = async() => {
  var res = await findCourse(courseId.value)
  state.course = res.data.data
}

const fetchData = async() => {
  fetchCourse()
  await findValid()
  await fetchLessons()
  await fetchLearned()
}
fetchData()

// 获取课时信息
const fetchLesson = async(id) => {
  var res = await findLesson(id)
  state.lesson = res.data.data
}

// 加载视频
const loadLesson = async(id) => {
  videoLoading.value = true
  await fetchLesson(id)
  lessonId.value = state.lesson.courseItemId
  if (coursePermission.value || state.lesson.isFree === '1') {
    options.vid = state.lesson.objectId
    var res2 = await getPlayAuth(state.lesson.objectId)
    options.playauth = res2.data.data

    if (coursePermission.value) {
      await saveLearning()
      await fetchLearned()
    }
  }
  videoLoading.value = false
}

const handleLoadLesson = async() => {
  await fetchLast()
  if (lastLesson.value !== '' && lastLesson.value !== undefined) {
    lessonId.value = lastLesson.value
  } else {
    await fetchFirst()
    lessonId.value = firstLesson.value
  }
  await loadLesson(lessonId.value)
}

onMounted(async() => {
  await handleLoadLesson()
  handleSaveLearning()
})

watch(
  () => options.playauth,
  () => {
    videoLoading.value = !videoLoading.value
    setTimeout(() => {
      // 设置延迟执行
      videoLoading.value = !videoLoading.value
    }, 1)
  }
)

// 保存学习记录
const saveLearning = async(duration) => {
  var dataForm = {
    learningPlanId: learningId.value,
    courseId: courseId.value,
    missionId: lessonId.value,
    duration: duration
  }
  await saveLearningLog(dataForm)
}

const saveInterval = ref(null)
const handleSaveLearning = () => {
  if (coursePermission) {
    saveInterval.value = setInterval(async function() {
      var currTime = Math.floor(playerRef.value.getCurrentTime() * 100) / 100
      if (currTime === 0) {
        return
      }
      await saveLearning(currTime)
    }, 30000)
  }
}

const ended = () => {
  clearInterval(saveInterval.value)
}

const router = useRouter()
const onBack = () => {
  router.back()
}

// const goLesson = (lessonId) => {
//   loadLesson(lessonId)
// }

const showGoods = () => {}
const handleTime = (val) => {
  var time = val
  var minutes = Math.floor(time / 60)
  var seconds = Math.ceil(time % 60)

  if (seconds < 10) {
    seconds = '0' + seconds
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }
  return minutes + ':' + seconds
}

onUnmounted(() => {
  clearInterval(saveInterval.value)
})
</script>
<style lang="scss" scoped>
.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top-bar .student {
  color: #414042;
}

.top-bar .middle-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.top-bar .timer {
  color: #414042;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: bold;
}

.top-bar .help {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.top-class:deep(.hide-class .el-dialog__header) {
  padding: 0;
  padding-bottom: 0;
  margin: 0;
}

.top-class:deep(.hide-class .el-dialog__body) {
  padding: 0;
  padding-bottom: 0;
  margin: 0;
}

.top-class:deep(.el-overlay) {
  background-color: rgba($color: #000000, $alpha: 0.9);
}

.modal-dialog {
  width: 100%;
}

// Actual modal
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: transparent;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

// Title text within header
.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-body a {
  max-width: 200px;
}

.btn.bc-button {
  margin: 0 auto;
  display: block;
  background-color: transparent;
  color: #283e53;
  font-weight: bold;
  border: none;
  box-shadow: 0 2px 2px grey;
}

.course_container {
  // background-color: #000000;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.video_wrapper {
  flex: 1;
  background-color: #000000;
  height: calc(100vh - 109px);
}

.course_tool {
  height: 45px;
  background-color: #222;
}

.lesson_wrapper {
  width: 400px;
  background-color: #e6e8eb;
}

.loading-container {
  flex: 1;
  height: calc(100vh - 109px);
}

dl,
dt,
dd {
  padding: 0;
  margin: 0;
  margin-inline-start: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

dl.lesson-list {
  dt.chapter-name {
    width: 100%;
    color: #606266;
    font-size: 16px;
    font-weight: bold;
    margin: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .chapter-index {
      width: 24px;
      height: 24px;
      font-size: 14px;
      border-radius: 50%;
      margin: 0 10px;
      border: 1px solid #606266;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  dd.lesson-list-item {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px dashed #363637;

    .lesson-list-item-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 4px;

      span.lesson-index {
        display: block;
      }

      span.lesson-title {
        font-size: 16px;
        color: #202121;
        margin: 0 4px;
      }
    }

    .lesson-list-item-right {
      .lesson-duration {
        padding-left: 4px;
        color: #6b6d71;
      }
    }
  }

  dd.lesson-list-item:hover {
    background-color: #3a3e4a;

    .lesson-list-item-left {
      span.lesson-index {
        color: #fff;
      }

      span.lesson-title {
        color: #fff;
      }
    }

    .lesson-list-item-right {
      span.lesson-duration {
        color: #fff;
      }
    }
  }

  dd.lesson-list-item.lesson-chapter {
    // padding-left: 32px;
  }

  dd.lesson-list-item.curr {
    background-color: #3a3e4a;

    .lesson-list-item-left {
      span.lesson-index {
        color: #fff;
      }

      span.lesson-title {
        color: #fff;
      }
    }

    .lesson-list-item-right {
      span.lesson-duration {
        color: #fff;
      }
    }
  }

  .lesson-list-item-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    color: #606266;

    span.lesson-title {
      font-size: 14px;
    }
  }

  .lesson-list-item-right {
    span.lesson-duration {
      font-size: 14px;
    }
  }
}
</style>
