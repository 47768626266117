<template lang="">
  <div class="common-layout">
    <top-bar :start="true" :task-content="state.answer" :task-title="state.partTitle" :default-duration="defaultDuration" :test-type="'Writing'" @handle-save-draft="handleSaveDraft" @handle-finish="handleFinish" />
    <el-row :gutter="16" style="margin-top:16px;">
      <el-col :span="11">
        <el-card v-if="state.task !== undefined" :body-style="{ padding: '0 0 0 16px'}" style="height:calc(100vh - 114px);">
          <el-scrollbar style="height:calc(100vh - 114px); padding: 16px 16px 16px 0;">
            <template v-if="state.typeId === '38'">
              <h2 class="task_name">Task 1</h2>
            </template>
            <template v-else>
              <h2 class="task_name">Task 2</h2>
            </template>
            <div class="task_content" v-html="state.task.activityContent" />
          </el-scrollbar>
        </el-card>
        <el-card v-else style="height:calc(100vh - 122px);">
          Loading...
        </el-card>
      </el-col>
      <el-col :span="13">
        <el-card :body-style="{ padding: '16px'}" style="height:calc(100vh - 114px);">
          <TaskTextArea :task="logForm.taskContent" :task-type="state.typeId === '38' ? 'Task1':'Task2'" @on-input="onHandleAnswer" />
        </el-card>
      </el-col>
    </el-row>
    <el-dialog v-model="dialogFormVisible" width="60%" title="提交作文">
      <div class="date_title">请预约批改时间</div>
      <div class="date_subtitle">批改时间为最迟获得批改结果的时间，可预约5天之内的批改时间，每天0点开放新的可预约时间!</div>
      <ul class="dates">
        <li v-for="date in state.dates" :key="date.logDate">
          <template v-if="date.logDate >='2023-01-21' && date.logDate <='2023-01-29'">
            <div class="date disable">
              <span>{{ date.logDate }} </span>
              <span>暂停批改</span>
            </div>
          </template>
          <telmplate v-else>
            <div v-if="date.logCount > 0" class="date" :class="{'active': curDate === date.logDate}" @click="handleSelectDate(date.logDate)">
              <span>{{ date.logDate }} </span>
              <span>剩余可预约：<strong>{{ date.logCount }}</strong></span>
            </div>
            <div v-else class="date disable">
              <span>{{ date.logDate }} </span>
              <span>剩余可预约：<strong>{{ date.logCount }}</strong></span>
            </div>
          </telmplate>
        </li>
      </ul>
      <template #footer>
        <span class="dialog-footer">
          <el-button plain @click="dialogFormVisible = false">
            <font-awesome-icon icon="fa-solid fa-circle-xmark" style="margin-right:5px;" /> 取消
          </el-button>
          <el-button type="danger" plain @click="doFinish">
            <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" style="margin-right:5px;" /> 提交作文
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, onUnmounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ElMessage, ElMessageBox } from 'element-plus'

import { findPartInfo } from '@/apis/part'
import { findWritingLog, saveWritingLog, findWrtingLogDates } from '@/apis/member'

import TopBar from './components/TopBar'
import TaskTextArea from './components/TaskTextArea'

const dialogFormVisible = ref(false)

const state = reactive({
  task: undefined,
  partTitle: undefined,
  partId: undefined,
  typeId: undefined,
  answer: '',
  dates: []
})

const logForm = reactive({
  writingLogId: undefined,
  testPartId: undefined,
  testPartNum: undefined,
  testNote: undefined,
  testContent: undefined || '',
  taskType: undefined,
  taskContent: undefined,
  taskAttachment: undefined,
  taskNote: undefined,
  taskDuration: 0,
  deadline: undefined,
  status: '0',
  project: 'IELTS_TEST'
})

const store = useStore()

const route = useRoute()
const partId = route.query.partId || ''
state.partId = partId
const logId = route.query.logId || ''

const router = useRouter()

const defaultDuration = ref(0)
const curDate = ref(undefined)

onMounted(() => {
  // 监听浏览器返回
  window.addEventListener('popstate', goBack, false)

  nextTick(() => {
    // 禁用右键
    document.oncontextmenu = new Function('event.returnValue=false')
    // 禁用选择
    document.onselectstart = new Function('event.returnValue=false')
  })
})

onUnmounted(() => {
  // 取消禁用右键
  document.oncontextmenu = new Function('event.returnValue=true')
  // 取消禁用选择
  document.onselectstart = new Function('event.returnValue=true')

  window.removeEventListener('popstate', goBack)
})

const goBack = () => {
  ElMessageBox.confirm('确认结束本次作文写作？', '提示',
    {
      type: 'warning',
      confirmButtonText: '结束写作',
      cancelButtonText: '继续写作'
    }).then(() => {
    router.back()
  }).catch((action) => {
    if (action === 'cancel') {
      history.pushState(null, null, document.URL)
    }
  })
}

const fetchWritingLog = () => {
  findWritingLog(logId).then(res => {
    var _data = res.data.data
    logForm.writingLogId = _data.writingLogId
    logForm.testPartId = _data.testPartId
    logForm.testPartNum = _data.testPartNum
    logForm.testNote = _data.testNote
    logForm.testContent = _data.testContent
    logForm.taskType = _data.taskType
    logForm.taskContent = _data.taskContent
    logForm.taskNote = _data.taskNote
    logForm.taskDuration = _data.taskDuration
    logForm.status = _data.status
    defaultDuration.value = parseInt(_data.taskDuration || 0)
  })
}

if (logId !== undefined && logId !== '') {
  fetchWritingLog()
}

const findPart = () => {
  findPartInfo(state.partId).then(res => {
    var _data = res.data.data
    state.task = _data.activityList[0]
    state.typeId = _data.typeCategoryId
    state.partTitle = _data.partTitle

    logForm.testPartId = _data.partId
    logForm.testPartNum = _data.partNum
    logForm.testNote = _data.partTitle
    logForm.testContent = _data.activityList[0].activityContent
    logForm.taskType = _data.typeCategoryId === '38' ? 'Task1' : 'Task2'
  })
}
findPart()

const onHandleAnswer = (e) => {
  state.answer = e
  logForm.taskContent = e
}

const handleFinish = (time) => {
  if (logForm.taskContent === undefined || logForm.taskContent.length === 0) {
    ElMessage.error('提交的内容不能为空!')
    return
  }
  dialogFormVisible.value = true
  logForm.taskDuration = time
}

const doFinish = () => {
  if (curDate.value === undefined || curDate.value === '') {
    ElMessage.error('请选择预约批改时间！')
    return
  }
  ElMessageBox.confirm(
    '确认提交作文么？本次提交将扣除 1 个批改额度。',
    '温馨提示',
    {
      confirmButtonText: '确认提交',
      cancelButtonText: '再看看',
      type: 'warning'
    }
  )
    .then(() => {
      logForm.status = '1'
      logForm.deadline = curDate.value + ' 23:59:59'
      saveLog(logForm).then(res => {
        logForm.writingLogId = res.data.data.writingLogId
        ElMessage.success('提交作文成功，等待批改中!')
        store.dispatch('getWritingInfo')
        dialogFormVisible.value = false
        router.push({ path: '/writing', replace: true })
      })
    })
    .catch(() => {
      return
    })
}

const handleSaveDraft = (time) => {
  if (logForm.taskContent === undefined || logForm.taskContent.length === 0) {
    ElMessage.error('保存的内容不能为空!')
    return
  }
  logForm.status = '0'
  logForm.taskDuration = time
  logForm.deadline = undefined
  saveLog(logForm).then(res => {
    logForm.writingLogId = res.data.data.writingLogId
    ElMessage.success('保存草稿成功!')
  })
}

const saveLog = (form) => {
  return saveWritingLog(form)
}

const fetchDates = () => {
  findWrtingLogDates().then(res => {
    state.dates = res.data.data
  })
}
fetchDates()

const handleSelectDate = (v) => {
  curDate.value = v
}

</script>
<style lang="scss" scoped>
.common-layout{
  background-color: #FFFFFF;
  min-height: 100vh;
  padding: 16px;
}

.task_name{
  margin: 0 0 8px 0;
}

.task_note{
  line-height: 1.5rem;
}

.task_content{
  font-size: 16px;
  line-height: 1.5rem;
  text-align: justify;
}

.task_content:deep(img){
  display: inline-block;
  width: 100%;
  height: auto;
}

.date_title{
  font-weight: bold;
  font-size: 18px;
  color: #303133;
  margin-bottom: 8px;
}
.date_subtitle{
  font-size: 14px;
  color: #909399;
  margin-bottom: 16px;
}
ul.dates{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style:none;
  li{
    margin-right: 16px;
    div.date{
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color:   #fef0f0;
      color: #F56C6C;
      border: 1px solid #F56C6C;
      border-radius: 4px;
      padding: 4px 16px;
      cursor: pointer;
      span:first-child{
        margin-bottom: 4px;
      }
      span strong{
        font-size: 16px;
      }
    }
    div.date:hover, div.date.active{
      background-color:#F56C6C;
      color:    #fef0f0;
    }
    div.date.disable{
      background-color:  #f4f4f5;
      color: #909399;
      border-color: #909399;
      cursor:auto;
    }
  }
}

</style>
