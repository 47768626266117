import request from '@/utils/request'

// 获取试卷信息
export function findPaper(paperId) {
  return request({
    url: '/api/paper/' + paperId,
    method: 'get'
  })
}

// 获取试卷列表
export function findList(data) {
  return request({
    url: '/api/paper/remote',
    method: 'post',
    data: data
  })
}

// 获取试卷数量
export function findPapersCount(data) {
  return request({
    url: '/api/paper/remote',
    method: 'post',
    data: data
  })
}
