<template lang="">
  <el-card :body-style="{ padding: '0'}" class="writing_new">
    <template #header>
      <div class="card-header">
        <span>所有批改</span>
        <router-link :to="'/writing/test'">
          <el-button type="primary" plain class="button"><font-awesome-icon icon="fa-solid fa-pen-to-square" style="margin-right:5px;" />我要批改</el-button>
        </router-link>
      </div>
    </template>
    <el-table v-loading="dataLoading" :data="tableData" stripe size="large" style="height:calc(100vh - 170px);">
      <el-table-column prop="testNote" label="题目" align="left">
        <template #default="scope">
          <router-link class="link" :to="`/writing/result/`+scope.row.writingLogId">{{ scope.row.testNote }}</router-link>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" width="160" align="center">
        <template #default="scope">
          {{ formatDate(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="进度" width="90" align="center">
        <template #default="scope">
          <el-tag :type="fiterStatusTagType(scope.row.status)">
            {{ fiterStatus(scope.row.status) }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="total, prev, pager, next" :current-page="queryForm.current" :page-size="queryForm.size" :default-page-size="queryForm.size" :total="total" style="float:right; margin: 16px 16px 0 0;" @current-change="currentChangeHandle" />
  </el-card>
</template>
<script setup>
import { ref, reactive } from 'vue'

import moment from 'moment'

import { findWrtingLogList } from '@/apis/member'

const dataLoading = ref(false)
const queryForm = reactive({
  current: 1,
  size: 20,
  status: ''
})
const total = ref(0)
const tableData = ref([])
const fetchLogList = () => {
  dataLoading.value = true
  findWrtingLogList(queryForm).then(res => {
    tableData.value = res.data.data.records
    queryForm.current = res.data.data.current
    total.value = res.data.data.total
    dataLoading.value = false
  }).catch(e => {
    dataLoading.value = false
  })
}
fetchLogList()

const currentChangeHandle = (val) => {
  queryForm.current = val
  fetchLogList()
}

const fiterStatus = (v) => {
  switch (v) {
    case '0':
      return '草稿'
    case '1':
      return '待批改'
    case '2':
      return '批改中'
    case '3':
      return '已批改'
    default:
      return '草稿'
  }
}

const fiterStatusTagType = (v) => {
  switch (v) {
    case '0':
      return 'info'
    case '1':
      return 'warning'
    case '2':
      return ''
    case '3':
      return 'success'
    default:
      return 'info'
  }
}

const formatDate = (v) => {
  return moment(v).format('yyyy-MM-DD HH:mm')
}

</script>
<style lang="scss" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul.log_list{
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.log_list li{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}
a.link{
  color: #409EFF;
}
a.link:hover{
  text-decoration:underline;
}
</style>
