<template>
  <el-form
    ref="registerForm"
    class="login-form"
    status-icon
    :rules="registerRules"
    :model="registerForm"
    label-width="0"
  >
    <el-form-item prop="username">
      <el-input
        v-model="registerForm.username"
        placeholder="请输入用户名"
        auto-complete="off"
        @keyup.enter="handleRegister"
      >
        <template #prefix>
          <el-icon class="el-input__icon"><User /></el-icon>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="registerForm.password"
        placeholder="请输入密码"
        auto-complete="off"
        :type="passwordType"
        @keyup.enter="handleRegister"
      >
        <template #prefix>
          <el-icon class="el-input__icon"><Lock /></el-icon>
        </template>
        <template #suffix>
          <el-icon v-if="passwordType==='password'" class="el-input__icon" @click="showPassword"><Hide /></el-icon>
          <el-icon v-else class="el-input__icon" @click="showPassword"><View /></el-icon>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="phone">
      <el-input
        v-model="registerForm.phone"
        placeholder="请输入手机号"
        auto-complete="off"
        @keyup.enter="handleRegister"
      >
        <template #prefix>
          <el-icon class="el-input__icon"><Phone /></el-icon>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item>
      <div class="terms">
        <el-checkbox v-model="acceptTerms" /> I agree to the <el-button type="primary" text @click="showContent('1505780345866354690')">terms of use</el-button> and <el-button type="primary" text @click="showContent('1505780504507514881')">privacy policy</el-button> of this test.
      </div>
      <el-button
        type="primary"
        style="width:100%; margin-bottom:30px;"
        class="login-submit"
        @click.prevent="handleRegister"
      >注册
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { ElMessage } from 'element-plus'
import { User, View, Lock, Hide, Phone } from '@element-plus/icons-vue'
import { registerUser } from '@/apis/login'
import { mapGetters } from 'vuex'
import { checkPassword, checkPhone, validateUsername } from '@/const/crud/user'

export default {
  name: 'UserRegister',
  components: { User, View, Lock, Hide, Phone },
  data() {
    return {
      registerForm: {
        username: '',
        password: '',
        phone: ''
      },
      checked: false,
      acceptTerms: false,
      registerRules: {
        username: [
          {
            min: 6,
            max: 20,
            message: '长度在 6 到 20 个字符',
            trigger: 'blur'
          },
          {
            validator: validateUsername,
            trigger: 'blur'
          }
        ],
        password: [
          {
            validator: checkPassword,
            trigger: 'blur'
          }
        ],
        phone: [
          {
            validator: checkPhone,
            trigger: 'blur'
          }
        ]
      },
      passwordType: 'password'
    }
  },
  computed: {
    ...mapGetters(['tagWel'])
  },
  created() {
  },
  methods: {
    showPassword() {
      this.passwordType === ''
        ? (this.passwordType = 'password')
        : (this.passwordType = '')
    },
    handleRegister() {
      if (!this.acceptTerms) {
        ElMessage({
          message: 'You must accept terms and privacy to continue!',
          type: 'warning'
        })
        return
      }
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          registerUser(this.registerForm)
            .then(() => {
              this.$message.success('注册成功!')
              this.$emit('ok')
            })
        }
      })
    }
  }
}
</script>

<style></style>
