<template lang="">
  <div class="content-container">
    <h1 class="title">{{ content.title }}</h1>
    <el-divider />
    <div class="content" v-html="content.content" />
  </div>
</template>
<script>
import { findContent } from '@/apis/content'

export default {
  name: 'ContentPage',
  data() {
    return {
      content: {}
    }
  },
  created() {
    var _contetId = this.$route.params.contentId
    this.getObj(_contetId)
  },
  methods: {
    getObj(contentId) {
      findContent(contentId).then(res => {
        this.content = res.data.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content-container{
  padding: 30px 100px;
  height: 100vh;
  background-color: white;
}

.content-container .content p{
  line-height: 1.5em;
}
</style>
