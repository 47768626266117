import request from '@/utils/request'

// 获取题库详情
export function findTest(testId) {
  return request({
    url: '/api/speaking/test/' + testId + '/detail',
    method: 'get'
  })
}

// 获取题库权限
export function validTest(testId) {
  return request({
    url: '/api/speaking/user/' + testId + '/valid',
    method: 'get'
  })
}

// 获取话题
export function findTopic(topicId) {
  return request({
    url: '/api/speaking/topic/' + topicId + '/detail',
    method: 'get'
  })
}

// 获取话题列表
export function findTopicList(data) {
  return request({
    url: '/api/speaking/topic/list',
    method: 'post',
    data: data
  })
}

// 获取免费问题列表
export function findFreeQuestions(topicId) {
  return request({
    url: '/api/speaking/question/' + topicId + '/free',
    method: 'get'
  })
}

// 获取问题列表
export function findQuestions(topicId) {
  return request({
    url: '/api/speaking/question/' + topicId + '/list',
    method: 'get'
  })
}

// 获取问题详情
export function findQuestion(questionId) {
  return request({
    url: '/api/speaking/question/' + questionId + '/detail',
    method: 'get'
  })
}

// 获取口语日志
export function findSpeakingLogs(questionId) {
  return request({
    url: '/api/speaking/log/' + questionId + '/list',
    method: 'get'
  })
}

// 保存口语日志
export function saveSpeakingLog(data) {
  return request({
    url: '/api/speaking/log/saveLog',
    method: 'post',
    data: data
  })
}
