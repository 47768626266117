<template lang="">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="test-ended-modal-title" class="modal-title">Test ended</h5>
      </div>
      <div class="modal-body">
        <p>Your test has finished.</p>
        <p>All of your answers have been stored.</p>
        <p>Please wait for further instructions.</p>
        <el-button class="btn btn-primary bc-button" @click="handleContinue">Continue</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { saveAnswers } from '@/utils/test'

export default {
  name: 'ReadingEndPage',
  emits: ['nextStep'],
  data() {
    return {
      autoTimeout: undefined
    }
  },
  computed: {
    ...mapGetters({ paper: 'paperInfo', types: 'testTypes' })
  },
  created() {
    this.autoContinue()
  },
  methods: {
    checkTypes(e) {
      if (this.types === undefined || this.types.length <= 0) {
        return false
      }
      try {
        this.types.forEach(element => {
          if (element === e) {
            throw e
          }
        })
      } catch (e) {
        return e
      }
      return false
    },
    autoContinue() {
      var _this = this
      this.autoTimeout = setTimeout(function() {
        _this.handleContinue()
      }, 5000)
    },
    handleContinue() {
      if (this.paper.paperType === '1') {
        if (this.checkTypes('writing')) {
          this.$emit('nextStep', 'WritingIndexView')
        } else {
          this.handleSave('/paper')
        }
      } else {
        this.handleSave('/paper')
      }
    },
    handleSave(direct) {
      var _this = this
      saveAnswers({ finish: '1' }).then(res => {
        clearTimeout(this.autoTimeout)
        _this.$router.replace({ name: 'TestResultView', params: { 'logId': res }, query: { 'direct': direct }})
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-dialog {
  width: 700px;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-350px,-150px);
}
// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  outline: 0;
  background-color: transparent;
  border-radius: 10px;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: black;
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

// Title text within header
.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-body a {
  max-width: 200px;
}

.btn.bc-button {
    margin: 0 auto;
    display: block;
    background-color: transparent;
    color: #283e53;
    font-weight: bold;
    border: none;
    box-shadow: 0 2px 2px grey;
}
</style>
