import Layout from '@/layout'
import CommonLayout from '@/layout/Layout'

import HomeView from '@/views/home/index'
import SignInView from '@/views/sign_in/index'
import PaperView from '@/views/paper/PaperView'
import PaperHistoryView from '@/views/paper/PaperHistoryView'
import ContentView from '@/views/content/index'
import SpeakView from '@/views/speak/SpeakView'
import FeedbackView from '@/views/feedback/FeedbackView'
import WritingView from '@/views/writing/WritingView'
import WritingTestView from '@/views/writing/WritingTestView'
import WritingInfoView from '@/views/writing/WritingInfoView'
import WritingFreeView from '@/views/writing/WritingFreeView'
import WritingResultView from '@/views/writing/WritingResultView'
import ListeningPartView from '@/views/part/ListeningPartView'
import ListeningIntensiveView from '@/views/part/ListeningIntensiveView'
import AnalyzeIndex from '@/views/AnalyzeIndex'

export default [
  {
    path: '/',
    component: Layout,
    redirect: '/',
    children: [
      {
        path: '',
        name: 'HomeView',
        component: HomeView
      }
    ]
  },
  {
    path: '/login',
    name: 'SignInView',
    component: SignInView
  },
  {
    path: '/analyze',
    name: 'AnalyzeIndex',
    component: AnalyzeIndex
  },
  {
    path: '/paper',
    component: Layout,
    redirect: '/paper',
    children: [
      {
        path: '',
        name: 'PaperView',
        component: PaperView
      },
      {
        path: 'history',
        name: 'PaperHistoryView',
        component: PaperHistoryView
      }
    ]
  },
  {
    path: '/post/:contentId',
    name: 'ContentView',
    component: ContentView,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/writing',
    component: Layout,
    redirect: '/writing',
    children: [
      {
        path: '',
        name: 'WritingView',
        component: WritingView
      },
      {
        path: 'test',
        name: 'WritingTestView',
        component: WritingTestView
      },
      {
        path: 'info',
        component: CommonLayout,
        redirect: '/writing/info',
        children: [
          {
            path: '',
            name: 'WritingInfoView',
            component: WritingInfoView
          }
        ]
      },
      {
        path: 'free',
        component: CommonLayout,
        redirect: '/writing/free',
        children: [
          {
            path: '',
            name: 'WritingFreeView',
            component: WritingFreeView
          }
        ]
      },
      {
        path: 'result',
        component: CommonLayout,
        redirect: '/writing/result/:logId',
        children: [
          {
            path: ':logId',
            name: 'WritingResultView',
            component: WritingResultView
          }
        ]
      }
    ]
  },
  {
    path: '/feedback',
    component: Layout,
    redirect: '/feedback',
    children: [
      {
        path: '',
        name: 'FeedbackView',
        component: FeedbackView
      }
    ]
  },
  {
    path: '/speak',
    component: Layout,
    redirect: '/speak',
    children: [
      {
        path: '',
        name: 'SpeakView',
        component: SpeakView
      }
    ]
  },
  {
    path: '/part',
    component: Layout,
    redirect: '/part/listening',
    children: [
      {
        path: 'listening',
        name: 'ListeningPartView',
        component: ListeningPartView
      },
      {
        path: ':partId/intensive',
        name: 'ListeningIntensiveView',
        component: ListeningIntensiveView
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
  }
]
