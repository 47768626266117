<template lang="">
  <div class="d-flex justify-space-between align-start mb-8">
    <v-avatar>
      <v-img
        src="https://cdn.vuetifyjs.com/images/lists/4.jpg"
        alt="Basic Edition"
      />
    </v-avatar>
    <div
      class="flex-grow-1 d-flex flex-column justify-start align-stretch ml-2"
    >
      <span class="font-weight-bold">Basic Edition</span>
      <div
        class="my-2 pa-4 audio-text bg-indigo-lighten-5 rounded-xl rounded-ts-0"
        style="max-width: 80%"
      >
        <div class="question-content" v-html="currentChat.content" />
        <div>
          <v-btn
            variant="tonal"
            size="small"
            prepend-icon="mdi-microphone"
            color="indigo"
            class="mt-2 mr-2"
            @click="handleRecord(0)"
          >跟读</v-btn>
          <v-btn
            variant="tonal"
            size="small"
            prepend-icon="mdi-microphone-variant"
            color="deep-orange"
            class="mt-2"
            @click="handleRecord(1)"
          >我的答案</v-btn>
        </div>
      </div>
      <div
        v-if="
          currentChat.audio !== null &&
            currentChat.audio !== undefined &&
            currentChat.audio !== ''
        "
        class="my-2"
        style="width: 60%"
      >
        <AudioPlayer
          ref="answerAudioRef"
          :auto-play="false"
          :audios="[currentChat.audio]"
          :controls="'noMuted noBackward noForward noBackward5 noForward5 noVolume'"
          b-color="#E8EAF6"
          t-color="#3F51B5"
          thumb-color="#3F51B5"
          custom-class="pa-4 rounded-xl rounded-ts-0"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue'

import AudioPlayer from '@/components/AudioPlayer/AudioPlayer'

const emits = defineEmits(['record'])
const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const answerAudioRef = ref()
const currentChat = ref(props.item)
watch(
  () => props.item,
  () => {
    currentChat.value = props.item
    if (
      props.item.audio !== undefined &&
      props.item.audio !== null &&
      props.item.audio !== ''
    ) {
      answerAudioRef.value.src = props.item.audio
    }
  }
)

const handleRecord = (t) => {
  emits('record', currentChat.value.content, t)
}
</script>
<style lang="scss" scoped>
.audio-text {
  font-size: 16px;
  line-height: 1.75rem;
}
.question-content {
  :deep(p) {
    margin: 0 0 8px 0;
    padding: 0;
  }
  :deep(div) {
    margin: 0 0 8px 0;
    padding: 0;
  }
}
</style>
