import Layout from '@/layout'
import LessonLayout from '@/layout/Layout'

import CourseHome from '@/views/course/CourseHome'
import CourseDetail from '@/views/course/CourseDetail'
import LessonPlay from '@/views/course/LessonPlay'

export default [
  {
    path: '/course',
    component: Layout,
    redirect: '/course',
    children: [
      {
        path: '',
        name: 'CourseHome',
        component: CourseHome
      },
      {
        path: ':courseId/detail',
        name: 'CourseDetail',
        component: CourseDetail
      }
    ]
  },
  {
    path: '/course/:courseId/lesson',
    component: LessonLayout,
    redirect: '/',
    children: [
      {
        path: '',
        name: 'LessonPlay',
        component: LessonPlay
      }
    ]
  }
]
