<template lang="">
  <el-container class="on-test">
    <el-header>
      <top-bar />
    </el-header>
    <el-main class="app-main">
      <section class="app-main">
        <div class="step-one">
          <div class="student-details">
            <img src="@/assets/images/userCheck.png" alt="">
            <p class="bc-box-title">Confirm your details</p>
            <div class="bc-box">
              <h3 style="color: red; text-align: center;">EXAMPLE</h3>
              <ul>
                <li>Name:</li>
                <li>Date of birth: XX-XX-XXXX</li>
                <li>Candidate number: XXXX XXXXX - 123456</li>
              </ul>
              <p class="bx-box-info">If your details aren't correct, please inform the invigilator.</p>
              <el-button type="primary" class="btn bc-button pointer" @click="toNext">My details are correct</el-button>
            </div>
          </div>
        </div>
      </section>
      <Note :text="text" />
    </el-main>
  </el-container>
</template>
<script>
import { ElMessageBox } from 'element-plus'
import TopBar from '@/components/TopBar'
import Note from '@/components/Note'
import { findPaper } from '@/apis/paper'
import { fetchUnFinishedTestLogs } from '@/apis/test'
import { loadTestLog } from '@/utils/test'

import { mapGetters } from 'vuex'

export default {
  name: 'TestStartPage',
  components: { Note, TopBar },
  emits: ['nextStep'],
  data() {
    return {
      paperId: undefined,
      paper: {},
      text: 'Please click the “my details are correct” button to progress into the listening familiarisation test, in your real IELTS test there will be an invigilator in the room to advise when to do this.'
    }
  },
  computed: {
    ...mapGetters({ memberInfo: 'memberInfo' }),
    getMember() {
      return this.memberInfo
    }
  },
  watch: {
    getMember: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.handleIsMember()
        this.handlerIsValid()
      }
    }
  },
  created() {
    this.$store.dispatch('clearAnswer')
    var _paperId = this.$route.params.paperId
    this.paperId = _paperId
    this.fetchPaper()
  },
  methods: {
    async fetchPaper() {
      var _res = await findPaper(this.paperId)
      var _paper = _res.data.data
      if (_paper.isFree === '1' || this.handleMember()) {
        this.paper = _paper
        this.$store.dispatch('setPaperInfo', _paper)
      } else {
        this.$router.replace('/paper')
        return
      }

      var _unRes = await fetchUnFinishedTestLogs({
        'type': _res.data.data.paperType,
        'id': this.paperId
      })
      if (_unRes.data.data !== null) {
        ElMessageBox.confirm(
          '本套题你有尚未完成的考试?',
          'Warning',
          {
            confirmButtonText: '继续上次',
            cancelButtonText: '重新开始',
            type: 'warning'
          }
        )
          .then(() => {
            loadTestLog(_unRes.data.data)
            return
          })
          .catch(() => {
            return
          })
      }
    },
    handleMember() {
      var _this = this

      var _isMember = this.handleIsMember()
      if (!_isMember) {
        ElMessageBox.confirm(
          '您还不是会员哦，成为会员?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
          .then(() => {
            _this.$router.replace('/paper')
            return
          })
          .catch(() => {
            _this.$router.replace('/paper')
            return
          })
      } else {
        var isValid = this.handlerIsValid()
        if (!isValid) {
          ElMessageBox.confirm(
            '会员无效或已过有效期，重新激活?',
            'Warning',
            {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }
          )
            .then(() => {
              _this.$router.replace('/paper')
              return
            })
            .catch(() => {
              _this.$router.replace('/paper')
              return
            })
        }
      }
      return true
    },
    handleIsMember() {
      var _member = this.memberInfo
      if (_member !== undefined && JSON.stringify(_member) !== '{}') {
        return true
      }
      return false
    },
    handlerIsValid() {
      var _member = this.memberInfo
      if (_member === undefined || JSON.stringify(_member) === '{}') {
        return false
      }

      var _end = new Date(_member.endTime.replace(/\-/g, '\/'))
      var _now = new Date()
      if (_member.endTime !== undefined &&
      this.compareDate(_end, _now) &&
          _member.status === '0') {
        return true
      } else {
        return false
      }
    },
    compareDate(date1, date2) {
      var oDate1 = new Date(date1)
      var oDate2 = new Date(date2)
      if (oDate1.getTime() > oDate2.getTime()) {
        return true // 第一个大
      } else {
        return false // 第二个大
      }
    },
    toNext() {
      if (this.paper.testCategoryId === '21' || this.paper.testCategoryId === '232' || this.paper.paperType === '1') {
        this.$emit('nextStep', 'ListeningIndexView')
      } else if (this.paper.testCategoryId === '22' || this.paper.testCategoryId === '233') {
        this.$emit('nextStep', 'ReadingIndexView')
      } else if (this.paper.testCategoryId === '150' || this.paper.testCategoryId === '234') {
        this.$emit('nextStep', 'WritingIndexView')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
img {
    vertical-align: middle;
    border-style: none;
}

.student-details, .test-sound {
    width: 100%;
    max-width: 1120px;
    margin: 80px auto 0 auto;
    text-align: left;
}
.bc-box-title {
    display: inline-block;
    font-weight: bold;
}
.bc-box {
    display: block;
    width: 100%;
    max-width: 1120px;
    border: solid 1px white;
    border-radius: 10px;
    padding: 15px;
    margin: 0 auto;
}
.btn.bc-button {
    margin: 0 auto;
    display: block;
    background-color: transparent;
    color: #283e53;
    font-weight: bold;
    border: none;
    box-shadow: 0 2px 2px grey;
}
p.bx-box-info, p.bc-box-attention {
    padding-left: 25px;
    background-position: left 0.15em;
    background-repeat: no-repeat;
}
p.bx-box-info {
    background-image: url('@/assets/images/iconInformation16.png');
}
</style>
