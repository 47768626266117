<template lang="">
  <div class="log-container">
    <el-row>
      <el-col :lg="{ span: 22, offset: 1 }" :md="{ span: 24 }">
        <section style="padding: 15px 15px 0 15px">
          <el-divider>
            <h2 style="margin: 0; color: #1c2233">WRITING</h2>
          </el-divider>
        </section>
        <section class="log-section">
          <el-row :gutter="15">
            <el-col
              :span="13"
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
              "
            >
              <ul class="menu">
                <li
                  v-for="(part, index) in parts"
                  :key="part.partId"
                  :class="{
                    actived: currentWritingPart.partId === part.partId
                  }"
                  @click="tabChange(index)"
                >
                  <span>TASK {{ index + 1 }}</span>
                </li>
              </ul>
              <el-card
                :body-style="{ padding: '0px' }"
                class="parts"
                style="height: calc(100vh - 30px)"
              >
                <section
                  v-for="(part, index) in parts"
                  v-show="currentWritingPart.partId === part.partId"
                  :key="part.partId"
                >
                  <div class="part-title__">
                    <span>TASK {{ index + 1 }}</span>
                  </div>
                  <el-scrollbar style="height: calc(100vh - 74px)">
                    <div
                      v-for="activity in part.activityList"
                      :key="activity.activityId"
                      class="activity"
                    >
                      <div
                        class="activity-content"
                        v-html="activity.activityContent"
                      />
                    </div>
                  </el-scrollbar>
                </section>
              </el-card>
            </el-col>
            <el-col :span="11">
              <el-card
                :body-style="{ padding: '0px' }"
                class="parts"
                style="height: calc(50vh - 15px)"
              >
                <div class="questions-title">
                  <span>TASK {{ currentIndex + 1 }} 范文</span>
                </div>
                <el-scrollbar style="height: calc(50vh - 59px)">
                  <div
                    class="part-content-en"
                    style="white-space: pre-wrap"
                    v-html="currentWritingPart.partContentEn"
                  />
                </el-scrollbar>
              </el-card>
              <el-card
                :body-style="{ padding: '0px' }"
                class="parts"
                style="height: calc(50vh - 30px); margin-top: 15px"
              >
                <div class="questions-title">
                  <span>TASK {{ currentIndex + 1 }} 我的写作</span>
                </div>
                <el-scrollbar style="height: calc(50vh - 74px)">
                  <div
                    v-if="currentIndex == 0"
                    class="part-content-en"
                    style="white-space: pre-wrap"
                    v-html="log.task1 ?? '未提交Task1'"
                  />
                  <div
                    v-else
                    class="part-content-en"
                    style="white-space: pre-wrap"
                    v-html="log.task2 ?? '未提交Task2'"
                  />
                </el-scrollbar>
              </el-card>
            </el-col>
          </el-row>
        </section>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  inject: ['writingParts', 'testLog'],
  data() {
    return {
      currentWritingPart: {},
      currentIndex: 0
    }
  },
  computed: {
    parts() {
      return this.writingParts.value
    },
    log() {
      return this.testLog.value
    }
  },
  watch: {
    parts() {
      this.currentWritingPart = this.parts[0]
    }
  },
  created() {
    this.currentWritingPart = this.parts[0]
  },
  methods: {
    tabChange(e) {
      this.currentWritingPart = this.parts[e]
      this.currentIndex = e
    }
  }
}
</script>
<style lang="scss">
.log-container {
  background-color: #f2f3f5;
}
.log-section {
  padding: 15px;
}
.parts {
  height: calc(100vh - 30px);
  width: 100%;
}
.parts .part-title__ {
  font-weight: bold;
  padding: 15px 15px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.activities {
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px);
}
.activity {
  display: block;
  padding: 15px;
}
.activity:last-child {
  border-bottom: none;
}
.activity-questions {
  margin-bottom: 15px;
}

.activity-title {
  margin-bottom: 5px;
}

.activity-content {
  line-height: 1.75rem;
  .wscnph {
    display: block;
    width: 100%;
    height: auto;
  }
}

.activity-content table {
  border: 1px solid #f3f3f3;
}

.questions-title {
  padding: 10px 15px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.questions-title .actions {
  width: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.questions-title .icon {
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  display: flex;
  padding: 5px;
  background-color: #f0f2f5;
  border: 1px solid #606266;
  color: #606266;
  cursor: pointer;
}

.questions-title .icon:hover {
  background-color: #606266;
  color: #fff;
}

.questions-title .icon.disable {
  border: 1px solid #c0c4cc;
  background-color: #f0f2f5;
  color: #c0c4cc;
  cursor: auto;
}
.questions {
  padding: 15px;
  font-size: 14px;
  line-height: 1.5rem;
}

.question-analyses {
  font-size: 14px;
  line-height: 1.8rem;
}

.question-answer span {
  font-weight: bold;
}

.question-answer.right span {
  color: #40d039;
}

.question-answer.wrong span {
  color: #e31837;
}

.part-content-en {
  background-color: #ffffff;
  font-size: 14px;
  padding: 15px;
  line-height: 1.8rem;
  overflow: auto;
}

.part-content-en:deep(h1) {
  font-size: 20px;
  margin: 0;
}

.part-content-en:deep(p) {
  margin: 0 0 5px 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul.menu {
  display: block;
  flex-basis: auto;
  white-space: nowrap;
}

ul.menu li {
  /* line-height: 10px; */
  margin-bottom: 3px;
  cursor: pointer;
}

ul.menu li.actived span {
  background-color: #ffffff;
}
ul.menu li span {
  width: 110px;
  /* margin: 60px; */
  display: inline-block;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: #303133;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #e6e8eb;
}
</style>
