<template lang="">
  <div class="main">
    <el-card style="width:50vw;height:50vh;">
      <div class="main-container">
        <h1>404</h1>
        <h2>UH OH! 页面丢失</h2>
        <p>您所访问的页面不存在，您可以点击下面链接返回首页。</p>
        <router-link to="/">返回首页</router-link>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.main{
  position: absolute;
  left: 50vw;
  top: 50vh;
  transform: translate(-25vw,-25vh);
}
.main-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  h1{
    text-align: center;
    font-size: 100px;
    margin: 0;
    padding: 0;
  }
  h2{
    text-align: center;
    font-size: 32px;
    margin: 0;
    padding: 0;
  }
  p{
    text-align: center;
    margin-top: 16px;
  }
}
</style>
