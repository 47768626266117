<template>
  <label class="one-checkbox" :class="{'is-checked':isChecked}">
    <span class="one-check_input">
      <!-- <span class="one-check_inner" /> -->
      <input
        v-model="model"
        type="checkbox"
        :checked="isChecked"
        :name="name"
        :disabled="disabled"
        :indeterminate="indeterminate"
        :value="label"
        @change="handleChange"
      >
    </span>
    <span class="one-checkbox_label">
      <slot />
      <template v-if="!$slots.default">
        {{ label }}
      </template>
    </span>
  </label>
</template>
<script>
import { computed, getCurrentInstance, defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'HiCheckbox',
  props: {
    // 属性校验 vue2 里面也是一样
    name: String,
    indeterminate: Boolean,
    checked: Boolean,
    disabled: Boolean,
    label: [String, Number, Boolean],
    modelValue: [String, Number, Boolean]
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit, attrs }) {
    const useCheckboxGroup = () => {
      const checkboxGroup = inject('HiCheckboxGroup', {})
      const isGroup = checkboxGroup.name === 'HiCheckboxGroup' // 判断有没有父亲叫ZCheckboxGroup
      return {
        isGroup,
        checkboxGroup
      }
    }

    const useModel = (props) => {
    // getCurrentInstance是vue3中用于获取组件的上下文
      const { emit } = getCurrentInstance() // 以前只有checkbox的时候 用户会传递modelValue
      const { isGroup, checkboxGroup } = useCheckboxGroup()
      const store = computed(() => checkboxGroup ? (checkboxGroup.modelValue?.value ?? []) : props.modelValue) // 从爸爸的modelValue取出来 传递给自己， type="checkbox" 可以绑定数组
      const model = computed({
        get() {
          return isGroup ? store.value : props.modelValue
        },
        set(val) {
          if (isGroup) { // 只要是组 就需要触发组的更新方法，不触发自己的
            return checkboxGroup.changeEvent(val)
          }
          emit('update:modelValue', val)
        }
      })
      return model
    }
    // 1.设计一个属性 这个属性采用的就是modelValue, 还能更改，更改的时候要触发一个事件，更新数据
    const model = useModel(props)

    const useCheckboxStatus = (props, model) => {
      const isChecked = computed(() => {
        const value = model.value // 当前是不是选中的
        // todo...
        if (Array.isArray(value)) { // 针对父组件传递过来的数组
          return value.includes(props.label)
        } else { // 针对true false
          return value
        }
      })
      return isChecked
    }
    // 2.需要给checkbox 设置一个checked的状态，等一会我们更改checkbox选中或者取消选中需要获取到checked状态
    const isChecked = useCheckboxStatus(props, model)

    const useEvent = () => {
      const { emit } = getCurrentInstance()
      const handleChange = (e) => {
        const target = e.target
        emit('change', target.checked)
      }
      return handleChange
    }
    // 3.创造一个change事件 可以触发绑定到自己身上的change
    const handleChange = useEvent()

    return {
      model,
      isChecked,
      handleChange
    }
  }
})
</script>

<style lang="scss" scoped>
.one-checkbox{
  color: #333;
  font-weight: 400;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: nowrap;
  margin-bottom: 5px;
  .one-checkbox_input{
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1.5;
    position: relative;
    vertical-align: middle;
    .one-checkbox_inner{
      display: inline-block;
      position: relative;
      border: 1px solid #dcdfe6;
      border-radius: 2px;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      background-color: #fff;
      z-index: 1;
      transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s,cubic-bezier(.71,-.46,.29,1.46);
      &:after{
        box-sizing: content-box;
        content: '';
        border: 1px solid #ffffff;
        border-left: 0;
        border-top: 0;
        height: 7px;
        left: 4px;
        position: absolute;
        top: 1px;
        transform: rotate(45deg) scaleY(0);
        width: 3px;
        transition: transform .15s ease-in .05s;
        transform-origin: center;
      }
    }
    .one-checkbox_original{
      opacity: 0;
      outline: none;
      position: absolute;
      left: 10px;
      margin: 0;
      width: 0;
      height: 0;
      z-index: -1;
    }
  }
  .one-checkbox_label{
    display: inline-block ;
    padding-left: 10px;
    line-height: 1.5rem;
    white-space: normal;
    word-wrap: break-word;
  }
}
// 选中的样式
.one-checkbox.is-checked{
  .one-checkbox_input{
    .one-checkbox_inner{
      background-color: #409eff;
      border-color: #409eff;
    }
    &:after{
      transform: rotate(45deg) scaleY(1);
    }
  }
  .one-checkbox_label{
    color: #409eff;
  }
}
</style>
