import request from '@/utils/request'

// 使用激活码
export function activateCard(pass) {
  return request({
    url: '/api/card/' + pass,
    method: 'get'
  })
}

// 获取会员信息
export function findMember() {
  return request({
    url: '/api/member/IELTS_TEST',
    method: 'get'
  })
}

// 获取会员日志
export function findMemberLogList(params) {
  return request({
    url: '/api/memberLog/list',
    method: 'get',
    params: params
  })
}

// 获取写作账户信息
export function findWriting() {
  return request({
    url: '/api/writing/IELTS_TEST',
    method: 'get'
  })
}

// 获取写作账户记录
export function findWrtingGoodsLogList(params) {
  return request({
    url: '/api/writingGoodsLog/list',
    method: 'get',
    params: params
  })
}

// 获取写作日志
export function findWrtingLogList(params) {
  return request({
    url: '/api/writingLog/list',
    method: 'get',
    params: params
  })
}

// 获取写作动态
export function findWrtingLogLastList(params) {
  return request({
    url: '/api/writingLog/last',
    method: 'get',
    params: params
  })
}

// 保存写作记录
export function saveWritingLog(data) {
  return request({
    url: '/api/writingLog/save',
    method: 'post',
    data: data
  })
}

// 保存写作记录
export function updateWritingLog(data) {
  return request({
    url: '/api/writingLog/update',
    method: 'post',
    data: data
  })
}

// 获取写作记录
export function findWritingLog(writingLogId) {
  return request({
    url: '/api/writingLog/' + writingLogId,
    method: 'get'
  })
}

// 获取预约日期
export function findWrtingLogDates() {
  return request({
    url: '/api/writingLog/find/dates',
    method: 'get'
  })
}
