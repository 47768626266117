<template>
  <el-form
    ref="loginForm"
    class="login-form"
    status-icon
    :rules="loginRules"
    :model="loginForm"
    label-width="0"
  >
    <el-form-item prop="username">
      <el-input
        v-model="loginForm.username"
        placeholder="请输入用户名"
        size="large"
        @keyup.enter="handleLogin"
      >
        <template #prefix>
          <el-icon class="el-input__icon"><User /></el-icon>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="loginForm.password"
        :type="passwordType"
        auto-complete="off"
        placeholder="请输入密码"
        size="large"
        @keyup.enter="handleLogin"
      >
        <template #prefix>
          <el-icon class="el-input__icon"><Lock /></el-icon>
        </template>
        <template #suffix>
          <el-icon v-if="passwordType==='password'" class="el-input__icon" @click="showPassword"><Hide /></el-icon>
          <el-icon v-else class="el-input__icon" @click="showPassword"><View /></el-icon>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="code">
      <el-row :span="24">
        <el-col :span="16">
          <el-input
            v-model="loginForm.code"
            :maxlength="code.len"
            auto-complete="off"
            placeholder="请输入验证码"
            size="large"
            @keyup.enter="handleLogin"
          >
            <template #prefix>
              <el-icon class="el-input__icon"><Bell /></el-icon>
            </template>
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="login-code">
            <span
              v-if="code.type == 'text'"
              class="login-code-img"
              @click="refreshCode"
            >{{ code.value }}</span>
            <img
              v-else
              :src="code.src"
              class="login-code-img"
              @click="refreshCode"
            >
          </div>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        style="width:100%; margin-bottom:30px;"
        class="login-submit"
        size="large"
        @click.prevent="handleLogin"
      >登录
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { User, View, Lock, Hide, Bell } from '@element-plus/icons-vue'
import { randomLenNum } from '@/utils/util'
import { mapGetters } from 'vuex'

export default {
  name: 'UserLoginPage',
  components: { User, View, Lock, Hide, Bell },
  data() {
    return {
      loginForm: {
        username: undefined,
        password: undefined,
        code: undefined,
        randomStr: undefined
      },
      checked: false,
      code: {
        src: '/code',
        value: '',
        len: 4,
        type: 'image'
      },
      codeUrl: process.env.NODE_ENV === 'production' ? 'http://pig.hibookapp.com/code' : '/code',
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { pattern: /^([A-Z\a-z\u4e00-\u9fa5\d]*?)$/, message: '请输入小写英文、数字、中文', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度最少为6位', trigger: 'blur' }
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      passwordType: 'password'
    }
  },
  computed: {
    ...mapGetters(['tagWel'])
  },
  created() {
    this.refreshCode()
  },
  methods: {
    refreshCode() {
      this.loginForm.code = ''
      this.loginForm.randomStr = randomLenNum(this.code.len, true)
      this.code.type === 'text'
        ? (this.code.value = randomLenNum(this.code.len))
        : (this.code.src = `${this.codeUrl}?randomStr=${this.loginForm.randomStr}`)
    },
    showPassword() {
      this.passwordType === ''
        ? (this.passwordType = 'password')
        : (this.passwordType = '')
    },
    handleLogin() {
      var self = this
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          self.$store
            .dispatch('LoginByUsername', self.loginForm)
            .then(() => {
              console.log('Login Success!!')
              self.$store.dispatch('GetUserInfo')
              self.$store.dispatch('getMemberInfo')
              self.$store.dispatch('getWritingInfo')
              self.$router.push({
                path: self.redirect || '/',
                query: self.otherQuery
              }, () => {})
            })
            .catch(() => {
              self.refreshCode()
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;
.login-code {
  margin-left: 16px;
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
.login-code-img {
  width: 100px;
  height: 30px;
}
</style>
