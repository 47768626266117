<template lang="">
  <div ref="noteContent" class="note">
    <div class="close" @click.native="close" />
    <div class="draghandle" @mousedown.native="onMouseDown" />
    <div class="edit">
      <div class="mainText" contenteditable="false">
        {{ text ?? '' }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NoteComponent',
  props: ['text'],
  data() {
    return {
      instructionsNote: null,
      captured: null,
      highestZ: 0
    }
  },
  computed() {

  },
  created() {
    if (this.text === '') {
      this.close()
    }
  },
  methods: {
    close() {
      this.$refs.noteContent.remove()
    },
    onMouseDown(e) {
      this.captured = this
      this.startX = e.clientX - this.$refs.noteContent.offsetLeft
      this.startY = e.clientY - this.$refs.noteContent.offsetTop
      this.zIndex = ++this.highestZ

      var self = this
      if (!('mouseMoveHandler' in this)) {
        this.mouseMoveHandler = function(e) { return self.onMouseMove(e) }
        this.mouseUpHandler = function(e) { return self.onMouseUp(e) }
      }

      document.addEventListener('mousemove', this.mouseMoveHandler, true)
      document.addEventListener('mouseup', this.mouseUpHandler, true)

      return false
    },
    onMouseMove: function(e) {
      if (this !== this.captured) { return true }
      this.$refs.noteContent.style.left = e.clientX - this.startX + 'px'
      this.$refs.noteContent.style.right = 'unset'
      this.$refs.noteContent.style.top = e.clientY - this.startY + 'px'
      this.$refs.noteContent.style.bottom = 'unset'
      return false
    },

    onMouseUp: function(e) {
      document.removeEventListener('mousemove', this.mouseMoveHandler, true)
      document.removeEventListener('mouseup', this.mouseUpHandler, true)
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.note {
  background-color: rgb(255, 240, 70);
  color:black !important;
  /*height: 250px;
  /*padding: 10px;*/
  position: absolute;
  right: 10%;
  bottom: 10%;
  width: 200px;
  -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 0.357em;
  border-radius: 0.357em;
}

.note .close {
    margin: 0.3em 0.5em 0 0;
}

.note .draghandle {
    background-color: #db0;
    border-bottom: 1px solid #a80;
    height:1.5em;
    cursor:move;
    -moz-user-select:none;
    user-select:none;
    -moz-border-radius-topleft: 0.357em;
    -moz-border-radius-topright: 0.357em;
    border-radius: 0.357em 0.357em 0 0;
}

.note .edit {
    outline: none;
    overflow-x:hidden;
    overflow-y:auto;
    height: 250px;
    width: 100%;
    word-wrap:break-word;
}

.edit .highlightText {
    padding: 10px;
    font-weight:bold;
}
.edit .mainText{
    padding: 0 10px 10px;
}
.note .ftr {
    padding: 5px 4px;
    overflow: hidden;
    text-align:center;
}
.note .ftr a {
    color:#666;
    /* font-weight:bold;
    float: left; */
}
.note .ftr a:hover {
    color:#222;
    /* text-decoration:underline; */
}

.note .close {
    -moz-background-size: 16px;
    background-size: 16px;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url("./img/close-icon.png");
    width: 1.14286em;
    height: 1.14286em;
    margin: 3px 5px 0 0;
    padding: 0;
    float: right;
    cursor: pointer;
}
</style>
