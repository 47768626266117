<template lang="">
  <div class="d-flex justify-space-between align-start mb-8">
    <div
      class="flex-grow-1 d-flex flex-column justify-start align-end mr-2"
      style="width: 100%"
    >
      <span class="font-weight-bold text-right">Custom</span>
      <div
        v-if="
          currentChat.testType === 1 &&
            currentChat.text !== null &&
            currentChat.text !== '' &&
            currentChat.text !== undefined
        "
        class="my-2 audio-text bg-deep-purple-lighten-5 rounded-xl rounded-te-0"
        style="max-width: 80%"
      >
        <div
          id="pdfCentent"
          class="question-content pa-4"
          v-html="currentChat.text"
        />
        <div class="ml-4 mb-4">
          <v-btn
            variant="tonal"
            size="small"
            prepend-icon="mdi-file-pdf-box"
            color="deep-purple"
            @click="handleExportPdf"
          >下载PDF</v-btn>
          <v-btn
            v-if="!isCloud && currentChat.audio === undefined"
            variant="tonal"
            size="small"
            prepend-icon="mdi-cloud-upload"
            color="deep-purple"
            class="ml-4"
            @click="uploadRec"
          >保存云端</v-btn>
        </div>
      </div>
      <div
        v-if="currentChat.audio !== undefined && currentChat.audio !== null"
        class="my-2 audio-text"
        style="width: 60%"
      >
        <AudioPlayer
          ref="answerAudioRef"
          :auto-play="false"
          :audios="[currentChat.audio]"
          :controls="'noMuted noBackward noForward noBackward5 noForward5 noVolume'"
          b-color="#EDE7F6"
          t-color="#673AB7"
          thumb-color="#673AB7"
          custom-class="pa-4 rounded-xl rounded-te-0"
        />
        <div class="d-flex justify-end align-center mt-2">
          <v-btn
            variant="text"
            size="small"
            prepend-icon="mdi-download"
            color="deep-purple"
            @click="recdown"
          >下载音频</v-btn>
          <v-btn
            v-if="!isCloud"
            variant="text"
            size="small"
            prepend-icon="mdi-cloud-upload"
            color="deep-purple"
            @click="uploadRec"
          >保存云端</v-btn>
          <template
            v-if="
              currentChat.correctAiStatus === 0 ||
                currentChat.correctStatus === 0
            "
          >
            <!-- <v-btn
              v-if="currentChat.correctAiStatus === 0"
              variant="text"
              size="small"
              prepend-icon="mdi-refresh-auto"
              color="deep-purple"
            >
              AI测评
            </v-btn> -->
            <!-- <v-btn
              v-if="currentChat.correctStatus === 0"
              variant="text"
              size="small"
              prepend-icon="mdi-account-tie"
              color="deep-purple"
            >
              人工测评
            </v-btn> -->
          </template>
        </div>
      </div>
      <div
        v-if="hasDown"
        class="my-2 pa-4 d-flex flex-column justify-start align-stretch bg-grey-lighten-4 rounded"
        style="width: 80%"
      >
        <div class="d-flex justify-center align-center">
          没弹下载？试一下链接或复制Base64文本
          <v-btn
            variant="text"
            size="small"
            color="primary"
            class="ml-2"
            @click="recdown64()"
          >生成Base64文本</v-btn>
        </div>
        <v-textarea
          v-if="down64Val"
          v-model="down64Val"
          class="mt-2"
          variant="solo"
          width="100%"
          rows="6"
          hide-details
        />
      </div>
      <!-- <template v-if="isCloud">
        <div
          v-if="
            currentChat.correctAiResult !== undefined &&
              currentChat.correctAiResult !== '' &&
              currentChat.correctAiResult !== null
          "
          class="my-2 pa-4 audio-text bg-deep-purple-lighten-5 rounded-xl rounded-te-0"
          style="width: 80%"
        >
          {{ handleAiResult() }}
        </div>
        <div
          v-if="
            currentChat.correctResult === undefined &&
              currentChat.correctResult === ''
          "
          class="my-2 pa-4 audio-text bg-deep-purple-lighten-5 rounded"
          style="width: 80%"
        >
          人工批改结果
        </div>
      </template> -->
    </div>
    <v-avatar>
      <v-img :src="require('@/assets/images/logo.png')" alt="John" />
    </v-avatar>
  </div>
</template>
<script setup>
import { ref, watch, defineProps, getCurrentInstance } from 'vue'
import { saveSpeakingLog } from '@/apis/speaking'
import { upload } from '@/apis/file'
import { ElMessage } from 'element-plus'

import AudioPlayer from '@/components/AudioPlayer/AudioPlayer'

const props = defineProps({
  question: {
    type: Object,
    default: () => {}
  },
  item: {
    type: Object,
    default: () => {}
  }
})
const answerAudioRef = ref()
const currentChat = ref(props.item)
watch(
  () => props.item,
  () => {
    currentChat.value = props.item
    if (props.item.audio !== undefined) {
      answerAudioRef.value.src = props.item.audio
    }
  }
)

const hasDown = ref(false)
const recdown = () => {
  var name = props.question.speakingQuestionId
  var downA = document.createElement('A')
  downA.href = props.item.audio
  downA.download = name
  downA.click()
  hasDown.value = true
}

// const baseUrl = process.env.VUE_APP_BASE_API
const isCloud = ref(props.item.isCloud || false)
const uploadRec = async() => {
  var o = props.item
  var formData = new FormData()
  var blob = o.blob // 获取音频数据
  var answer
  if (blob !== undefined) {
    // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，
    // 其它项目可直接传把blob作为file塞入formData
    var newbolb = new Blob([blob], { type: 'audio/wav' })
    var fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
    // formData是传给后端的对象,
    formData.append('file', fileOfBlob)
    // 发送给后端的方法
    var res = await upload(formData)
    answer = res.data.data.url
  }
  var data = {
    topicId: props.question.topicId,
    questionId: props.question.speakingQuestionId,
    answer:
      answer === undefined ? answer : 'https://pig.hibookapp.com' + answer,
    answerText: props.item.text,
    testType: props.item.testType
  }
  var resData = await saveSpeakingLog(data)
  if (resData.data.data != null) {
    ElMessage.success('保存成功!')
    isCloud.value = true
  }
}

const down64Val = ref()
const recdown64 = () => {
  var o = props.item
  var reader = new FileReader()
  reader.onloadend = function() {
    down64Val.value = reader.result
  }
  reader.readAsDataURL(o.blob)
}

// const handleAiResult = () => {
//   var _result = currentChat.value.correctAiResult
//   console.log(JSON.parse(_result))
//   return JSON.parse(_result)
// }

const { proxy } = getCurrentInstance()
const handleExportPdf = () => {
  console.log(proxy.$exportPdf)
  proxy.$exportPdf(document.querySelector('#pdfCentent'), '文件名称')
}
</script>
<style lang="scss" scoped>
.audio-text {
  font-size: 16px;
  line-height: 1.75rem;
}
.question-content {
  :deep(p) {
    margin: 0;
    padding: 0;
  }
  :deep(div) {
    margin: 0;
    padding: 0;
  }
}
</style>
