<template lang="">
  <el-card :body-style="{ padding: '16px'}">
    <div class="top-bar">
      <el-page-header :icon="ArrowLeft" style="display:flex;" @back="onBack">
        <template #content>
          <span class="text-large font-600 mr-3">{{ taskTitle }} </span>
        </template>
      </el-page-header>
      <div class="timer">
        <span class="minute">{{ state.minute }}</span>
        <span> : </span>
        <span class="second">{{ state.second }}</span>
      </div>
      <div class="action">
        <el-button v-preventReClick type="" class="end-button" plain @click="handleSaveDraft"><font-awesome-icon icon="fa-solid fa-floppy-disk" style="margin-right:5px;" /> 保存草稿</el-button>
        <el-button type="danger" class="end-button" plain @click.once="handleFinish"><font-awesome-icon icon="fa-solid fa-cloud-arrow-up" style="margin-right:5px;" /> 提交作文</el-button>
      </div>
    </div>
  </el-card>
</template>
<script setup>
import { reactive, watch, computed, onMounted, onUnmounted, defineProps, defineEmits } from 'vue'
import { useRouter } from 'vue-router'

import { ElMessage } from 'element-plus'
import { ArrowLeft } from '@element-plus/icons-vue'

const emit = defineEmits(['handleSaveDraft', 'handleFinish'])
const props = defineProps({
  taskContent: {
    type: String,
    default: undefined
  },
  taskTitle: {
    type: String,
    default: undefined
  },
  testType: {
    type: String,
    default: undefined
  },
  defaultDuration: {
    type: Number,
    default: 0
  },
  start: {
    type: Boolean,
    default: false
  }
})

const state = reactive({
  content: {},
  saveInterval: undefined,
  timerInterval: undefined,
  duration: 0,
  minute: '00',
  second: '00',
  isPrecise: false,
  isFinishSection: true,
  dialogHelpVisible: false,
  dialogHideVisible: false
})

const start = computed(() => state.start)
watch(start, (newStart) => {
  console.log(`newStart is ${newStart}`)
})

const taskContent = computed(() => props.taskContent)
watch(taskContent, (newStart) => {
  console.log(`taskContent is ${newStart}`)
})

const taskTitle = computed(() => props.taskTitle)
watch(taskTitle, (newStart) => {
  console.log(`taskTitle is ${newStart}`)
})

onMounted(() => {
  if (start) {
    startTimer()
  }
})

onUnmounted(() => {
  clearInterval(state.timerInterval)
  clearInterval(state.saveInterval)
})

const router = useRouter()
const onBack = () => {
  if (state.taskContent !== undefined && state.taskContent.length > 0) {
    emit('handleSaveDraft', state.duration)
  }
  router.back()
}

const doFinish = () => {
  clearInterval(state.timerInterval)
  clearInterval(state.saveInterval)
  emit('handleFinish', state.duration)
}

const handleFinish = () => {
  if (taskContent === undefined || taskContent.length === 0) {
    ElMessage.warning('提交的内容不能为空!')
    return
  }
  doFinish()
}

const handleSaveDraft = () => {
  emit('handleSaveDraft', state.duration)
}

const initTime = computed(() => props.defaultDuration)
const startTimer = () => {
  // Get limit in seconds
  var time = initTime.value + 0
  // var time = (5 * 60) + 4;

  // Save answers every 5 seconds
  state.saveInterval = setInterval(function() {
    // saveAnswers({})
  }, 5000)

  // Update the count down every 1 second
  state.timerInterval = setInterval(function() {
    // One second has passed
    time = time + 1
    state.duration = time

    // Time calculations for minutes
    var minutes = Math.ceil(time / 60)

    var preciseMinutes = Math.floor(time / 60)
    if (preciseMinutes < 10) {
      preciseMinutes = '0' + preciseMinutes
    }

    var seconds = time - preciseMinutes * 60
    if (seconds < 10) {
      seconds = '0' + seconds
    }

    // Display the result
    if (minutes <= 1) {
      state.minute = '00'
      state.second = seconds
    } else {
      state.minute = preciseMinutes
      state.second = seconds
    }
  }, 1000)
}
</script>
<style lang="scss" scoped>
.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top-bar .student {
  color: #414042;
}

.top-bar .middle-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.top-bar .timer {
  color: #414042;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: bold;

}

.top-bar .help {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.top-class:deep(.hide-class .el-dialog__header){
  padding: 0;
  padding-bottom: 0;
  margin: 0;
}

.top-class:deep(.hide-class .el-dialog__body){
  padding: 0;
  padding-bottom: 0;
  margin: 0;
}

.top-class:deep(.el-overlay){
  background-color: rgba($color: #000000, $alpha: 0.9);
}

.modal-dialog {
  width: 100%;
}
// Actual modal
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: transparent;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

// Title text within header
.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-body a {
  max-width: 200px;
}

.btn.bc-button {
    margin: 0 auto;
    display: block;
    background-color: transparent;
    color: #283e53;
    font-weight: bold;
    border: none;
    box-shadow: 0 2px 2px grey;
}
</style>
