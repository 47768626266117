<template lang="">
  <div>
    <div style="height:18px;background-color:#FEC171" />
    <div style="height:150px;background-color:#1c2233">
      <el-row>
        <el-col :lg="{span:22,offset:1}" :md="{span:24}">
          <h1 style="margin-left:15px; margin-top:10px; margin-bottom:0;color:#FEC171;font-size:40px;">IELTS.</h1>
        </el-col>
      </el-row>
    </div>
    <div style="background-color:#F2F3F5;">
      <el-row>
        <el-col :lg="{span:22,offset:1}" :md="{span:24}">
          <section class="log-info" style="margin-top:-70px;">
            <el-card :body-style="{ padding: '40px 15px' }" style="margin:0 15px 0 15px;">
              <h1>Your Results</h1>
              <h3>Here are your marks for your Listening and Reading answers:</h3>
              <div class="scores">
                <span>
                  <Icon name="tinglizhuanxiangke" class="scoreIcon" />
                  Listening <strong v-if="listeningParts.length > 0" style="margin-left:10px;">{{ listeningScore }}</strong> <strong v-else style="margin-left:10px;">-</strong>
                </span>
                <span>
                  <Icon name="yueduzhuanxiangke" class="scoreIcon" />
                  Reading  <strong v-if="readingParts.length > 0" style="margin-left:10px;">{{ readingScore }}</strong> <strong v-else style="margin-left:10px;">-</strong>
                </span>
              </div>
            </el-card>
          </section>
        </el-col>
      </el-row>
    </div>
    <ielts-listening v-if="listeningParts.length > 0" />
    <ielts-reading v-if="readingParts.length > 0" />
    <ielts-writing v-if="writingParts.length > 0" />
    <div style="background-color: #F2F3F5; padding-bottom:15px;">
      <el-row>
        <el-col :offset="1" :span="22" style="text-align:center;">
          <el-button size="large" plain color="#1c2233" @click="handleBack">返回</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="height:12px;background-color:#FEC171" />
    <div style="height:110px;background-color:#1c2233" />
  </div>
</template>
<script>
import { findTestLog } from '@/apis/test'
import { findPaper } from '@/apis/paper'
import { ref, provide, readonly, onMounted, computed, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { handleScore } from '@/utils/test'

import IeltsListening from './ListeningTestEndView'
import IeltsReading from './ReadingTestEndView'
import IeltsWriting from './WritingTestEndView'

export default {
  components: { IeltsListening, IeltsReading, IeltsWriting },
  setup() {
    const router = useRoute()
    const uRouter = useRouter()
    var listeningParts = ref([])
    var readingParts = ref([])
    var writingParts = ref([])
    var listeningAnswers = ref([])
    var readingAnswers = ref([])
    var testLog = ref({})
    var paperInfo = ref({})
    var readingScore = ref('0.0')
    var readingNum = ref(0)
    var listeningScore = ref('0.0')
    var listeningNum = ref(0)

    onMounted(() => {
      fetchTestLog()
      // 监听浏览器返回
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', handleBack, false)
    })

    onUnmounted(() => {
      window.removeEventListener('popstate', handleBack)
    })

    const handleBack = () => {
      var _path = router.query.direct ?? '/paper'
      uRouter.replace({ path: _path })
    }

    const fetchTestLog = () => {
      const _logId = router.params.logId
      findTestLog(_logId).then(res => {
        testLog.value = res.data.data
        handleAnswers()
        handleScores()
        fetchPaper()
      })
    }

    const fetchPaper = () => {
      var _id = testLog.value.objectId
      findPaper(_id).then(res => {
        paperInfo.value = res.data.data
        handleParts()
      })
    }

    const handleAnswers = () => {
      listeningAnswers.value = JSON.parse(testLog.value.resultInfo).listening
      readingAnswers.value = JSON.parse(testLog.value.resultInfo).reading
    }

    const handleParts = () => {
      var _listeningParts = []
      var _readingParts = []
      var _writingParts = []
      if (paperInfo.value.paperType === '1') {
        paperInfo.value.children.forEach(child => {
          if (child.testCategoryId === '21' || child.testCategoryId === '232') {
            _listeningParts = child.partList
          } else if (child.testCategoryId === '22' || child.testCategoryId === '233') {
            _readingParts = child.partList
          } else if (child.testCategoryId === '150' || child.testCategoryId === '234') {
            _writingParts = child.partList
          }
        })
      } else {
        if (paperInfo.value.testCategoryId === '21' || paperInfo.value.testCategoryId === '232') {
          _listeningParts = paperInfo.value.partList
        } else if (paperInfo.value.testCategoryId === '22' || paperInfo.value.testCategoryId === '233') {
          _readingParts = paperInfo.value.partList
        } else if (paperInfo.value.testCategoryId === '150' || paperInfo.value.testCategoryId === '234') {
          _writingParts = paperInfo.value.partList
        }
      }

      listeningParts.value = _listeningParts
      readingParts.value = _readingParts
      writingParts.value = _writingParts
    }

    const handleScores = () => {
      var _readingScore = JSON.parse(testLog.value.resultScore).readingScore
      readingNum.value = parseInt(_readingScore)
      readingScore.value = handleScore(parseInt(_readingScore))
      var _listeningScore = JSON.parse(testLog.value.resultScore).listeningScore
      listeningNum.value = parseInt(_listeningScore)
      listeningScore.value = handleScore(parseInt(_listeningScore))
    }

    provide('testLog', computed(() => readonly(testLog)))
    provide('listeningParts', computed(() => readonly(listeningParts)))
    provide('listeningAnswers', computed(() => readonly(listeningAnswers)))
    provide('readingParts', computed(() => readonly(readingParts)))
    provide('readingAnswers', computed(() => readonly(readingAnswers)))
    provide('writingParts', computed(() => readonly(writingParts)))
    provide('readingNum', computed(() => readonly(readingNum)))
    provide('listeningNum', computed(() => readonly(listeningNum)))
    return {
      testLog,
      listeningParts,
      readingParts,
      writingParts,
      readingScore,
      listeningScore,
      handleBack
    }
  }
}
</script>
<style lang="scss" scoped>
.log-info h1{
  font-size: 28px;
  margin:0 0 10px 0;
  text-align: center;
}
.log-info h3{
  font-size: 18px;
  margin:0 0 30px 0;
  text-align: center;
}
.scores{
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.scores span{
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.scores span:first-child{
  margin-right: 80px;
}

.scoreIcon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
</style>
