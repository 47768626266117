import request from '@/utils/request'
import qs from 'qs'

// 获取题库列表
export function findParts(current, size, data) {
  return request({
    url: '/api/part/remote',
    method: 'post',
    params: { current, size },
    data: qs.stringify(data)
  })
}

// 获取题库记录
export function findPartInfo(partId) {
  return request({
    url: '/api/part/' + partId + '/info',
    method: 'get'
  })
}
