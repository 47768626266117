<template>
  <router-view />
</template>

<script>
export default {
  name: 'AppPage',
  beforeCreate () {
    document.querySelector('body').setAttribute('class', 'on-test')
  }

}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #303133;
  background-color: #fff;
  font-family: "Helvetica Neue Regular", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
</style>
