<template lang="">
  <div class="common-layout">
    <el-card :body-style="{ padding: '16px'}" style="margin-bottom:16px;">
      <el-page-header :icon="ArrowLeft" style="display:flex;" @back="onBack">
        <template #content>
          <span class="text-large font-600 mr-3">作文批改详情</span>
          <small>【批改编号： {{ logId }}】</small>
        </template>
      </el-page-header>
    </el-card>
    <el-row :gutter="16">
      <el-col :span="12">
        <el-card :body-style="{ padding: '0px 0px 0px 16px'}" style="height:calc(50vh - 60px); margin-bottom:10px;">
          <template #header>
            <div class="card-header">
              <span>写作题目</span>
            </div>
          </template>
          <el-scrollbar style="height:calc(50vh - 120px); padding: 16px 16px 16px 0;">
            <template v-if="state.log === undefined">
              Loading....
            </template>
            <template v-else>
              <h2 style="margin:8px auto; font-size:18px;">【{{ state.log.taskType }}】{{ state.log.testNote }}</h2>
              <div class="task-content" v-html="state.log.testContent" />
            </template>
          </el-scrollbar>
        </el-card>
        <el-card :body-style="{ padding: '0 0 0 16px'}" style="height:calc(50vh - 56px);">
          <template #header>
            <div class="card-header">
              <span>我的作文</span>
              <el-button class="button" type="primary" plain @click="exportWordFile"><font-awesome-icon icon="fa-solid fa-download" style="margin-right:5px;" /> 下载作文</el-button>
            </div>
          </template>
          <el-scrollbar style="height:calc(50vh - 125px); padding: 16px 16px 16px 0;">
            <template v-if="state.log === undefined">
              Loading....
            </template>
            <template v-else>
              <div class="task-content" style="white-space:pre-wrap" v-html="state.log.taskContent" />
            </template>
          </el-scrollbar>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card :body-style="{ padding: '16px'}" style="margin-bottom:16px;">
          <el-steps :active="progress" finish-status="success">
            <el-step title="草稿" :description="state.log !== undefined ? state.log.createTime : ''" />
            <el-step :title="state.log !== undefined ? (state.log.status === '0' ? '待提交':'已提交') :''" :description="state.log !== undefined ? state.log.submitTime : ''" />
            <el-step :title="state.log !== undefined ? (state.log.status <= '1' ? '待批改':'批改中') :''" :description="state.log !== undefined ? state.log.receiveTime : ''" />
            <el-step :title="state.log !== undefined ? (state.log.status < '3' ? '预计完成':'完成批改') :''" :description="state.log !== undefined ? (state.log.status === '3' ? state.log.correctTime : state.log.deadline):''" />
          </el-steps>
        </el-card>
        <el-card :body-style="{ padding: '0 0 0 16px'}" style="height:calc(100vh - 234px);">
          <template #header>
            <div class="card-header">
              <span>批改报告</span>
              <div>
                <el-button v-show="state.log !== undefined && state.log.status === '0'" class="button" type="primary" plain @click="goWriting"><font-awesome-icon icon="fa-solid fa-pen-to-square" style="margin-right:5px;" /> 继续编辑</el-button>
                <el-button v-show="state.log !== undefined && state.log.status === '0'" class="button" type="danger" plain @click="showDates"><font-awesome-icon icon="fa-solid fa-cloud-arrow-up" style="margin-right:5px;" /> 提交作文</el-button>
                <el-button v-show="state.log !== undefined && state.log.status === '3'" class="button" type="primary" plain @click="downloadFile"><font-awesome-icon icon="fa-solid fa-download" style="margin-right:5px;" /> 下载报告</el-button>
              </div>
            </div>
          </template>
          <el-scrollbar style="height:calc(100vh - 276px); padding: 16px 16px 16px 0;">
            <div v-loading="state.log === undefined">
              <template v-if="state.log !== undefined">
                <template v-if="state.log.status === '0'">尚未提交作文</template>
                <template v-else-if="state.log.status === '1'">等待批改</template>
                <template v-else-if="state.log.status === '2'">批改中</template>
                <template v-else>
                  <div style="margin-bottom:8px;"><strong>综合得分：</strong>{{ parseFloat(state.log.resultScore).toFixed(1) }}</div>
                  <template v-if="state.log.resultContent !== undefined || state.log.resultContent !== ''">
                    <div style="margin-bottom:8px;"><strong>批改说明：</strong></div>
                    <div class="task-content" v-html="state.log.resultContent" />
                  </template>
                </template>
              </template>
            </div>
          </el-scrollbar>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog v-model="dialogFormVisible" width="60%" title="提交作文">
      <div class="date_title">请预约批改时间</div>
      <div class="date_subtitle">批改时间为最迟获得批改结果的时间，可预约5天之内的批改时间，每天0点开放新的可预约时间!</div>
      <ul class="dates">
        <li v-for="date in state.dates" :key="date.logDate">
          <div v-if="date.logCount > 0" class="date" :class="{'active': curDate === date.logDate}" @click="handleSelectDate(date.logDate)">
            <span>{{ date.logDate }} </span>
            <span>剩余可预约：<strong>{{ date.logCount }}</strong></span>
          </div>
          <div v-else class="date disable">
            <span>{{ date.logDate }} </span>
            <span>剩余可预约：<strong>{{ date.logCount }}</strong></span>
          </div>
        </li>
      </ul>
      <template #footer>
        <span class="dialog-footer">
          <el-button plain @click="dialogFormVisible = false">
            <font-awesome-icon icon="fa-solid fa-circle-xmark" style="margin-right:5px;" /> 取消
          </el-button>
          <el-button type="danger" plain @click="doFinish">
            <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" style="margin-right:5px;" /> 提交作文
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, onUnmounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ArrowLeft } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'

import { exportWord, getBase64Sync } from '@/utils/exportDocx'
import { handleDown2 } from '@/utils/util'

import { findPartInfo } from '@/apis/part'
import { findWritingLog, saveWritingLog, findWrtingLogDates } from '@/apis/member'

const progress = ref(0)
const state = reactive({
  log: undefined,
  part: undefined
})
const dialogFormVisible = ref(false)
const curDate = ref(undefined)

onMounted(() => {
  nextTick(() => {
    // 禁用右键
    document.oncontextmenu = new Function('event.returnValue=false')
    // 禁用选择
    document.onselectstart = new Function('event.returnValue=false')
  })
})

onUnmounted(() => {
  // 取消禁用右键
  document.oncontextmenu = new Function('event.returnValue=true')
  // 取消禁用选择
  document.onselectstart = new Function('event.returnValue=true')
})

const route = useRoute()
const logId = route.params.logId || 'Loading'

const docxData = reactive({
  writingLogId: '',
  partTitle: '',
  testContent: '请在【9分达人模考平台】的题库中查看具体题目内容',
  taskContent: '',
  taskNote: '',
  imgList: []
})
const doxUrl = ref(undefined)

const fetchWritingLog = () => {
  findWritingLog(logId).then(async res => {
    var _data = res.data.data
    state.log = _data
    progress.value = _data.status === '1' || _data.status === '3' ? parseInt(_data.status) + 1 : parseInt(_data.status)
    if (_data.testPartId !== null && _data.testPartId !== undefined) {
      findPart(_data.testPartId)
    }

    docxData.writingLogId = logId
    docxData.taskType = _data.taskType
    docxData.partTitle = _data.testNote || ''
    docxData.taskContent = remove_tags(_data.taskContent || '')
    docxData.taskNote = _data.taskNote || ''
    if (_data.testNote.indexOf('自选题目') !== -1) {
      docxData.testContent = remove_tags(_data.testContent || '')
      docxData.imgList = await handleImageUrl(_data.testContent || '')
    }
    doxUrl.value = _data.resultAttachment
  })
}
fetchWritingLog()

const handleImageUrl = async(data) => {
  const matchReg = /<img.*?(?:>|\/>)/gi // 匹配图片中的img标签
  const arr2 = data.match(matchReg) // 拿到标签中所有的img
  const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
  const imgurls = []
  if (arr2 != null) {
    for (let i = 0; i < arr2.length; i++) {
      const src = arr2[i].match(srcReg)
      imgurls.push(src[1]) // 拿到 src=后面的 "http：xxx"
    }
  }

  var imgList = []
  for (const i in imgurls) {
    imgList.push({ 'imgUrl': await getBase64Sync(imgurls[i]) })
  }
  return imgList
}

const remove_tags = (html) => {
  html = html.replace(/<br>/g, '$br$')
  html = html.replace(/(?:\r\n|\r|\n)/g, '$br$')
  var tmp = document.createElement('DIV')
  tmp.innerHTML = html
  html = tmp.textContent || tmp.innerText
  html = html.replace(/\$br\$/g, '\n')
  html = html.replace(/\u0085|\u2028|\u2029|\u000b/g, '\n')
  return html
}

const findPart = (partId) => {
  findPartInfo(partId).then(res => {
    state.part = res.data.data
    // docxData.partTitle = res.data.data.partTitle || ''
  })
}

const router = useRouter()
const onBack = () => {
  router.back()
}

const goWriting = () => {
  if (state.part !== undefined) {
    router.push({
      path: '/writing/info',
      query: { 'partId': state.part.partId, 'logId': logId }
    }, () => {})
  } else {
    router.push({
      path: '/writing/free',
      query: { 'logId': logId }
    }, () => {})
  }
}

const exportWordFile = async() => {
  // for (const i in this.imglist) {
  //   this.imglist[i].imgUrl = await getBase64Sync(this.imglist[i].imgUrl)
  // }
  const data = docxData

  const imgSize = {
    // 控制导出的word图片大小
    imgurl: [200, 200]
  }
  console.log(docxData)
  exportWord('/docx/writing_template.docx', data, docxData.partTitle + '.docx', imgSize)
}

const downloadFile = () => {
  if (doxUrl.value === undefined) {
    ElMessage.error('老师正在快马加鞭的批改中，请耐心等待...')
    return
  }
  var fileName = docxData.writingLogId + '.docx'
  var bucketName = doxUrl.value
  console.log(bucketName)
  handleDown2(fileName, bucketName)
}

const showDates = () => {
  dialogFormVisible.value = true
}

const store = useStore()
const doFinish = () => {
  if (curDate.value === undefined || curDate.value === '') {
    ElMessage.error('请选择预约批改时间！')
    return
  }

  ElMessageBox.confirm(
    '确认提交作文么？本次提交将扣除 1 个批改额度。',
    '温馨提示',
    {
      confirmButtonText: '确认提交',
      cancelButtonText: '再看看',
      type: 'warning'
    }
  )
    .then(() => {
      var updateForm = {
        writingLogId: logId,
        project: 'IELTS_TEST',
        status: '1',
        deadline: curDate.value + ' 23:59:59'
      }
      saveWritingLog(updateForm).then(res => {
        console.log(res.data)
        if (res.data.code === 0) {
          ElMessage.success('提交作文成功，等待批改中...')
          fetchWritingLog()
          store.dispatch('getWritingInfo')
          dialogFormVisible.value = false
        } else {
          ElMessage.error('提交失败，请检查后重新提交！')
        }
      })
    })
    .catch(() => {
      return
    })
}

const fetchDates = () => {
  findWrtingLogDates().then(res => {
    state.dates = res.data.data
  })
}
fetchDates()

const handleSelectDate = (v) => {
  console.log(v)
  curDate.value = v
}

</script>
<style lang="scss" scoped>
.common-layout {
  background-color: #FFFFFF;
  min-height: 100vh;
  padding: 16px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-content {
  font-size: 16px;
  line-height: 1.5rem;
  text-align: justify;
}

.task-content:deep(p > img, img) {
  display: block;
  width: 100%;
  height: auto;
}

.task-content:deep(img.wscnph) {
  display: block;
  width: 100%;
  height: auto;
}

.date_title{
  font-weight: bold;
  font-size: 18px;
  color: #303133;
  margin-bottom: 8px;
}
.date_subtitle{
  font-size: 14px;
  color: #909399;
  margin-bottom: 16px;
}
ul.dates{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style:none;
  li{
    margin-right: 16px;
    div.date{
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color:   #fef0f0;
      color: #F56C6C;
      border: 1px solid #F56C6C;
      border-radius: 4px;
      padding: 4px 16px;
      cursor: pointer;
      span:first-child{
        margin-bottom: 4px;
      }
      span strong{
        font-size: 16px;
      }
    }
    div.date:hover, div.date.active{
      background-color:#F56C6C;
      color:    #fef0f0;
    }
    div.date.disable{
      background-color:  #f4f4f5;
      color: #909399;
      border-color: #909399;
      cursor:auto;
    }
  }
}
</style>
