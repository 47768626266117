<template lang="">
  <div class="common_layout">
    <v-card class="rounded-0">
      <v-card-title class="d-flex justify-space-between align-center">
        <div>
          <v-btn variant="text" @click="onBack">返回</v-btn>
          {{ test.name }}
        </div>
        <v-spacer />
        <v-btn
          v-if="!isValid"
          prepend-icon="mdi-cart-variant"
          color="black"
          @click="showGoods"
        >
          ￥{{ test.price || '0.00' }} 解锁所有话题
        </v-btn>
      </v-card-title>
    </v-card>
    <div style="height: calc(100vh - 69px)">
      <v-row no-gutters>
        <v-col cols="4">
          <v-card class="ma-4">
            <v-card-text class="overflow-y-auto pa-4">
              <div class="d-flex flex-column justify-start align-stretch py-2">
                <span class="mr-2 font-weight-bold">分类：</span>
                <v-chip-group
                  v-model="currentCate"
                  selected-class="text-indigo"
                  filter
                  mandatory
                  @update="updateCate"
                >
                  <v-chip :key="'Cate' + 0" value="">所有</v-chip>
                  <v-chip
                    v-for="cate in cateList.children"
                    :key="'Cate' + cate.id"
                    :value="cate.id"
                  >{{ cate.label }}</v-chip>
                </v-chip-group>
              </div>
              <div class="d-flex flex-column justify-start align-stretch py-2">
                <span class="mr-2 font-weight-bold">题型：</span>
                <v-chip-group
                  v-model="currentPartType"
                  selected-class="text-indigo"
                  filter
                  mandatory
                  @update="updatePartType"
                >
                  <v-chip :key="'PartType' + 0" value="">所有</v-chip>
                  <v-chip :key="'PartType' + 1" value="PART1">Part 1</v-chip>
                  <v-chip
                    :key="'PartType' + 2"
                    value="PART2&3"
                  >Part 2&3</v-chip>
                  <v-chip :key="'PartType' + 3" value="COMPOSE">串题</v-chip>
                </v-chip-group>
              </div>
              <div class="d-flex flex-column justify-start align-stretch py-2">
                <span class="mr-2 font-weight-bold">新题：</span>
                <v-chip-group
                  v-model="currentNewStatus"
                  selected-class="text-indigo"
                  filter
                  mandatory
                  @update="updateNewStatus"
                >
                  <v-chip :key="'Cate' + 0" value="">所有</v-chip>
                  <v-chip :key="'Cate' + 1" value="1">当季新题</v-chip>
                  <v-chip :key="'Cate' + 2" value="0">保留话题</v-chip>
                </v-chip-group>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <div style="height: calc(100vh - 69px)" class="overflow-y-auto mt-4">
            <TopicListComponent
              :test-id="testId"
              :cate-id="currentCate"
              :new-status="currentNewStatus"
              :part-type="currentPartType"
              :is-valid="isValid"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <SpeakingComponent ref="goodsRef" @success="onSuccess" />
  </div>
</template>
<script setup>
import { ref, computed, provide, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { findTest, validTest } from '@/apis/speaking'
import { findCategoryByCode } from '@/apis/category'

import SpeakingComponent from '@/components/Goods/SpeakingComponent'
import TopicListComponent from './components/TopicListComponent'

const route = useRoute()
const testId = ref('')
testId.value = route.params.testId || ''

const test = ref({})
const fetchTest = async() => {
  const res = await findTest(testId.value)
  test.value = res.data.data
}
fetchTest()

const cateList = ref([])
const fetchCate = async() => {
  const res = await findCategoryByCode('speaking_category')
  console.log('fetchCate', res.data.data)
  cateList.value = res.data.data[0]
}
fetchCate()

const testValid = ref(false)
const handleValid = async() => {
  const res = await validTest(testId.value)
  var _data = res.data.data
  testValid.value = _data !== null && _data !== ''
}
handleValid()

watch(
  () => testValid,
  () => {
    isValid.value = testValid.value || test.value.isFree === '1'
  }
)

const isValid = computed(() => {
  return testValid.value || test.value.isFree === '1'
})

const currentCate = ref('')
const currentPartType = ref('')
const currentNewStatus = ref('')

const updatePartType = (val) => {
  currentPartType.value = val
}

const updateCate = (val) => {
  currentCate.value = val
}

const updateNewStatus = (val) => {
  currentNewStatus.value = val
}

provide('testValid', () => isValid.value)

const router = useRouter()
const onBack = () => {
  router.back()
}

const goodsRef = ref(undefined)
const showGoods = () => {
  goodsRef.value.show()
}

const onSuccess = () => {
  handleValid()
}
</script>
<style lang="scss" scoped>
ul,
dl {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.text-align_center {
  text-align: center;
}

.common_layout {
  height: 100vh;
}

ul.exercise_type {
  padding-left: 24px;
  margin-bottom: 16px;
  color: #58595c;
  border-bottom: 1px solid #eaebef;

  li {
    height: 56px;
    font-size: 18px;
    display: inline-block;
    line-height: 56px;
    margin-right: 48px;
    cursor: pointer;
  }

  li.active {
    font-weight: bold;
    color: #409eff;
    border-bottom: 3px solid #409eff;
  }
}

.note_container {
  .note_content {
    box-sizing: border-box;
    line-height: 36px;
    color: #409eff;
    font-size: 14px;
    background-color: #ecf5ff;
    border-radius: 4px;
  }
}

ul.origin_type,
ul.origin_list {
  display: flex;
  flex-flow: row wrap;

  li {
    width: 108px;
    height: 36px;
    font-size: 14px;
    display: inline-block;
    color: #58595c;
    background-color: #f7f9fc;
    text-align: center;
    line-height: 36px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
  }

  li.active {
    color: #fff;
    background: linear-gradient(135deg, #409eff, #79bbff);
    border: none;
    line-height: 36px;
  }
}

ul.origin_list {
  margin-bottom: 16px;
}

.part_container {
  dl {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    dt {
      padding: 0 24px;
      height: 56px;
      line-height: 56px;
      color: #303133;
      background-color: #f7f9fc;

      .test_title {
        color: #303133;
        font-size: 18px;
        font-weight: bold;
      }
    }

    dd {
      margin: 0;
      padding: 0 24px;
      height: 64px;
      line-height: 64px;
      color: #333;

      .el-row {
        border-bottom: 1px solid #f7f9fc;
      }
    }

    dd:last-child {
      .el-row {
        border-bottom: none;
      }
    }
  }
}

.table_head {
  background-color: #303133;
}
</style>
