const getters = {
  website: (state) => state.common.website,
  userInfo: (state) => state.user.userInfo,
  access_token: (state) => state.user.access_token,
  refresh_token: (state) => state.user.refresh_token,
  // 考试模块
  paperInfo: (state) => state.test.paperInfo,
  isTest: (state) => state.test.isTest,
  currentQuestionNum: (state) => state.test.currentQuestionNum,
  testStartTime: (state) => state.test.testStartTime,
  listeningAnswer: (state) => state.test.listeningAnswer,
  listeningParts: (state) => state.test.listeningParts,
  readingAnswer: (state) => state.test.readingAnswer,
  readingParts: (state) => state.test.readingParts,
  writingParts: (state) => state.test.writingParts,
  writingAnswer: (state) => state.test.writingAnswer,
  testTypes: (state) => state.test.testTypes,
  fontSize: (state) => state.test.fontSize,
  playerType: (state) => state.test.playerType,
  // 会员模块
  memberInfo: (state) => state.member.memberInfo,
  writingInfo: (state) => state.writing.writingInfo
}
export default getters
