<template lang="">
  <el-form
    ref="loginForm"
    :model="loginForm"
    :rules="loginRules"
    class="login-form"
    autocomplete="on"
    label-position="left"
  >
    <el-form-item prop="mobile">
      <el-input
        ref="mobile"
        v-model="loginForm.mobile"
        name="mobile"
        type="text"
        size="large"
        placeholder="请输入手机号"
      >
        <template #prefix>
          <el-icon class="el-input__icon"><Phone /></el-icon>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="code">
      <el-input
        ref="code"
        v-model="loginForm.code"
        name="code"
        type="text"
        size="large"
        placeholder="输入验证码"
      >
        <template #prefix>
          <el-icon class="el-input__icon"><Bell /></el-icon>
        </template>
        <template #append>
          <el-button
            v-if="!isSendSms"
            @click="handleSend"
          >获取验证码</el-button>
          <el-button v-else>{{ seconds }} s</el-button>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="acceptTerms">
      <el-checkbox v-model="acceptTerms" style="margin-right: 10px" />
      我已阅读并同意
      <a
        style="margin: 0 8px"
        target="_blank"
        href="#/post/1505780345866354690"
      >用户协议</a>
      和
      <a
        style="margin: 0 8px"
        target="_blank"
        href="#/post/1505780504507514881"
      >隐私政策</a>.
    </el-form-item>
    <el-button
      :loading="loading"
      type="primary"
      style="width: 100%; margin-bottom: 30px"
      size="large"
      @click.prevent="handleLogin"
    >登录</el-button>
  </el-form>
  <content-component v-if="showContentVisible" ref="showContent" />
</template>
<script>
import { sendSmsCode } from '@/apis/login'
import { ElMessage } from 'element-plus'
import { Bell, Phone } from '@element-plus/icons-vue'

import { isvalidatemobile, validatenull } from '@/utils/validate'

export default {
  name: 'CodeLoginPage',
  components: { Bell, Phone },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (isvalidatemobile(value)[0]) {
        callback(new Error(isvalidatemobile(value)[1]))
      } else {
        callback()
      }
    }

    const validateCode = (rule, value, callback) => {
      if (validatenull(value)) {
        callback(new Error('验证码不能为空'))
      } else if (value.length !== 6) {
        callback(new Error('请输入6位数的验证码'))
      } else {
        callback()
      }
    }

    return {
      activeName: 'user',
      loginForm: {
        mobile: undefined,
        code: undefined
      },
      seconds: 60,
      acceptTerms: false,
      isSendSms: false,
      showContentVisible: false,
      timerInterval: undefined,
      loginRules: {
        mobile: [{ required: true, trigger: 'blur', validator: validatePhone }],
        code: [{ required: true, trigger: 'blur', validator: validateCode }]
      },
      loading: false
    }
  },
  unmounted() {
    this.stopTimer()
  },
  methods: {
    showContent(contentId) {
      this.showContentVisible = true
      this.$nextTick(() => {
        this.$router.push(
          {
            path: '/post',
            query: { contentId: contentId }
          },
          () => {}
        )
      })
    },
    handleSend() {
      var self = this
      // 判断是否可以发送（时间限制）
      if (this.isSendSms) return
      // 发送验证码
      this.$refs.loginForm.validateField('mobile', (valid) => {
        console.log('loginForm', valid)
        if (valid) {
          sendSmsCode(this.loginForm.mobile).then((res) => {
            if (res.data.data) {
              self.startTimer()
              ElMessage.success('验证码发送成功')
            } else {
              ElMessage.error(res.data.msg)
            }
          })
        }
      })
    },
    handleLogin() {
      var self = this
      if (!this.acceptTerms) {
        ElMessage({
          message: 'You must accept terms and privacy to continue!',
          type: 'warning'
        })
        return
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          self.loading = true
          self.$store
            .dispatch('LoginByPhone', self.loginForm)
            .then(() => {
              console.log('Login Success!!')
              self.$store.dispatch('GetUserInfo')
              self.$store.dispatch('getMemberInfo')
              self.$store.dispatch('getWritingInfo')
              self.$router.push(
                {
                  path: self.redirect || '/',
                  query: self.otherQuery
                },
                () => {}
              )
              self.stopTimer()
              self.loading = false
            })
            .catch(() => {
              self.stopTimer()
              self.loading = false
            })
        }
      })
    },
    startTimer() {
      var self = this
      // Get limit in seconds

      this.isSendSms = true
      // Update the count down every 1 second
      this.timerInterval = setInterval(function() {
        // One second has passed
        self.seconds = self.seconds - 1

        // If the count down is finished, write some text
        if (self.seconds === 0) {
          self.stopTimer()
        }
      }, 1000)
    },
    stopTimer() {
      clearInterval(this.timerInterval)
      this.isSendSms = false
    }
  }
}
</script>
<style lang="scss" scoped>
// .login-form{
//   background-color: white;
// }
</style>
