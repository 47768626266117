<template lang="">
  <div v-if="activity.startQuestionNum === activity.endQuestionNum" class="activity-questions bold">Question {{ activity.startQuestionNum }}</div>
  <div v-else class="activity-questions bold">Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</div>
  <div class="activity-title" v-html="activity.activityTitle" />
  <div class="activity-note" v-html="activity.activitySubTitle" />
  <ul class="questions">
    <li v-for="question in activity.questionList" :key="question.questionNum" style="margin-bottom:20px;">
      <div :class="'question-title font-weight-bold '+ ((getResult(question.questionNum).isRight || getResult(question.questionNum).right) ?'right':'wrong') + (currentNum === question.questionNum?' highlight':'')" @click="changeNum(question.questionNum)">
        <span class="q-number">{{ question.questionNum }}.</span> {{ question.questionTitle }}
      </div>
      <ul class="answers__">
        <li v-for="answer in JSON.parse(question.questionContent)" :key="answer.index">
          <template v-if="getResult(question.questionNum).isRight || getResult(question.questionNum).right">
            <template v-if="answer.isRight">
              <Icon name="radio-circle-marked" class="checkIcon right" />
              <span class="right">
                <strong>{{ answer.index }}</strong> {{ answer.content }}
              </span>
            </template>
            <template v-else>
              <Icon name="radio-circle" class="checkIcon" />
              <span>
                <strong>{{ answer.index }}</strong> {{ answer.content }}
              </span>
            </template>
          </template>
          <template v-else>
            <template v-if="answer.isRight">
              <Icon name="radio-circle-marked" class="checkIcon" :class="{'wrong':getAnswer(question.questionNum) === 'No answer', 'right':getAnswer(question.questionNum) !== 'No answer'}" />
              <span :class="{'wrong':getAnswer(question.questionNum) === 'No answer', 'right':getAnswer(question.questionNum) !== 'No answer'}">
                <strong>{{ answer.index }}</strong> {{ answer.content }}
              </span>
            </template>
            <template v-else>
              <Icon v-if="answer.index === getAnswer(question.questionNum)" name="radio-circle-marked" class="checkIcon wrong" />
              <Icon v-else name="radio-circle" class="checkIcon" />
              <span :class="{'wrong':answer.index === getAnswer(question.questionNum)}">
                <strong>{{ answer.index }}</strong> {{ answer.content }}
              </span>
            </template>
          </template>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'Answer0201Component',
  inject: ['listeningAnswers', 'currentNum', 'changeNum'],
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: []
    }
  },
  methods: {
    getResult(num) {
      var r
      this.listeningAnswers.value.forEach(e => {
        if (e.questionNum === num) {
          r = e
          return
        }
      })
      return r
    },
    getAnswer(num) {
      var r
      this.listeningAnswers.value.forEach(e => {
        if (e.questionNum === num) {
          r = e
          return
        }
      })
      return JSON.parse(r.yourAnswer).toString()
    }
  }

}
</script>
<style lang="scss" scoped>
.activity-questions {
  margin-bottom: 15px;
}

.activity-title {
  margin-bottom: 5px;
}

.activity-content table {
  border: 1px solid #F3F3F3;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.questions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

ul.questions li {
  flex: 0 0 100%;
  margin: 0 0 20px 0;
  font-size: 14px;
}

ul.questions li>.question-title {
  font-size: 16px;
  margin-bottom: 5px;
  cursor: pointer;
}

ul.questions li>.question-title.right.highlight {
  background-color: #f0f9eb;
  border: 1px solid #40d039;
  color: #40d039;
  border-radius: 5px;
  padding: 5px 10px;
}

ul.questions li>.question-title.wrong.highlight {
  background-color: #fef0f0;
  border: 1px solid #E31837;
  color: #E31837;
  border-radius: 5px;
  padding: 5px 10px;
}

ul.answers__ li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.answers__ li span.right {
  color: #40d039;
}

.answers__ li span.wrong {
  color: #E31837;
}

.answers__ li strong {
  margin-right: 10px;
}

.checkIcon {
  width: 25px;
  height: 25px;
  margin-right: 2px;
}

.checkIcon.right {
  color: #40d039;
}

.checkIcon.wrong {
  color: #E31837;
}
</style>
