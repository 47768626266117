<template>
  <v-app>
    <div class="common-layout">
      <el-container>
        <el-aside :class="{ 'hide-sidebar': isCollapse }">
          <el-menu
            default-active="/"
            :contenteditable="defaultActive"
            :collapse="isCollapse"
            :collapse-transition="false"
            :unique-opened="false"
            style="height: calc(100vh - 48px)"
            active-text-color="#FEC171"
            background-color="#1c2233"
            text-color="#bfcbd9"
            mode="vertical"
            router
          >
            <el-menu-item index="/">
              <el-icon>
                <HomeFilled />
              </el-icon>
              <template #title>首页</template>
            </el-menu-item>
            <el-menu-item index="/paper">
              <el-icon>
                <Notebook />
              </el-icon>
              <template #title>雅思机考</template>
            </el-menu-item>
            <!-- <el-menu-item index="/part/listening">
              <el-icon>
                <Headset />
              </el-icon>
              <template #title>雅思精听</template>
            </el-menu-item> -->
            <el-menu-item v-show="writingPublish" index="/writing">
              <el-icon>
                <EditPen />
              </el-icon>
              <template #title>作文批改</template>
            </el-menu-item>
            <!-- <el-menu-item index="/speak">
              <el-icon>
                <Mic />
              </el-icon>
              <template #title>口语资料</template>
            </el-menu-item> -->
            <el-menu-item index="/speaking">
              <el-icon>
                <Mic />
              </el-icon>
              <template #title>口语题库</template>
            </el-menu-item>
            <el-menu-item index="/course">
              <el-icon>
                <VideoCameraFilled />
              </el-icon>
              <template #title>精品课程</template>
            </el-menu-item>
            <el-divider border-style="dashed" />
            <el-menu-item index="/feedback">
              <el-icon>
                <HelpFilled />
              </el-icon>
              <template #title>问题反馈</template>
            </el-menu-item>
            <el-menu-item index="#" @click="onLogout">
              <el-icon>
                <Tools />
              </el-icon>
              <template #title>退出登录</template>
            </el-menu-item>
          </el-menu>
          <div class="top-collapse" @click="handleCollapse">
            <div v-if="isCollapse" class="tooltip-base-box">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="展开菜单"
                placement="展开菜单"
              >
                <span>
                  <font-awesome-icon icon="fa-solid fa-bars" size="lg" />
                </span>
              </el-tooltip>
            </div>
            <span v-else>
              <font-awesome-icon
                icon="fa-solid fa-angles-left"
                size="lg"
                style="margin-right: 5px"
              />收起
            </span>
          </div>
        </el-aside>
        <el-main style="height: 100vh; padding: 0">
          <router-view :key="key" v-slot="{ Component }">
            <transition name="fade-transform" mode="out-in">
              <keep-alive>
                <component :is="Component" />
              </keep-alive>
            </transition>
          </router-view>
        </el-main>
      </el-container>
    </div>
  </v-app>
</template>
<script>
import {
  HelpFilled,
  Notebook,
  Mic,
  Tools,
  HomeFilled,
  EditPen,
  Headset,
  VideoCameraFilled
} from '@element-plus/icons-vue'
import { checkToken } from '@/apis/login'

import { getObj } from '@/apis/sys_param'

export default {
  name: 'LayoutComponent',
  components: {
    HelpFilled,
    Notebook,
    Mic,
    Tools,
    HomeFilled,
    EditPen,
    Headset,
    VideoCameraFilled
  },
  data() {
    return {
      defaultActive: '1',
      isCollapse: false,
      // 刷新token锁
      refreshLock: false,
      // 刷新token的时间
      refreshTime: '',
      writingPublish: false
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  created() {
    // 实时检测刷新token
    this.refreshToken()
    this.getWrtingPublic()
    this.handleResize()
  },
  destroyed() {
    clearInterval(this.refreshTime)
  },
  methods: {
    handleResize() {
      var _this = this
      window.addEventListener('resize', function() {
        if (window.innerWidth <= 1024) {
          console.log(window.innerWidth)
          _this.isCollapse = true
        } else {
          _this.isCollapse = false
        }
      })
    },
    onLogout() {
      this.$confirm('是否退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.dispatch('clearMemberInfo')
          this.$store.dispatch('clearWritingInfo')
          this.$store.dispatch('LogOut').then(() => {
            clearInterval(this.refreshTime)
            this.$router.replace({ path: '/login' })
          })
        })
        .catch((e) => {
          console.log('取消退出')
        })
    },
    handleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key
    },
    refreshToken() {
      this.refreshTime = setInterval(() => {
        checkToken(this.refreshLock, this.$store, this.refreshTime)
      }, 60000)
    },
    getWrtingPublic() {
      getObj('WRITING_PUBLISH_KEY').then((res) => {
        this.writingPublish = res.data.data === '1'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  margin-top: -50px;
  margin-right: 50px;
}

.top-collapse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  margin-right: 1px;
  height: 48px;
  cursor: pointer;

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #bfcbd9;
  }
}

.el-aside {
  transition: width 0.28s;
  width: 180px !important;
}

.el-aside.hide-sidebar {
  width: 64px !important;
}

.tooltip-base-box .box-item {
  width: 110px;
  margin-top: 10px;
}
</style>
