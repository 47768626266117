<template lang="">
  <div class="card" :class="{'highlight':isHighlight}">
    <div class="card-header question-text" @click="toggleHandle">
      <span class="q-number">{{ (questionNum===undefined || questionNum === '') ? question.questionNum: questionNum }}.</span>
      <span>{{ question.questionTitle }}</span>
    </div>
    <transition>
      <div class="card-body">
        <slot :question="question" />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    questionNum: {
      type: String,
      default: undefined
    },
    question: {
      type: Object,
      default: undefined
    },
    answerList: {
      type: Array,
      default: undefined
    },
    isHighlight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  created() {
  },
  methods: {
    toggleHandle() {
      this.$store.dispatch('setCurrentQuestionNum', this.question.questionNum)
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  border: 1px solid #DCDFE6;
  border-radius: 5px;
}

.card.small {
  .card-header {
    font-size: 14px;
  }
}

.card.standard {
  .card-header {
    font-size: 16px;
  }
}

.card.large {
  .card-header {
    font-size: 18px;
  }
}

.card.extra_large {
  .card-header {
    font-size: 20px;
  }
}

.card-header {
  padding: 10px 15px;
  border-radius: 5px 5px 0 0;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
}

.highlight.card {
  // color: #409EFF;
  border: 1px solid #409EFF;
  .card-header {
    background-color: #ecf5ff;
  }

  .card-body {
    background-color: #ecf5ff;
  }
}

.card-header .q-number {
  font-weight: bold;
  margin-right: 5px;
}

.card-body {
  padding: 10px 15px;
  background-color: white;
  border-radius: 0 0 5px 5px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
