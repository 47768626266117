<template lang="">
  <el-container class="on-test">
    <el-header>
      <top-bar v-if="paper.paperType === '1'" :limit-minutes="35" :start="true" :hide-time="false" :audio-model="audio" :test-type="'Listening'" @handle-finish="handleFinish" />
      <top-bar-practice v-else :start="true" :test-type="'Listening'" @handle-finish="handleFinish" />
    </el-header>
    <el-main class="app-main">
      <div id="highlightable-content">
        <div class="test-container">
          <div class="part-title">
            <el-row>
              <el-col :span="14">
                <div style="display:flex; flex-direction:column; justify-content:center;">
                  <h3>Part {{ currentPartIndex + 1 }}</h3>
                  <p>Listen and answer questions {{ parts[currentPartIndex].startQuestionNum }} - {{ parts[currentPartIndex].endQuestionNum }}.</p>
                </div>
              </el-col>
              <el-col :span="10" style="text-align:right;">
                <div class="audio-wrapper">
                  <template v-if="paper.paperType === '0'">
                    <el-switch
                      v-model="continuous"
                      inline-prompt
                      active-text="连续播放"
                      inactive-text="随题播放"
                      style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949;margin-right:8px;"
                    />
                  </template>
                  <template v-if="paper.paperType === '0'">
                    <template v-if="playerType==='1'">
                      <AudioPlayer ref="audioPart" :auto-play="true" :audios="audios" :controls="'noMuted noBackward noForward'" />
                    </template>
                    <template v-else>
                      <audio ref="audioPart" style="width:300px" autoplay :controls="paper.paperType === '0'" controlsList="nodownload" @ended="onEnded">
                        <source :src="audios[0]" type="audio/mpeg">
                        您的浏览器不支持 audio 元素。
                      </audio>
                    </template>
                  </template>
                  <template v-else>
                    <audio ref="audioPart" autoplay @ended="onEnded">
                      <source :src="audios[0]" type="audio/mpeg">
                      您的浏览器不支持 audio 元素。
                    </audio>
                  </template>
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-for="part in parts" v-show="currentPartId === part.partId" :key="'part-' + part.partId" class="part-container">
            <el-scrollbar ref="scrollbarRef" class="part-content" @scroll="scroll">
              <section v-for="activity in part.activityList" :key="'section'+activity.activityId" class="activity">
                <question-0201 v-if="activity.activityTypeId === '48'" :activity="activity" />
                <question-0203 v-if="activity.activityTypeId === '49'" :activity="activity" />
                <question-0204 v-if="activity.activityTypeId === '51'" :activity="activity" />
                <question-0205 v-if="activity.activityTypeId === '50'" :activity="activity" />
              </section>
            </el-scrollbar>
          </div>
          <el-row class="question-navigation">
            <el-col :span="2">
              <div class="input-group">
                <button class="btn btn-block review" @click="handleReview"><span :class="'check '+(isReview ? 'checked':'') " />Review</button>
              </div>
            </el-col>
            <el-col :span="19">
              <div class="question-nav">
                <ul class="question-buttons">
                  <template v-for="(part, index) in parts" :key="'question-buttons-' + part.partId">
                    <li class="part-label-holder">
                      <div class="btn disabled part-label">Part {{ index+1 }}:</div>
                    </li>
                    <template v-for="num in part.endQuestionNum ">
                      <li v-if="num >= part.startQuestionNum" :key="num">
                        <answer-button :part-id="part.partId" :question-num="num" :review-list="reviewList" @on-handel="onHandel" />
                      </li>
                    </template>
                  </template>
                </ul>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="arrows">
                <div class="row">
                  <div class="col">
                    <button v-if="currentNum===1" disabled class="btn prev" @click="onPrev"><span class="arrow-left" /></button>
                    <button v-else class="btn prev" @click="onPrev"><span class="arrow-left" /></button>
                    <button v-if="currentNum===40" disabled class="btn next" @click="onNext"><span class="arrow-right" /></button>
                    <button v-else class="btn next" @click="onNext"><span class="arrow-right" /></button>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import TopBar from '@/components/TopBar'
import TopBarPractice from '@/components/TopBarPractice'
import { mapGetters } from 'vuex'
import { animate } from 'popmotion'

import Question0201 from '../components/questions/Question0201'
import Question0203 from '../components/questions/Question0203'
import Question0204 from '../components/questions/Question0204'
import Question0205 from '../components/questions/Question0205'

import AnswerButton from '../components/buttons/ListeningAnswerButton'

import { initContextMenu, destroyContextMenu } from '@/plugins/highlight'

import AudioPlayer from '@/components/AudioPlayer/AudioPlayer.vue'

export default {
  name: 'ListeningTestPage',
  components: { TopBar, TopBarPractice, AnswerButton, Question0201, Question0203, Question0204, Question0205, AudioPlayer },
  emits: ['listeningNextStep', 'listening-next-step'],
  data() {
    return {
      queModels: {},
      testAudioPlaying: false,
      volumeValue: 25,
      audio: undefined,
      currentPartIndex: 0,
      currentPartId: undefined,
      reviewList: [],
      isReview: false,
      audios: [],
      currentAudio: 0,
      parts: [],
      continuous: true,
      useNativePlayer: true,
      audioUrl: undefined,
      isSafari: false
    }
  },
  computed: {
    ...mapGetters({ paper: 'paperInfo', listeningParts: 'listeningParts', currentNum: 'currentQuestionNum', startTime: 'testStartTime', playerType: 'playerType' }),
    getReview() {
      return this.currentNum
    },
    getCurrentNum() {
      return this.currentNum
    }
  },
  watch: {
    getReview: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.isReview = this.indexOf(this.reviewList, this.currentNum) >= 0
      }
    },
    getCurrentNum: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
      }
    }
  },
  mounted() {
    initContextMenu()
  },
  unmounted() {
    // this.$refs.audioPart.stop()
    destroyContextMenu()
  },
  created() {
    var _audios = []
    this.parts = this.listeningParts
    this.parts.forEach((p) => {
      _audios.push(p.audioUrl)
    })
    this.audios = _audios

    this.currentPartId = this.parts[0].partId

    if (this.startTime === undefined || this.startTime === '') {
      this.$store.dispatch('setTestStartTime', new Date())
    }

    var br = this.browser()
    if (br[0] === 'Safari') {
      console.log('Safari') // 是否为Safari浏览器
      this.isSafari = true
    }
  },
  methods: {
    changeAudio(index) {
      if (this.currentAudio !== index) {
        this.currentAudio = index
        this.$refs.audioPart.src = this.audios[index]
        this.$refs.audioPart.play()
      }
    },
    onEnded() {
      var _len = this.audios.length
      var _cur = this.currentAudio
      if (_cur < _len - 1) {
        this.changeAudio(_cur + 1)
      } else {
        this.$refs.audioPart.stop()
      }
    },
    onHandel(data) {
      this.currentPartId = data.partId
      this.getPartIndexById(data.partId)
    },
    handleFinish(val) {
      this.$emit('listeningNextStep', val)
    },
    onPrev() {
      if (this.currentNum > 1) {
        this.getPartIndex(this.currentNum - 1)
        this.$store.dispatch('setCurrentQuestionNum', this.currentNum - 1)
        this.scrollToCmtList(this.currentNum)
      }
    },
    onNext() {
      if (this.currentNum < 40) {
        this.getPartIndex(this.currentNum + 1)
        this.$store.dispatch('setCurrentQuestionNum', this.currentNum + 1)
        this.scrollToCmtList(this.currentNum)
      }
    },
    handleReview() {
      var has = this.indexOf(this.reviewList, this.currentNum) >= 0
      if (!has) {
        this.reviewList.push(this.currentNum)
        this.isReview = true
      } else {
        this.remove(this.reviewList, this.currentNum)
        this.isReview = false
      }
    },
    indexOf(list, val) {
      for (var i = 0; i < list.length; i++) {
        if (list[i] === val) return i
      }
      return -1
    },
    remove(list, val) {
      var _index = this.indexOf(list, val)
      if (_index > -1) {
        list.splice(_index, 1)
      }
      return list
    },
    getPartIndex(num) {
      var _this = this
      var i = 0
      this.parts.forEach(e => {
        if (e.startQuestionNum <= num && e.endQuestionNum >= num) {
          _this.currentPartId = e.partId
          _this.currentPartIndex = i
          if (_this.paper.paperType === '0' && !_this.continuous) {
            if (_this.playerType === '2') {
              _this.changeAudio(i)
            } else {
              _this.$refs.audioPart.changeAudio(i)
            }
          }
          return
        }
        i++
      })
    },
    getPartIndexById(partId) {
      var _this = this
      var i = 0
      this.parts.forEach(e => {
        if (e.partId === partId) {
          _this.currentPartIndex = i
          if (_this.paper.paperType === '0' && !_this.continuous) {
            if (_this.playerType === '2') {
              _this.changeAudio(i)
            } else {
              _this.$refs.audioPart.changeAudio(i)
            }
          }
          return
        }
        i++
      })
    },
    scroll(v) {
      // console.log('bar', v)
    },
    scrollToCmtList(num) {
      // var _this = this
      // 1. 当前滚动条的位置
      const now = window.scrollY

      // 2. 目标位置（文章信息区域的高度）
      var _que = document.querySelector('#question_' + num)
      var dist = now
      if (_que != null) {
        dist = document.querySelector('#question_' + num).scrollTop
      }

      // 3. 实现滚动动画
      animate({
        from: now, // 当前的位置
        to: dist, // 目标位置
        onUpdate: latest => {
          window.scrollTo(0, latest)
          // _this.$refs.scrollbarRef.value.setScrollTop(latest)
        }
      })
    },
    browser() {
      var ua = window.navigator.userAgent

      var ret = ''

      if (/Firefox/g.test(ua)) {
        ua = ua.split(' ')

        ret = 'Firefox|' + ua[ua.length - 1].split('/')[1]
      } else if (/MSIE/g.test(ua)) {
        ua = ua.split(';')

        ret = 'IE|' + ua[1].split(' ')[2]
      } else if (/Opera/g.test(ua)) {
        ua = ua.split(' ')

        ret = 'Opera|' + ua[ua.length - 1].split('/')[1]
      } else if (/Chrome/g.test(ua)) {
        ua = ua.split(' ')

        ret = 'Chrome|' + ua[ua.length - 2].split('/')[1]
      } else if (/^apple\s+/i.test(navigator.vendor)) {
        ua = ua.split(' ')

        ret = 'Safari|' + ua[ua.length - 2].split('/')[1]
      } else {
        ret = '未知浏览器'
      }

      return ret.split('|')
    }
  }
}
</script>
<style lang="scss" scoped>
.activity{
  margin-bottom: 40px;
}

.audio-wrapper {
  display:flex;
  flex-direction:row;
  justify-content:end;
  align-items:center;
  flex:1;
  height: 60px;
}
</style>
