<template lang="">
  <el-input
    :id="'question_' + questionNum"
    v-model="questionAnswer"
    class="question-input"
    :placeholder="questionNum"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    @focus="onFocus(questionNum)"
    @click="onFocus(questionNum)"
    @change="onHandleAnswer($event, questionNum)"
    @input="onInput($event, questionNum)"
  />
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'QuestionInput',
  data() {
    return {
      questionNum: undefined,
      questionAnswer: undefined
    }
  },
  computed: {
    ...mapGetters({ readingAnswer: 'readingAnswer', currentQuestionNum: 'currentQuestionNum' }),
    getAnswers() {
      return this.listeningAnswer
    },
    getCurrentNum() {
      return this.currentQuestionNum
    }
  },
  watch: {
    getCurrentNum: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.handleFocus()
      }
    },
    getAnswers: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        var _this = this
        this.readingAnswer.forEach(element => {
          if (element.questionNumber === parseInt(_this.questionNum)) {
            _this.questionAnswer = element.yourAnswer
          }
        })
      }
    }
  },
  created() {
    var _questionNum = this.$slots.default()[0].children
    _questionNum = _questionNum.replace('【', '')
    _questionNum = _questionNum.replace('】', '')
    this.questionNum = _questionNum

    var _this = this
    this.readingAnswer.forEach(element => {
      if (element.questionNumber === parseInt(_this.questionNum)) {
        _this.questionAnswer = element.yourAnswer
      }
    })
  },
  methods: {
    onInput(e, questionNum) {
      this.onHandleAnswer(e, parseInt(questionNum))
    },
    onFocus(questionNum) {
      this.$store.dispatch('setCurrentQuestionNum', questionNum)
    },
    onHandleAnswer(e, questionNum) {
      this.$store.dispatch('saveReadingAnswer', {
        'questionNumber': questionNum,
        'yourAnswer': e
      })
    },
    handleFocus() {
      var node = document.getElementById('question_' + this.currentQuestionNum)
      if (node) {
        // node.scrollIntoView({
        //   behavior: 'smooth'
        // })
        node.focus()
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.question-input{
  max-width: 200px;
  min-width: 150px;
  margin: 5px;
  border-radius: 4px;
  border:1px solid #606266;
  overflow: visible;
}

.question-input:deep(input){
  outline:0;
  padding:2px 0;
  font-size: 16px;
  text-align: left;
  border-radius: 4px;
  font-weight: bold;
  color: #222222;
}
.question-input:deep(input::placeholder){
  color: #303133;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
</style>
