<template lang="">
  <p class="activity-questions bold">{{ activity.activityType }}</p>
  <p
    v-if="activity.startQuestionNum === activity.endQuestionNum"
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Question {{ activity.startQuestionNum }}</p>
  <p
    v-else
    class="activity-questions bold"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
  >Questions {{ activity.startQuestionNum }} - {{ activity.endQuestionNum }}</p>
  <p
    class="activity-title"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activityTitle"
  />
  <p
    class="activity-note"
    :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
    v-html="activity.activitySubTitle"
  />
  <ul class="questions">
    <li
      v-for="question in activity.questionList"
      :id="'question_' + question.questionNum"
      :key="question.questionNum"
      style="margin-bottom:15px;"
    >
      <reading-question-card :question="question" :is-highlight="question.questionNum === currentNum">
        <template #default="slotProps">
          <hi-radio-group
            v-model="queModels[slotProps.question.questionNum]"
            :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
            @change="onChange($event, slotProps.question.questionNum)"
          >
            <hi-radio v-for="answer in JSON.parse(question.questionContent)" :key="answer.index" :label="answer.index" :name="'question'+question.questionNum">
              <strong>{{ answer.index }}</strong> {{ answer.content }}
            </hi-radio>
          </hi-radio-group>
        </template>
      </reading-question-card>
    </li>
  </ul>
</template>
<script>
import { mapGetters } from 'vuex'
import ReadingQuestionCard from '../ReadingQuestionCard'
import HiRadio from '../radio/Radio'
import HiRadioGroup from '../radio/RadioGroup'
export default {
  name: 'Question0301Component',
  components: { ReadingQuestionCard, HiRadio, HiRadioGroup },
  props: {
    activity: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      queModels: [],
      answerList: [],
      size: '0'
    }
  },
  computed: {
    ...mapGetters({ fontSize: 'fontSize', readingAnswer: 'readingAnswer', currentNum: 'currentQuestionNum' }),
    getFontSize() {
      return this.fontSize
    },
    getCurrentNum() {
      return this.currentNum
    }
  },
  watch: {
    getCurrentNum: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.handleFocus()
      }
    },
    getFontSize: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.size = this.fontSize
      }
    }
  },
  created() {
    var _this = this
    this.readingAnswer.forEach(element => {
      _this.queModels[element.questionNumber] = element.yourAnswer
    })

    this.size = this.fontSize
  },
  mounted() {
    this.size = this.fontSize
    this.$nextTick(() => {
      this.handleFocus()
    })
  },
  methods: {
    onChange(e, questionNum) {
      this.$store.dispatch('setCurrentQuestionNum', questionNum)
      this.$store.dispatch('saveReadingAnswer', {
        'questionNumber': questionNum,
        'yourAnswer': e.target.value
      })
    },
    handleFocus() {
      var node = document.getElementById('question_' + this.currentNum)
      if (node) {
        // node.scrollIntoView({
        //   behavior: 'smooth'
        // })
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.activity-questions{
  margin-bottom: 15px;
}
.activity-title{
  margin-bottom: 5px;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.questions{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
ul.questions li{
  flex: 0 0 100%;
  margin: 0 0 10px 0;
}

ul.questions::v-deep .el-radio-group{
  display: block;
}

ul.questions::v-deep .el-radio{
  display: block;
  margin-bottom: 5px;
  height: auto;
}

ul.questions::v-deep .el-radio__label {
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.7em;
  vertical-align: top;
  display: inline-block;
}
</style>
