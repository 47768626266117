<template lang="">
  <el-container class="on-test">
    <el-header>
      <top-bar v-if="paper.paperType === '1'" :limit-minutes="60" :start="true" :hide-time="false" :test-type="'Writing'" @handle-finish="handleFinish" />
      <top-bar-practice v-else :start="true" :test-type="'Writing'" @handle-finish="handleFinish" />
    </el-header>
    <el-main class="app-main">
      <div id="highlightable-content">
        <div class="test-container">
          <div v-if="currentNum === 1" class="part-title">
            <h3> Task 1</h3>
            <div>You should spend about 20 minutes on this task.Write at leaste 150 words.</div>
          </div>
          <div v-else class="part-title">
            <h3> Task 2</h3>
            <div>You should spend about 40 minutes on this task. Write at leaste 250 words.</div>
          </div>
          <div v-for="part in parts" :key="'part-' + part.partId">
            <div v-show="currentPartId === part.partId" class="part-container">
              <div class="part-content">
                <el-row :gutter="20">
                  <el-col :span="13">
                    <el-scrollbar height="calc(100vh - 251px)" style="padding-right:16px;">
                      <section v-for="activity in part.activityList" :key="'section'+activity.activityId" class="activity needs-focus">
                        <div
                          class="part-content-en"
                          :class="{'small':size==='-2', 'standard':size==='0', 'large':size==='2', 'extra_large':size==='4'}"
                          v-html="activity.activityContent"
                        />
                      </section>
                    </el-scrollbar>
                  </el-col>
                  <el-col :span="11">
                    <TaskComponent :task="queModels[part.partId]" :part-id="part.partId" :type-category-id="part.typeCategoryId" @on-input="onHandleAnswer" />
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <el-row class="question-navigation">
            <el-col :span="2">
              <div class="input-group">
                <button class="btn btn-block review" @click="handleReview"><span class="check" :class="{'checked':isReview}" />Review</button>
              </div>
            </el-col>
            <el-col :span="19">
              <div class="question-nav">
                <ul class="question-buttons">
                  <li class="part-label-holder">
                    <div class="btn disabled part-label">Task 1:</div>
                  </li>
                  <li>
                    <button class="btn font-weight-bolder pointer" :class="{'highlight':currentNum == 1,'answered':questionAnswer[0] != undefined && questionAnswer[0] != '', 'for-review':review1}" @click="onClick(1)" @tap="onClick(1)">1</button>
                  </li>
                  <li class="part-label-holder">
                    <div class="btn disabled part-label">Task 2:</div>
                  </li>
                  <li>
                    <button class="btn font-weight-bolder pointer" :class="{'highlight':currentNum == 2,'answered':questionAnswer[1] != undefined && questionAnswer[1] != '', 'for-review':review2}" @click="onClick(2)" @tap="onClick(2)">2</button>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="arrows">
                <div class="row">
                  <div class="col">
                    <button v-if="currentNum===1" disabled class="btn prev" @click="onPrev"><span class="arrow-left" /></button>
                    <button v-else class="btn prev" @click="onPrev"><span class="arrow-left" /></button>
                    <button v-if="currentNum===2" disabled class="btn next" @click="onNext"><span class="arrow-right" /></button>
                    <button v-else class="btn next" @click="onNext"><span class="arrow-right" /></button>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import TopBar from '@/components/TopBar'
import TopBarPractice from '@/components/TopBarPractice'
import { mapGetters } from 'vuex'

import TaskComponent from './components/TaskComponent'

import { initContextMenu, destroyContextMenu } from '@/plugins/highlight'
export default {
  name: 'WritingTestPage',
  components: { TopBar, TopBarPractice, TaskComponent },
  emits: ['writing-next-step'],
  data() {
    return {
      queModels: [],
      questionAnswer: [],
      currentPartId: undefined,
      currentNum: 1,
      reviewList: [],
      isReview: false,
      parts: [],
      review1: false,
      review2: false,
      tmpAnswer: undefined,
      words: 0,
      size: '0'
    }
  },
  computed: {
    ...mapGetters({ fontSize: 'fontSize', paper: 'paperInfo', writingParts: 'writingParts', writingAnswer: 'writingAnswer', startTime: 'testStartTime' }),
    getAnswers() {
      return this.writingAnswer
    },
    getFontSize() {
      return this.fontSize
    }
  },
  watch: {
    getAnswers: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        var _this = this
        this.questionAnswer = []
        this.writingAnswer.forEach(element => {
          _this.queModels[element.partId] = element.yourAnswer
          this.questionAnswer.push(element.yourAnswer)
        })
      }
    },
    getFontSize: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.size = this.fontSize
      }
    }
  },
  created() {
    this.parts = this.writingParts
    this.currentPartId = this.parts[0].partId

    var _this = this
    this.writingAnswer.forEach(element => {
      _this.queModels[element.partId] = element.yourAnswer
      this.questionAnswer.push(element.yourAnswer)
    })

    if (this.startTime === undefined || this.startTime === '') {
      this.$store.dispatch('setTestStartTime', new Date())
    }
  },
  mounted() {
    this.size = this.fontSize
    initContextMenu()
  },
  unmounted() {
    destroyContextMenu()
  },
  methods: {
    getAnswer(task) {

    },
    onHandleAnswer(e, partId) {
      this.$store.dispatch('saveWritingAnswer', {
        'partId': partId,
        'yourAnswer': e
      })
    },
    onClick(questionNum) {
      this.currentNum = questionNum
      this.currentPartId = this.parts[questionNum - 1].partId
      this.isReview = this.indexOf(questionNum) >= 0
    },
    handleFinish(val) {
      this.$emit('writing-next-step', val)
    },
    onPrev() {
      if (this.currentNum > 1) {
        this.getPartIndex(this.currentNum - 1)
      }
    },
    onNext() {
      if (this.currentNum < 2) {
        this.getPartIndex(this.currentNum + 1)
      }
    },
    handleReview() {
      var has = this.indexOf(this.currentNum) >= 0
      if (!has) {
        this.reviewList.push(this.currentNum)
        this.isReview = true
      } else {
        this.remove(this.currentNum)
        this.isReview = false
      }
      this.checkReview()
    },
    checkReview() {
      this.review1 = this.indexOf(1) >= 0
      this.review2 = this.indexOf(2) >= 0
    },
    indexOf(val) {
      var list = this.reviewList
      for (var i = 0; i < list.length; i++) {
        if (list[i] === val) return i
      }
      return -1
    },
    remove(val) {
      var list = this.reviewList
      var _index = this.indexOf(val)
      if (_index > -1) {
        list.splice(_index, 1)
      }
      return list
    },
    getPartIndex(num) {
      this.currentPartId = this.parts[num - 1].partId
      this.currentNum = num
      this.isReview = this.indexOf(this.currentNum) >= 0
      this.checkReview()
    },
    getPartIndexById(partId) {
      var _this = this
      var i = 0
      this.parts.forEach(e => {
        if (e.partId === partId) {
          _this.currentNum = i + 1
          return
        }
        i++
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.activity{
  margin-bottom: 20px;
}
.part-content-en{
  text-align: justify;
  line-height: 1.75rem;
  padding-top: 10px;
}

.part-content-en:deep(img){
  display: block;
  width: 80%;
  height: auto;
  margin: 0 auto;
}

.part-content-en.small{
  font-size: 14px;
}
.part-content-en.standard{
  font-size: 16px;
}
.part-content-en.large{
  font-size: 18px;
}
.part-content-en.extra_large{
  font-size: 20px;
}
</style>
