import { getStore, setStore } from '@/utils/store'
import { findMember } from '@/apis/member'
const state = {
  memberInfo: getStore({
    name: 'member_info'
  }) || {}
}

const mutations = {
  SET_MEMBER_INFO: (state, memberInfo) => {
    state.memberInfo = memberInfo
    setStore({
      name: 'member_info',
      content: state.memberInfo
    })
  }
}

const actions = {
  getMemberInfo({ commit }, memberInfo) {
    findMember().then(res => {
      const data = res.data.data || {}
      commit('SET_MEMBER_INFO', data)
    })
  },
  clearMemberInfo({ commit }) {
    commit('SET_MEMBER_INFO', {})
  }
}

const member = {
  state,
  actions,
  mutations
}

export default member
