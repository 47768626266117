<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card color="#1F7087" theme="dark">
          <div class="d-flex flex-no-wrap justify-space-between align-center">
            <div>
              <v-card-title class="text-h5">Useful IELTS Language</v-card-title>
              <v-card-subtitle>实用的雅思语言表达</v-card-subtitle>
              <v-card-actions>
                <router-link to="/nine/speaking/content/1648264020884197377">
                  <v-btn variant="text">查看详情</v-btn>
                </router-link>
              </v-card-actions>
            </div>
            <v-icon icon="mdi-newspaper-variant" :size="40" class="mr-4" />
          </div>
        </v-card>
      </v-col>
      <!-- <v-col cols="12">
        <v-card color="#558B2F" theme="dark">
          <div class="d-flex flex-no-wrap justify-space-between align-center">
            <div>
              <v-card-title class="text-h5">Useful IELTS Language</v-card-title>
              <v-card-subtitle>图书部分数字内容</v-card-subtitle>
              <v-card-actions>
                <router-link to="/nine/speaking/book">
                  <v-btn variant="text">查看详情</v-btn>
                </router-link>
              </v-card-actions>
            </div>
            <v-icon icon="mdi-book-open-page-variant" :size="40" class="mr-4" />
          </div>
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12">
        <v-card color="#FF6F00" theme="dark">
          <div class="d-flex flex-no-wrap justify-space-between align-center">
            <div>
              <v-card-title class="text-h5">Audios</v-card-title>
              <v-card-subtitle>配套音频资源</v-card-subtitle>
              <v-card-actions>
                <router-link to="/nine/speaking/media">
                  <v-btn variant="text">查看详情</v-btn>
                </router-link>
              </v-card-actions>
            </div>
            <v-icon icon="mdi-multimedia" :size="40" class="mr-4" />
          </div>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>
<script setup>
import { ref } from 'vue'
import { findContent } from '@/apis/content'

const content = ref({})
const fetchData = () => {
  findContent('1648264020884197377').then((res) => {
    content.value = res.data.data
  })
}
fetchData()
</script>
<style lang=""></style>
