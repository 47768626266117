<template lang="">
  <v-app>
    <router-view v-slot="{ Component }" :key="key">
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </v-app>
</template>
<script>
export default {
  name: 'NormalLayout',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>
<style lang=""></style>
