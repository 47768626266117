import { getStore, setStore } from '@/utils/store'
import {
  getUserInfo,
  loginByUsername,
  loginByMobile,
  logout,
  refreshToken
} from '@/apis/login'
import { encryption } from '@/utils/util'
// import { getMenu } from '@/api/admin/menu'
import { resetRouter } from '@/router'

const state = {
  userInfo:
    getStore({
      name: 'user_info'
    }) || {},
  access_token:
    getStore({
      name: 'access_token'
    }) || '',
  refresh_token:
    getStore({
      name: 'refresh_token'
    }) || ''
}

const user = {
  state,
  actions: {
    // 根据用户名登录
    LoginByUsername({ commit }, userInfo) {
      const user = encryption({
        data: userInfo,
        key: 'thanks,pig4cloud',
        param: ['password']
      })
      return new Promise((resolve, reject) => {
        loginByUsername(user.username, user.password, user.code, user.randomStr)
          .then((response) => {
            const data = response.data
            commit('SET_ACCESS_TOKEN', data.access_token)
            commit('SET_REFRESH_TOKEN', data.refresh_token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 根据手机号登录
    LoginByPhone({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByMobile(userInfo.mobile, userInfo.code)
          .then((response) => {
            const data = response.data
            commit('SET_ACCESS_TOKEN', data.access_token)
            commit('SET_REFRESH_TOKEN', data.refresh_token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((res) => {
            const data = res.data.data || {}
            commit('SET_USER_INFO', data.sysUser)
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 刷新token
    RefreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refresh_token)
          .then((response) => {
            const data = response.data
            commit('SET_ACCESS_TOKEN', data.access_token)
            commit('SET_REFRESH_TOKEN', data.refresh_token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_USER_INFO', {})
            commit('SET_ACCESS_TOKEN', '')
            commit('SET_REFRESH_TOKEN', '')
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 注销session
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_USER_INFO', {})
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_REFRESH_TOKEN', '')
        resetRouter()
        resolve()
      })
    }
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token
      setStore({
        name: 'access_token',
        content: state.access_token
      })
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      state.refresh_token = rfToken
      setStore({
        name: 'refresh_token',
        content: state.refresh_token
      })
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({
        name: 'user_info',
        content: state.userInfo
      })
    }
  }
}
export default user
