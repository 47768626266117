<template lang="">
  <div class="common_layout">
    <ul class="exercise_type">
      <li :class="{'active':exerciseType===1}" @click="changeExerciseType(1)">顺序练习</li>
      <li :class="{'active':exerciseType===2}" @click="changeExerciseType(2)">场景练习</li>
    </ul>
    <template v-if="exerciseType===1">
      <div class="note_container">
        <div class="note_content">剑4-剑17和九分达人听力1-6，按Part/Section练习</div>
      </div>
      <div class="origin_container">
        <ul class="origin_type">
          <li :class="{'active':originType===1}" @click="changeOriginType(1)">官方题库</li>
          <li :class="{'active':originType===2}" @click="changeOriginType(2)">9分题库</li>
        </ul>
        <ul v-show="originType===1" class="origin_list">
          <li :class="{'active': currentCate === 226}" @click="changeCurrentCate(226)">
            剑17
          </li>
          <li :class="{'active': currentCate === 160}" @click="changeCurrentCate(160)">
            剑16
          </li>
          <li :class="{'active': currentCate === 165}" @click="changeCurrentCate(165)">
            剑15
          </li>
          <li :class="{'active': currentCate === 170}" @click="changeCurrentCate(170)">
            剑14
          </li>
          <li :class="{'active': currentCate === 175}" @click="changeCurrentCate(175)">
            剑13
          </li>
          <li :class="{'active': currentCate === 180}" @click="changeCurrentCate(180)">
            剑12
          </li>
          <li :class="{'active': currentCate === 185}" @click="changeCurrentCate(185)">
            剑11
          </li>
          <li :class="{'active': currentCate === 190}" @click="changeCurrentCate(190)">
            剑10
          </li>
          <li :class="{'active': currentCate === 195}" @click="changeCurrentCate(195)">
            剑9
          </li>
          <li :class="{'active': currentCate === 200}" @click="changeCurrentCate(200)">
            剑8
          </li>
          <li :class="{'active': currentCate === 205}" @click="changeCurrentCate(205)">
            剑7
          </li>
          <li :class="{'active': currentCate === 210}" @click="changeCurrentCate(210)">
            剑6
          </li>
          <li :class="{'active': currentCate === 216}" @click="changeCurrentCate(216)">
            剑5
          </li>
          <li :class="{'active': currentCate === 217}" @click="changeCurrentCate(217)">
            剑4
          </li>
        </ul>
        <ul v-show="originType===2" class="origin_list">
          <li :class="{'active': currentCate === 42}" @click="changeCurrentCate(42)">
            听力6
          </li>
          <li :class="{'active': currentCate === 73}" @click="changeCurrentCate(73)">
            听力5
          </li>
          <li :class="{'active': currentCate === 74}" @click="changeCurrentCate(74)">
            听力4
          </li>
          <li :class="{'active': currentCate === 75}" @click="changeCurrentCate(75)">
            听力3
          </li>
          <li :class="{'active': currentCate === 76}" @click="changeCurrentCate(76)">
            听力2
          </li>
          <li :class="{'active': currentCate === 77}" @click="changeCurrentCate(77)">
            听力1
          </li>
        </ul>
      </div>
      <div class="part_container">
        <ListeningPartItemVue
          v-for="cate in partCates"
          :key="cate.id"
          :test-title="cate.label"
          :origin-category-id="cate.id"
        />
      </div>
    </template>
    <template v-else>
      <div class="note_container">
        <div class="note_content">剑4-剑17和九分达人听力1-6，按场景练习</div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { findTree } from '@/apis/category'
import ListeningPartItemVue from './components/ListeningPartItem'

const exerciseType = ref(1)
const changeExerciseType = (v) => {
  exerciseType.value = v
}
const originType = ref(1)
const changeOriginType = (v) => {
  originType.value = v
  if (v === 1) {
    changeCurrentCate(226)
  } else {
    changeCurrentCate(42)
  }
}

// 获取分类
const currentCate = ref(226)
const partCates = ref([])
const changeCurrentCate = (id) => {
  currentCate.value = id
  findTree(id).then(res => {
    partCates.value = res.data.data
  })
}
changeCurrentCate(226)

</script>
<style lang="scss" scoped>
ul, dl{
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}
.text-align_center{
  text-align: center;
}
.common_layout{
  // padding:8px;
}

ul.exercise_type{
  padding-left: 24px;
  margin-bottom: 16px;
  color: #58595c;
  border-bottom: 1px solid #eaebef;
  li{
    height: 56px;
    font-size: 18px;
    display: inline-block;
    line-height: 56px;
    margin-right: 48px;
    cursor: pointer;
  }
  li.active{
    font-weight: bold;
    color: #409EFF;
    border-bottom: 3px solid #409EFF;
  }
}

.note_container{
  .note_content{
    box-sizing: border-box;
    margin: 0 16px 24px;
    height: 36px;
    line-height: 36px;
    padding-left: 16px;
    color: #409EFF;
    font-size: 14px;
    background-color:  #ecf5ff;
    border-radius: 4px;
  }
}

ul.origin_type, ul.origin_list{
  padding-left: 16px;
  display: flex;
  flex-flow:row wrap;
  li{
    width: 108px;
    height: 36px;
    font-size: 14px;
    display: inline-block;
    color: #58595c;
    background-color: #f7f9fc;
    text-align: center;
    line-height: 36px;
    margin-right: 16px;
    margin-bottom: 16px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
  }
  li.active{
    color: #fff;
    background: linear-gradient(135deg, #409EFF,#79bbff);
    border: none;
    line-height: 36px;
  }
}

ul.origin_type{
  margin-bottom: 16px;
}

ul.origin_list{
  margin-bottom: 16px;
}

.part_container{
  dl{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    dt{
      padding:0 24px;
      height: 56px;
      line-height: 56px;
      color: #303133;
      background-color: #f7f9fc;
      .test_title{
        color: #303133;
        font-size: 18px;
        font-weight: bold;
      }
    }
    dd{
      margin: 0;
      padding:0 24px;
      height: 64px;
      line-height: 64px;
      color: #333;
      .el-row{
        border-bottom: 1px solid #f7f9fc;
      }
    }
    dd:last-child{
      .el-row{
        border-bottom: none;
      }
    }
  }
}

</style>
