<template lang="">
  <el-card :body-style="{ padding: '0' }" class="writing_new">
    <template #header>
      <div class="card-header">
        <span>批改动态</span>
      </div>
    </template>
    <el-table v-loading="dataLoading" :data="logs" stripe style="width: 100%">
      <el-table-column prop="testNote" label="题目" align="left">
        <template #default="scope">
          <router-link
            class="link"
            :to="`/writing/result/` + scope.row.writingLogId"
          >{{ scope.row.testNote }}</router-link>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" width="100" align="center">
        <template #default="scope">
          {{ formatDate(scope.row.updateTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="进度" width="90" align="center">
        <template #default="scope">
          <el-tag :type="fiterStatusTagType(scope.row.status)">
            {{ fiterStatus(scope.row.status) }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
<script setup>
import { ref, reactive } from 'vue'
import moment from 'moment'

import { findWrtingLogLastList } from '@/apis/member'

const dataLoading = ref(false)
const queryForm = reactive({
  first: 0,
  count: 10,
  status: ''
})
const logs = ref([])
const fetchLogList = () => {
  dataLoading.value = true
  findWrtingLogLastList(queryForm)
    .then((res) => {
      logs.value = res.data.data
      dataLoading.value = false
    })
    .catch((e) => {
      dataLoading.value = false
    })
}
fetchLogList()

const fiterStatus = (v) => {
  switch (v) {
    case '0':
      return '草稿'
    case '1':
      return '待批改'
    case '2':
      return '批改中'
    case '3':
      return '已批改'
    default:
      return '草稿'
  }
}

const fiterStatusTagType = (v) => {
  switch (v) {
    case '0':
      return 'info'
    case '1':
      return 'warning'
    case '2':
      return ''
    case '3':
      return 'success'
    default:
      return 'info'
  }
}

const formatDate = (v) => {
  moment.locale('zh_CN')
  return moment(v).fromNow()
}
</script>
<style lang="scss" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul.log_list {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.log_list li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

a.link {
  color: #409eff;
}
a.link:hover {
  text-decoration: underline;
}
</style>
