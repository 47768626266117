import request from '@/utils/request'

// 保存考试记录
export function saveTestLog(data) {
  return request({
    url: '/api/testLog/save',
    method: 'post',
    data: data
  })
}

// 初始化考试记录
export function initTestLog(data) {
  return request({
    url: '/api/testLog/init',
    method: 'post',
    data: data
  })
}

// 获取考试记录
export function findTestLog(testLogId) {
  return request({
    url: '/api/testLog/' + testLogId,
    method: 'get'
  })
}

// 删除考试记录
export function deleteTestLog(testLogId) {
  return request({
    url: '/api/testLog/' + testLogId + '/remove',
    method: 'delete'
  })
}

// 拉取考试记录列表
export function fetchTestLogList(params) {
  return request({
    url: '/api/testLog/list',
    method: 'get',
    params: params
  })
}

// 拉取未完成的考试记录列表
export function fetchUnFinishedTestLogs(params) {
  return request({
    url: '/api/testLog/unFinished',
    method: 'get',
    params: params
  })
}

// 拉取考试记录列表
export function fetchTestLogCount(types) {
  return request({
    url: '/api/testLog/count',
    method: 'get',
    params: { types }
  })
}

// 拉取考试记录列表
export function fetchSentences(partId) {
  return request({
    url: '/api/intensive/' + partId + '/sentences',
    method: 'get'
  })
}
